import React, { useEffect,useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import { EN_LONG_FEES, ES_LONG_FEES, GET_STEP_DOCUMENT_PROGRESS_BAR, SET_STEP } from 'constants/App';
import { getArticles, putArticles } from 'middleware/documents';
import { renderCheckboxField } from 'utils/formUtils';
import { getArticleById, locale } from 'utils/helpers';
import Button from 'components/shared/Button.styled';
import Modal from 'components/shared/Modal';
import history from 'utils/history';
import { ROUTES } from 'constants/AppRoutes';
import { notification } from 'services/notification';

const LongFeesAgreement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const articles = useSelector((state) => state.documents.list?.result || []);
  const isChecked = useSelector((state) => state.form.longFees?.values?.agreement);
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    dispatch({
        type: GET_STEP_DOCUMENT_PROGRESS_BAR,
        data: { title: t('FEES_TEXT'), documentStep: 3 }
    })
    dispatch(getArticles())
  },[])

  useEffect(() => {
    if(window?.innerWidth < 1024){
      setIsBottom(true)
    }
  }, [window?.innerWidth])

  const longFeesAgreement = () => {
    if(!isBottom){
      notification('error', t('SCROLL_TO_BOTTOM'));
    } else {
      dispatch(putArticles('long'))
      dispatch({ type: 'TOGGLE_MODAL', set: false })
      dispatch({
        type: SET_STEP,
        step: 3
      })
      history.push(ROUTES.VERIFICATION)
    }
  }

  const handleScroll = (event) => {
    const target = event?.target;
    if(target.scrollHeight - target.scrollTop <= target.clientHeight + 10){
      setIsBottom(true)
    }
  }

  const handleLoad = () => {
    const height = document.querySelector('.overflow-page').offsetHeight;
    const fullHeight = document.querySelector('.overflow-page').scrollHeight;
    if(height === fullHeight){
      setIsBottom(true)
    }
  }

  return (
    <>
    <Modal
      show={true}
    >
      <div className="fees-block-long">
      <div className="overflow-page"  onLoad={() => handleLoad()} onScroll={(e) => handleScroll(e)}>
      {getArticleById(articles, locale() === 'en' ? EN_LONG_FEES : ES_LONG_FEES)}
      </div>
      </div>
      <div className="d-flex">
      <Field
        name="agreement"
        type="checkbox"
        component={renderCheckboxField}
      />
      <p>{t('ACCEPT_TNC')}</p>
      </div>
      <div className="text-center">
      <Button
        outlined
        type='submit'
        disabled={!isChecked}
        onClick={() => longFeesAgreement()}
      >{t('AGREE_TEXT')}
      </Button>
      </div>
    </Modal>
    </>
  )
}

export default reduxForm({
  form: 'longFees', // a unique identifier for this form
  destroyOnUnmount: false,
})(LongFeesAgreement);