import { ActionTypes } from './actionTypes';

export const signupRequest = () => {
    return {
        type: ActionTypes.SIGNUP_REQUEST
    }
}
export const signupSuccess = (response) => {
    return {
        type: ActionTypes.SIGNUP_SUCCESS,
        payload: response
    }
}
export const signupFailed = () => {
    return {
        type: ActionTypes.SIGNUP_FAILED,
    }
}

export const verifyEmailRequest = () => {
    return {
        type: ActionTypes.VERIFY_EMAIL_REQUEST
    }
}
export const verifyEmailSuccess = (response) => {
    return {
        type: ActionTypes.VERIFY_EMAIL_SUCCESS,
        payload: response,
    }
}
export const verifyEmailExistsSuccess = (response) => {
    return {
        type: ActionTypes.VERIFY_EMAIL_EXISTS_SUCCESS,
        payload: response,
    }
}
export const verifyEmailFailed = () => {
    return {
        type: ActionTypes.VERIFY_EMAIL_FAILED,
    }
}

export const userDataRequest = () => {
    return {
        type: ActionTypes.POST_USER_DATA_REQUEST
    }
}
export const userDataSuccess = (response) => {
    return {
        type: ActionTypes.POST_USER_DATA_SUCCESS,
        payload: response
    }
}
export const userDataFailed = () => {
    return {
        type: ActionTypes.POST_USER_DATA_FAILED,
    }
}

export const verifyOtpRequest = () => {
    return {
        type: ActionTypes.VERIFY_OTP_REQUEST
    }
}
export const verifyOtpSuccess = (response) => {
    return {
        type: ActionTypes.VERIFY_OTP_SUCCESS,
        payload: response
    }
}
export const verifyOtpFailed = () => {
    return {
        type: ActionTypes.VERIFY_OTP_FAILED,
    }
}

export const resendOtpRequest = () => {
    return {
        type: ActionTypes.RESEND_OTP_REQUEST
    }
}
export const resendOtpSuccess = (response) => {
    return {
        type: ActionTypes.RESEND_OTP_SUCCESS,
        payload: response
    }
}
export const resendOtpFailed = () => {
    return {
        type: ActionTypes.RESEND_OTP_FAILED,
    }
}

export const postUserDetailsRequest = () => {
    return {
        type: ActionTypes.POST_USER_DETAILS_REQUEST
    }
}
export const postUserDetailsSuccess = (response) => {
    return {
        type: ActionTypes.POST_USER_DETAILS_SUCCESS,
        payload: response
    }
}
export const postUserDetailsFailed = () => {
    return {
        type: ActionTypes.POST_USER_DETAILS_FAILED,
    }
}

export const getLocationAddressRequest = () => {
    return {
        type: ActionTypes.GET_LOCATION_ADDRESS_REQUEST,
    }
}
export const getLocationAddressSuccess = (response) => {
    return {
        type: ActionTypes.GET_LOCATION_ADDRESS_SUCCESS,
        payload: response
    }
}
export const getLocationAddressFailed = () => {
    return {
        type: ActionTypes.GET_LOCATION_ADDRESS_FAILED,
    }
}

export const getUserDetailsRequest = (country) => {
    return {
        type: ActionTypes.GET_USER_DETAILS_REQUEST,
        payload:country
    }
}
export const getUserDetailsSuccess = (response) => {
    return {
        type: ActionTypes.GET_USER_DETAILS_SUCCESS,
        payload: response
    }
}
export const getUserDetailsFailed = () => {
    return {
        type: ActionTypes.GET_USER_DETAILS_FAILED,
    }
}

export const postRegRequest = () => {
    return {
        type: ActionTypes.POST_REGISTRATION_REQUEST
    }
}
export const postRegSuccess = (response) => {
    return {
        type: ActionTypes.POST_REGISTRATION_SUCCESS,
        payload: response
    }
}
export const postRegFailed = () => {
    return {
        type: ActionTypes.POST_REGISTRATION_FAILED,
    }
}

export const putPromoCodeRequest = () => {
    return { type: ActionTypes.PUT_PROMOCODE_REQUEST }
}
export const putPromoCodeSuccess = (response) => {
    return {
        type: ActionTypes.PUT_PROMOCODE_SUCCESS,
        payload: response
    }
}
export const putPromoCodeFailed = () => {
    return {
        type: ActionTypes.PUT_PROMOCODE_FAILED,
    }
}

export const putUserLangRequest = () => {
    return { type: ActionTypes.PUT_USER_LANGUAGE_REQUEST }
}
export const putUserLangSuccess = (response) => {
    return {
        type: ActionTypes.PUT_USER_LANGUAGE_SUCCESS,
        payload: response
    }
}
export const putUserLangFailed = () => {
    return {
        type: ActionTypes.PUT_USER_LANGUAGE_FAILED,
    }
}


