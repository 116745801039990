import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getToken } from 'utils/helpers';
import { ROUTES } from 'constants/AppRoutes';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const token = getToken();
    return (
        <Route
            exact={ true }
            { ...rest }
            render={ (props) =>
                !token ?
                    <Redirect
                        to={ {
                            pathname: ROUTES.SIGNUP,
                            state: { from: props.location },
                        } }
                    />
                    : (
                        <Component { ...props } />
                    )
                }
        />
    )
}
