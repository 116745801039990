export const TRANSLATIONS_EN = {
    "APP_NAME": "Cuentas",
    "ONBOARDING1_TITLE": "Mobile banking\nbuilt for you",
    "ONBOARDING1_TEXT": "Apply in under 5 minutes. (Really)",
    "ONBOARDING2_TITLE": "What you will\nneed to apply",
    "ONBOARDING2_TEXT": "Be over 18 years old.\nHave a US Address.\nHave a Social Security (SSN) or ITIN.",
    "ONBOARDING3_TITLE": "What you will get",
    "ONBOARDING3_TEXT": "A Mastercard® debit card.\nYour funds insured by FDIC.\nSpecial savings at your favorite stores.\nFree long distance calling.*",
    "ONBOARDING4_TITLE": "How much\ndoes it cost?",
    "ONBOARDING4_TEXT": "$4.50 monthly maintenance fee.",
    "ONBOARDING5_TITLE": "Registration takes only a few minutes.",
    "ONBOARDING5_TITLE_TXT": "Make sure you have everything you need before you get started! Here are some helpful hints.",
    "ONBOARDING5_SUB_TEXT": "You must be over 18 years old to apply.",
    "ONBOARDING5_SUB_FRSTTEXT": "Your US address must match the address on the ID you use to register.",
    "ONBOARDING5_SUB_SCNDTEXT": "Have your SSN or an ITIN number ready.",
    "ONBOARDING5_SUB_THRD_TEXT": "Have your state issued driver’s license or another form of a state issued ID ready.",
    "ONBOARDING5_SUB_FRTH_TEXT": "If additional forms of identification are requested you may use Matrícula Consular, Passport or Green Card.",
    "SLIDER_TEXT": "*Up to $15 per month.",
    "CONTACT": "+555 555 555 55 55",
    "EN": "EN",
    "ES": "ES",
    "GMAIL": "GMAIL.COM",
    "YAHOO": "YAHOO.COM",
    "OUTLOOK": "OUTLOOK.COM",
    "PASSWORD_TEXT": "Special Character. Capital Letter. Min 9 Characters.",
    "ALREADY_HAVE_ACCOUNT": "Already have an Account?",
    "SPECIAL_CHARACTER": "Special Character",
    "CAPITAL_CHARACTER": "Capital Letter",
    "PASSWORD_LENGTH": "Min 9 Characters",
    "CREATE_NEW_ACCOUNT": "Create a New Account.",
    "PROGRESS_TEXT": "Progress",
    "FIRST_NAME_TEXT": "First Name*",
    "MIDDLE_NAME_TEXT": "Middle Name",
    "LAST_NAME_TEXT": "Last Name*",
    "EMAIL": "Email*",
    "PASSWORD": "Password*",
    "CONFIRM_PASSWORD": "Confirm Password*",
    "OLD_PASSWORD": "Old Password*",
    "NEW_PASSWORD": "New Password*",
    "CONFIRM_NEW_PASSWORD": "Confirm New Password*",
    "ENTER_EMAIL": "Enter Email*",
    "FORGOT_PASSWORD": "Forgot Your Password?",
    "RESTORE": "Reset",
    "SIGN_IN": "Sign In",
    "SIGN_UP": "Sign Up",
    "NEED_ACCOUNT": "Need an Account?",
    "ADDRESS": "Street Address*",
    "APT": "Apartment, Suite, Unit, etc.",
    "ZIP": "ZIP Code*",
    "CITY": "City*",
    "STATE": "State* (e.g. CA, FL, GA...)",
    "ADDRESS_INFO": "Address Information",
    "ENTER_BIRTHDAY": "Enter your Birthday",
    "PASSWORD_SECURITY": "Password and Security",
    "CREATE_YOUR_PIN": "Create your Personal PIN",
    "ACTIVATION_AND_PROMO_CODE": "Activation and Promo Code",
    "MAIDEN_NAME": "Mother's Maiden Name*",
    "SSN": "SSN (xxx-xx-xxxx)\\ITIN*",
    "ITIN": "ITIN",
    "OR": "OR",
    "CREATE_PIN": "Create PIN",
    "RE_ENTER_PIN": "Re-enter PIN",
    "PLACEHOLDER_NUMBER": "000 000 0000",
    "CONFIRM_MOBILE_NUMBER": "Confirm Mobile Number",
    "ENTER_SMS_CODE": "Enter SMS Code",
    "ENTER_CODE_TEXT": "We sent your SMS Code to",
    "ENTER_PHONE_NUMBER": "Enter your Phone Number",
    "ENTER_PHONE_SUBTEXT": "You will receive an SMS Code to restore your Password.",
    "ENTER_CODE_MESSAGE": "If you did not recieve an SMS Code,\nplease press 'Resend Code.'",
    "VERIFY": "Verify",
    "RESEND_CODE": "Resend Code",
    "SEND": "Send",
    "SEND_INVITE": "Send Invite",
    "DONE": "Done",
    "PENDING": "Pending",
    "CANCELLED": "Cancelled",
    "CHANGE": "Change",
    "CHECK_MAIL_VALIDATION": "Please check your Email",
    "CHECK_MAIL_LENGTH": "Please enter your Email",
    "CHECK_PASSWORD": "Please check your Password",
    "ENTER_PASSWORD": "Please enter your Password",
    "CHECK_PASSWORD_CONFIRM_PASSWORD": "Password and Confirm Password should be same",
    "ENTER_CONFIRM_PASSWORD": "Please enter Confirm Password",
    "FIRST_NAME": "Please enter First Name",
    "LAST_NAME": "Please enter Last Name",
    "CONTACT_NUMBER": "Please enter Phone Number",
    "INVALID_CONTACT_NUMBER": "Please enter a valid 10-digit Phone Number",
    "INVALID_CONTACT_NUMBER_WITH_CODE": "Please enter a valid Phone Number",
    "SUCCESS": "Thanks!",
    "PHONE_NUMBER_VERIFIED": "We have verified your Phone Number",
    "FACE_ID": "Face ID",
    "ENABLE_FACE_ID": "Do you want to enable Face ID?",
    "TOUCH_ID": "Touch ID",
    "ENABLE_TOUCH_ID": "Do you want to enable Touch ID?",
    "WELCOME_TO_CUENTAS": "Welcome to Cuentas",
    "GO_TO_MY_ACCOUNT": "Go to My Account.",
    "YES_TEXT": "Yes",
    "NO_TEXT": "No",
    "OK_TEXT": "Ok",
    "FEES_TEXT": "Fees",
    "NEED_HELP": "Need Help?",
    "PASSWORD_CHANGED": "Password changed.",
    "CLOSE_TEXT": "Close",
    "CONTACT_SUPPORT": "Contact Support at 1-833-283-6827",
    "CARHOLDER_AGREEMENT": "Cardholder Agreement",
    "TOUCH_ID_AGREEMENT": "Agreement for Touch ID/Face ID",
    "USER_ALREADY_EXISTS": "The Username you selected is already registered.",
    "INCORRECT_CREDENTIALS": "Please check your Login details.",
    "INCORRECT_OTP": "The SMS Code you have entered is incorrect.",
    "INCORRECT_CODE": "Please check SMS Code or Phone Number.",
    "RESTORE_PASSWORD": "Reset Password",
    "CHANGE_PASSWORD": "Change your Password",
    "CHANGE_PASSWORD_TEXT": "Enter your New Password",
    "PHONE_NUMBER_NOT_REGISTERED": "If the Phone Number entered is registered, you will receive an SMS Code.",
    "PASSWORD_RESET_SUCCESS": "Password restored successfully. Please Login again.",
    "CODE_RESENT_SUCCESSFULLY": "Code resent successfully, if the Phone Number entered is registered, you will receive a SMS Code.",
    "INVALID_SECRET_KEY": "Invalid secret key, please enter correct key and try again.",
    "ENTER_ACTIVATION_CODE": "Create your Activation Code*",
    "ACTIVATION_CODE_REQUIRED": "Activation Code is Required",
    "INVALID_ACTIVATION_CODE": "Invalid Activation Code",
    "INVALID_ACTIVATION_CODE_MESSAGE": "Enter a 4-digit Activation Code.",
    "ENTER_PROMO_CODE": "Enter Promo Code",
    "ENTER_ADDRESS": "Street Address is Required",
    "ENTER_ZIP_CODE": "ZIP Code is Required",
    "ENTER_CITY": "City is Required",
    "ENTER_STATE": "State is Required",
    "ENTER_MOTHER_MAIDEN_NAME": "Mother's Maiden Name is Required",
    "ENTER_SSN": "SSN/ITIN is Required",
    "INVALID_SSN": "Invalid SSN/ITIN",
    "EMPTY_BIRTHDAY": "Date Of Birth is Required",
    "ILD_REWARDS_TITLE": "International Long Distance Reward Terms",
    "LICENSE_AGREEMENT": "License Agreement",
    "AGREE_TEXT": "Confirm",
    "DECLINE_TEXT": "Decline",
    "ACCEPT_TNC": "I Accept the Terms and Conditions",
    "HOME": "Home",
    "WALLET": "Wallet",
    "ADD_CASH": "Add Cash",
    "RELOAD_CARD": "Reload Card",
    "RELOAD": "Reload",
    "NAVIGATE_TO": "Navigate to",
    "AMOUNT": "Amount",
    "RELOAD_CARD_TEXT": "Reload your Card\ndirectly at the register.",
    "LOCATIONS": "Locations",
    "LINKED_CUENTAS_MEMBERS": "Linked Cuentas Members",
    "PHONE_INVITES_SENT": "Phone Invites sent",
    "EMAIL_INVITES_SENT": "Email Invites sent",
    "PHONE_CONTACTS": "Contacts from your phone",
    "SEARCH_BY_ZIP": "Search by ZIP Code",
    "SEARCH": "Search",
    "ILD_DESCRIPTION": "Complete Sign Up for\nInternational Long Distance Reward Balance.",
    "ILD_ACCEPTED_DESCRIPTION": "International Long Distance Reward\nBalance",
    "DOCUMENT_NOT_VERIFIED": "Document not verified",
    "DETAILS": "Details",
    "COMPLETE_PURCHASE": "Complete Purchase",
    "ENTER_YOUR_EMAIL": "Enter your Email",
    "ENTER_AN_AMOUNT": "Enter an Amount",
    "AVAILABLE_FUNDS": "Available Funds",
    "HELP_TEXT": "Help",
    "CARD_HOLDER_AGREEMENT": "Cardholder Agreement",
    "ESIGN_DISCLOSURE": "E-Sign Disclosure",
    "SUTTON_PRIVACY_POLICY": "Sutton Bank Privacy Policy",
    "FEES_AND_LIMITS": "Fees and Limits",
    "CUENTAS_PRIVACY_POLICY": "Cuentas Privacy Policy",
    "CUENTAS_TERMS_OF_USE": "Cuentas Terms of Use",
    "CUENTAS_REWARDS": "Cuentas Rewards",
    "ABOUT": "About Cuentas",
    "QNA_TEXT": "Q&A",
    "CONTACT_TEXT": "Contact Info",
    "SECURITY_AND_FEES": "Security and Fees",
    "GENERAL_INFO": "General Information",
    "SPENDING_MONEY": "Spending Money",
    "ADDING_MONEY_TO_CART": "Adding Money to Your Card",
    "GENERAL_INFO_AND_GETTING_STARTED": "General Information\nand Getting Started.",
    "CARDHOLDER_AGREEMENT": "Cardholder Agreement",
    "SUTTON_BANK": "Sutton Bank",
    "FEES_N_LIMITS": "Fees and Limits",
    "ALL_FEES": "All Fees",
    "PRIVACY_POLICY": "Privacy Policy",
    "REWARDS_TEXT": "Cuentas Rewards",
    "ABOUT_CUENTAS": "About Cuentas",
    "CONTACT_INFO_TEXT": "Contact Info",
    "TERMS_OF_USE": "Terms of Use",
    "ADVANCE_SEARCH": "Advanced Search",
    "ADVANCE_SEARCH_DEC": "Set Filters, then press\nProceed to see results.",
    "SEARCH_FILTER": "Select Filters",
    "SELECT_AMOUNT": "Select Amount",
    "SELECT_STATE": "Select State",
    "SELECT_OPERATOR": "Select Operator",
    "TYPE": "Type",
    "PERIOD": "Period",
    "BY_RECIPENT": "By Recipient",
    "ENTER_RECIPENT_NAME": "Enter Recipient Name.",
    "PROCEED": "Proceed",
    "GENERATE_BAR_CODE": "Generate Barcode",
    "SELECT_ACTION": "Please Select Action",
    "WHAT_DO_YOU_WANT_DO": " ",
    "ENTER_AMOUNT": "Enter the Amount you would like to Load.",
    "PLEASE_ENTER_AMOUNT": "Please enter an Amount.",
    "SCAN_CODE": "Scan Barcode",
    "PLEASE_SCAN_BAR_CODE": "Show this Barcode at the Register",
    "RELOAD_CARD_TEXT1": "Take your Cuentas Card and cash to a Participating Retailer.",
    "RELOAD_CARD_TEXT2": "Present your Card to the Cashier to Add Money to your Prepaid Card. Or, generate a Barcode below for the Cashier to scan.",
    "RELOAD_CARD_TEXT3": "The Cashier will ring up sales and load your funds directly to your card.",
    "RELOAD_FEES_APPLY": "*Reload fees apply. Subject to Prepaid Card load limits. See Cardholder Agreement for complete details.",
    "CANCEL": "Cancel",
    "SUBMIT": "Submit",
    "DISMISS": "Dismiss",
    "SETTINGS_TITLE": "Settings",
    "WESTERN_UNION": "Western Union",
    "ACCOUNT_TITLE": "Account",
    "BANK_INFO": "Bank Info",
    "ALERT_TITLE": "Alerts",
    "PERSONALE_INFORMATION": "Personal Information",
    "SEND_EMAIL_VALIDATION_CODE": "Send Email Validation Code.",
    "SECURITY_TEXT": "Security",
    "ENABLE_TOUCH_ID_TEXT": "Enable Touch ID",
    "ENABLE_FACE_ID_TEXT": "Enable Face ID",
    "ON_TEXT": "ON",
    "OFF_TEXT": "OFF",
    "NAME_TEXT": "Name",
    "PHONE_TEXT": "Phone",
    "EMAIL_TEXT": "Email",
    "PHONE_NUMBER": "Phone Number",
    "E_MAIL_TEXT": "Email",
    "CHANGE_PASSWORD_TXT": "Change Password",
    "ENTER_EMAIL_VALIDATION": "Enter Email Validation Code",
    "VALIDATE_EMAIL_TEXT": "Validate Email",
    "CANCEL_TEXT": "Cancel",
    "ENTER_OLD_PASSWORD": "Enter Old Password then Enter your New Password",
    "OLD_PASSWORD_TEXT": "Old Password",
    "ENTER_NEW_PASSWORD": "Enter New Password",
    "ENTER_YOUR_NEW_PASSWORD": "Enter your Old Password",
    "NEW_PASSWORD_TEXT": "New Password",
    "CONFIRM_NEW_PASSWORD_TEXT": "Confirm New Password",
    "VALIDATIONS_STATEMENT": "1 Special Character, 1 Capital Letter & Min 9 Characters",
    "SUBMIT_TEXT": "Submit",
    "PLEASE_ENTER": "Please Enter ",
    "PLEASE_ENTER_VALID": "Please Enter Valid ",
    "PASSWORD_UPDATED_SUCCESSFULLY": "Password updated successfully.",
    "ENTER_VALID_AMOUNT": "Please enter a Valid Amount",
    "MAX_AMOUNT_EXCEED": "The Maximum Amount is $500.00",
    "ENTER_VALID_ZIP_CODE": "Please enter a valid ZIP Code",
    "SERVER_ERROR": "Server Error, please try again later.",
    "PHONE_OR_ACCOUNT": "Phone",
    "PAY": "Pay",
    "REQUEST": "Request",
    "INVITE": "Invite",
    "MAP": "Map",
    "LIST": "List",
    "PAYMENT_HISTORY": "Transaction History",
    "MONEY_IN": "Money In",
    "MONEY_OUT": "Money Out",
    "BOTH": "Both",
    "INCORRECT_CURRENT_PASSWORD": "Incorrect Current Password",
    "INCORRECT_CODE_TEXT": "Incorrect Code",
    "CODE_VALIDATED_SUCCESSFULLY": "Code Validated Successfully",
    "ALERTS_TRAILING_TEXT": "SMS and Email",
    "SMS_TEXT": "SMS",
    "PUSH": "Push",
    "AMOUNT_PAID": "Amount Paid",
    "AMOUNT_RECEIVED": "Amount Received",
    "TRANSFER_TO": "Transfer to",
    "RECEIVED_FROM": "Received from",
    "ADDITIONAL_DETAILS": "Additional Details",
    "NOTIFICATION_TITLE": "Notifications",
    "ALL_TAB": "All",
    "INVITE_TAB": "Invites",
    "TRANSACTION_TAB": "Transactions",
    "TRANSACTION_DETAILS": "Transactions Details",
    "VIEW_TRANSACTION": "View Transaction",
    "TRANSACTION_SUB_TEXT": "Please press View Transaction button to see details",
    "REJECT_OPTION": "Reject",
    "ACCEPT_OPTION": "Accept",
    "DISMISS_OPTION": "Dismiss",
    "SEND_OPTION": "Send",
    "NO_NOTIFICATION_TEXT": "No Notifications available",
    "NO_INVITE_TEXT": "No Invites available",
    "NO_TRANSACTIONN_TEXT": "No Transactions available",
    "HI_TEXT": "Hi",
    "INVIATION_SUB_TEXT": "I want to add you to my Cuentas address book. Please accept my invitation.",
    "TODAY": "Today",
    "CURRENT_WEEK": "Current Week",
    "CURRENT_MONTH": "Current Month",
    "CUSTOM": "Custom",
    "SET_CUSTOM_RANGE": "Set Custom Range",
    "START_DATE": "Start Date",
    "END_DATE": "End Date",
    "DATE_VALIDATION_ERROR": "Start Date should be earlier than End Date.",
    "SESSION_TIMEOUT": "Session Timeout, please Login again.",
    "SOMETHING_WENT_WRONG": "Something went wrong, please try again later.",
    "RELOAD_NOT_AVAILABLE": "Reload cash is not available for this location.",
    "CARD_ACTIVATION_TITLE": "Card Activation Code",
    "CARD_ACTIVATION_BUTTON_TEXT": "Activate Card",
    "DIRECT_DEPOSIT_TITLE": "Direct Deposit Info",
    "DEPOSIT_HINT_TEXT1": "ABA/Routing",
    "DEPOSIT_HINT_TEXT2": "Account #",
    "LA_TRANSIT_TITLE": "LA Transit TAP Card Recharge",
    "CEB_GO_TRANSIT_TITLE": "СЕВ GO Transit Reload",
    "GEAND_RAPID_TITLE": "Grand Rapids Replenishment",
    "ID_PLACEHOLDER": "Id",
    "SAVE_CHANGES_BUTTON_TEXT": "Save Changes",
    "CODE_INVALID_ERROR_MESSAGE": "Please enter a Valid Code.",
    "SET_NOTIFICATION_TITLE_TEXT": "Set Notifications",
    "SAVE_BUTTON_TEXT": "Save",
    "PAY_MONEY": "Pay Money",
    "REQUEST_MONEY": "Request Money",
    "PAYMENTS": "Payments",
    "CODE_COPIED_TEXT": "Code Copied",
    "ALERTS_UPDATE_SUCCESS_MESSAGE": "Alerts updated successfully",
    "ADDRESS_UPDATE_SUCCESS_MESSAGE": "Address updated successfully",
    "DATA_UPDATE_SUCCESS_MESSAGE": "Data updated successfully",
    "ITEM_SUBTOTAL": "Item Subtotal",
    "CUENTAS_DISCOUNT": "Cuentas Discount",
    "DISCOUNT": "Discounted Subtotal",
    "SALES_TAX": "Sales Tax",
    "INVOICE_TOTAL": "Invoice Total",
    "ITEM_VALUE": "Item Value",
    "FROM": "From",
    "TO": "To",
    "ADD_PAYMENT_NOTE": "Add Payment Note",
    "PAYMENT_SUCCESSFUL": "Amount has been transfered.",
    "REQUEST_SENT_SUCCESSFUL": "Amount request has been sent.",
    "PAYMENT_FAILED": "Payment failed, please try again later.",
    "REQUEST_FAILED": "Request failed, please try again later.",
    "PURCHASE_ERROR": "There was a problem with your request. Please contact Support.",
    "LOW_BALANCE_ERROR": "Your balance is lower than the amount requested.",
    "INVITE_FRIENDS": "Invite Friends to Cuentas.",
    "CHOOSE_WAY_TO_INVITE": " ",
    "INVITE_WITH_SMS": "Invite \nwith SMS",
    "INVITE_WITH_EMAIL": "Invite \nwith Email",
    "ENTER_EMAIL1": "Enter Email",
    "ENTER_EMAIL_NEEDED": "Enter Email needed",
    "ENTER_PHONE": "Enter Phone Number",
    "ENTER_PHONE_OR_CONTACTS": "Enter a Phone Number or find it in address book",
    "INVITE_SENT_SUCCESSFULLY": "Your Invite was successfully sent",
    "INVITE_FAILED": "Invite failed, please try again later",
    "SHOW_CARD_OTPION_TEXT": "Show Card Info",
    "ACCOUNT_NO": "Account #",
    "PLEASE_ENTER_EMAIL": "Please enter Email",
    "PLEASE_ENTER_VALID_EMAIL": "Please enter Valid Email",
    "ENTER_TRANSIT_CODE": "Enter Transit Code",
    "SIXTEEN_DIGIT_TRANSIT_CODE": "16-Digit Transit Code",
    "EMPTY_TRANSIT_CODE_ERROR": "Please enter Transit Code",
    "INVALID_TRANSIT_CODE_ERROR": "Please enter Valid Transit Code",
    "ENABLE_LOCATION": "Please enable device Location Services to find Merchants near your Location",
    "ENABLE_CONTACTS": "Please enable Contact Permission to fetch Contact List from Phone",
    "NETWORK_LOST": "Network connection lost.",
    "NETWORK_LOST_TRY_LATER": "Network connection lost, Please try again later.",
    "NETWORK_AVAILABLE": "Network connection is available now.",
    "CARD_ACTIVATION_POPUP_TEXT": "Congratulations! Your Account is active. Starting tomorrow, you may fully activate your account for Transfers & In-App Purchases by going to “Settings” to enter the 4 digit activation code you created during registration. Your $4.50 balance covers your activation fee.",
    "EMAIL_NEEDS_VERIFICATION": "Email must be validated to make a Cuentas Store purchase.",
    "STARTS_FROM": "Starts from {0}",
    "CONFIRM_TEXT": "Confirm",
    "CODE_SENT_SUCCESSFULLY": "A new Code has been sent.",
    "RECIPIENT_PHONE": "Recipient Phone",
    "LOOKUP": "Lookup",
    "OPERATOR_NOT_FOUND": "Operator not found.",
    "CARD_NEEDS_ACTIVATION": "Card must be Activated to do Payment.",
    "LOGOUT": "Logout",
    "STORE": "Store",
    "LOW_BAL_ALERT": "Low Balance Alert",
    "HIGH_AUTH_ALERT": "High Authorization Amount Alert",
    "Invite Received": "Invite Received",
    "Invite Accepted": "Invite Accepted",
    "Invite Rejected": "Invite Rejected",
    "Password Updated": "Password Updated",
    "Transfer Complete": "Transfer Complete",
    "Daily Balance Alert": "Daily Balance Alert",
    "Low Balance Alert": "Low Balance Alert",
    "Negative Balance Alert": "Negative Balance Alert",
    "High Authorization Amount Alert": "High Authorization Amount Alert",
    "Insufficient Balance Alert": "Insufficient Balance Alert",
    "Incorrect Pin Alert": "Incorrect Pin Alert",
    "Reload Alert": "Reload Alert",
    "SELECT_CATEGORY": "Select Category",
    "SELECT_PROVIDER": "Select Provider",
    "ENTER_ACCOUNT_NO": "Enter Account #",
    "PLEASE_ENTER_ACCOUNT_NO": "Please enter Account Number",
    "ENTER_PHONE_NO": "Enter Phone #",
    "ENTER_INVOICE_NO": "Enter Invoice #",
    "ENTER_BAR_CODE_NO_OR_SCAN": "Enter Barcode #",
    "ENTER_ACCOUNT_NO_OR_SCAN_BAR_CODE": "Enter Account \nor Barcode #",
    "ENTER_VALID_ACCOUNT_NO": "Enter a Valid Account #",
    "ENTER_VALID_PHONE_NO": "Enter a Valid Phone #",
    "ENTER_VALID_INVOICE_NO": "Enter a Valid Invoice #",
    "ENTER_VALID_BAR_CODE_NO_OR_SCAN": "Enter a Valid Barcode #",
    "ENTER_VALID_ACCOUNT_NO_OR_SCAN_BAR_CODE": "Enter a Valid Account \nor Barcode #",
    "CHECK_BALANCE": "Check Balance",
    "ENTER_PAYMENT_AMOUNT": "Enter Payment Amount",
    "PAY_FULL_AMOUNT": "Pay Full Amount",
    "PAY_PARTIAL_AMOUNT": "Pay Partial Amount",
    "SELECT_CURRENCY": "Select Currency",
    "SUCCESSFUL_PURCHASE_SUBTITLE": "You have successfully purchased ${0} of {1} for ${2}!",
    "SUCCESSFUL_PAID_SUBTITLE": "You have successfully paid ${0} to {1}",
    "CONVERT": "Convert",
    "PAY_BILL": "Pay Bill",
    "PIN": "Pin",
    "ITEM_NAME": "Item Name",
    "INVOICE_ID": "Invoice ID",
    "CURRENT_BALANCE": "Cuentas Balance: {0}",
    "AMOUNT_DUE": "Amount Due",
    "INSUFFICIENT_BALANCE": "Insufficient Balance",
    "NEGATIVE_BALANCE_ERROR": "Please enter a higher Amount",
    "PLEASE_SELECT_AMOUNT": "Please select Amount",
    "PLEASE_SELECT_OPERATOR": "Please select Operator",
    "PLEASE_SELECT_CATEGORY": "Please select Category",
    "ACTIVATION_CODE_POPUP_TITLE": "Activation Code",
    "ACTIVATION_CODE_POPUP_TEXT": "Define your personal card-activation code. This code will be required to activate your Card within the app, or by calling in to 844-433-8086 after completing registration",
    "TRANSIT_CODE_POPUP_TITLE": "Transit Account Number",
    "TRANSIT_CODE_POPUP_TEXT": "The Account Number is the last 16-digits of the number on the back of your card",
    "TRANSACTION_DETAILS_TEXT": "Transaction Details",
    "REDEEM_AMOUNT_TEXT": "Redeem Amount",
    "BILL_AMOUNT_TEXT": "Bill Amount",
    "AUTHENTICATION_TITLE_TEXT": "Authentication Required",
    "AUTHENTICATION_SUBTITLE_TEXT": "Please verify",
    "AUTHENTICATION_SENSOR_DESC": "Touch Sensor",
    "AUTHENTICATION_ERROR_DESC": "Failed",
    "CONFIRMATION": "Confirmation",
    "CONFIRMATION_SEND_MONEY": "You are about to Send ",
    "CONFIRMATION_REQUEST_MONEY": "You are about to Request ",
    "FROM_1": "from",
    "TO_1": "to",
    "IDOLOGY_FAILED": "There was an error with your request. Please check your information and try again.",
    "CONFIRMATION_ACTION": "Please confirm your purchase of: {0}.",
    "ERROR": "Error",
    "ALL_HISTORY": "All History",
    "NO_DATA_TEXT": "No Data Available",
    "CARD_ACTIVATION_POPUP_TITLE": "Congratulations!",
    "CUENTAS_PURCHASES": "Cuentas Purchases",
    "CARD_NEEDS_ACTIVATION_FOR_RELOAD": "Card must be Activated to Reload",
    "SUCCESS_TITLE": "Success",
    "MAINTENANCE_MESSAGE_TEXT": "Scheduled Maintenance. Please check back soon.",
    "UPGRADE_MESSAGE_TEXT": "New version of the App is available. Please download the latest version.",
    "CHOOSE_CATEGORY": "Choose Category",
    "SCROLL_TO_BOTTOM": "Please scroll to the bottom of Terms to Accept.",
    "ALERT_TOAST_TITLE": "Alert",
    "REPORT_CARD_LOST_TEXT": "Report Card Lost or Stolen",
    "CUENTAS_TRANSFERS": "Cuentas Transfers",
    "CUENTAS_TRANSFER": "Cuentas Transfer",
    "SEND_CODE": "Send Code",
    "ENTER_NEW_NO": "Enter New Phone Number",
    "ENTER_PIN_CODE": "Enter PIN Code",
    "CHECK_PHONE_FOR_PIN": "Check your Phone for the PIN code.",
    "CHECK_EMAIL_FOR_PIN": "Check your Email for the PIN code.",
    "EMAIL_UPDATE_SUCCESS": "Your Email has been sucessfully updated.",
    "PHONE_UPDATE_SUCCESS": "Your Phone Number has been sucessfully updated.",
    "CHANGE_EMAIL": "Change Email",
    "CHANGE_PHONE": "Change Phone Number",
    "ENTER_NEW_EMAIL": "Enter a New Email",
    "ENTER_YOUR_NEW_EMAIL": "Enter your New Email",
    "NEW_EMAIL_PLACEHOLDER": "New Email",
    "CONFIRM_EMAIL_PLACEHOLDER": "Confirm New Email",
    "UPDATE_EMAIL_INFO": "Please enter and confirm your New Email",
    "DIDNT_RECIEVE_PIN": "Did not receive PIN?",
    "RESEND_TEXT": "Resend",
    "VERIFICATION_CODE_INFO": "If you did not receive a Code, please press the \"Resend Code\" button.",
    "PLEASE_ENTER_CONFIRM_EMAIL": "Please enter Confirm Email",
    "PLEASE_ENTER_VALID_CONFIRM_EMAIL": "Please enter valid Confirm Email",
    "PLEASE_ACTIVATE_YOUR_CARD": "Please Activate your Card first",
    "CARD_ACTIVATION_FAILED": "Card Activation failed",
    "AVATAR_DIALOG_TITLE": "Update Avatar",
    "AVATAR_DIALOG_SUBTITLE": "Choose an option.",
    "AVATAR_DIALOG_OPTION_GALLERY": "Gallery",
    "AVATAR_DIALOG_OPTION_CAMERA": "Camera",
    "IMAGE_UPLOAD_SUCCESS": "Image uploaded successfully.",
    "IMAGE_UPLOAD_ERROR": "Error while uploading image.",
    "UPLOAD_USER_IMG": "Upload user image",
    "AVTAR_SIZE": "Image must be at least\n300x300 px\n(png., jpg. supported)",
    "AVTAR_SUCCESS_MSG": "Profile Picture has been updated successfully!",
    "INVITE_A_FRIEND_TRANSFER": "Invite a Friend to Cuentas With Optional Transfer",
    "INVITE_A_FRIEND_TRANSFER_REQUEST": "Invite a Friend to Cuentas with Optional Request for Funds",
    "OPTIONAL_AMOUNT": "Enter Optional Amount",
    "ENTER_A_PHONE_NUMBER": "Enter Phone Number",
    "ACCEPT_INVITE_SEND_MONEY": "Accept Invite and Send money",
    "ACCEPT_INVITE_REJECT_MONEY": "Accept Invite and Reject money",
    "REJECT_INVITE_REJECT_MONEY": "Reject Invite and Reject money",
    "OPTIONAL": " (optional)",
    "SOMETHING_WENT_WRONG_ERROR": "Something went wrong!",
    "INVITATION_ACCEPTED": "Invitation Accepted.",
    "INVITATION_REJECTED": "Invitation Rejected.",
    "ALREADY_EXIST_EMAIL": "The Email you selected is already registered.",
    "EMAIL_NOT_MATCH": "Email doesn't match",
    "WITH_THE_MESSAGE": " With the message: ",
    "REQUEST_DECLINED": "Request has been declined.",
    "OK": "OK",
    "MAX_AMOUNT_LIMIT": "Maximum Amount could be $1,000.",
    "INVITE_CONFIRMATION": "Are you sure you want to send Invite?",
    "POWERED_BY_WESTERN_UNION": "POWERED BY WESTERN UNION",
    "SEND_MONEY_WITH_CUENTAS": "Send Money with Cuentas",
    "HAVE_MY_WU_REWARDS_NUMBER": "Have a MyWU\nRewards Number?",
    "CLICK_HEAR_TO_ENTER": "Click here to enter",
    "NO_MY_WU_REWARDS": "No MyWU Rewards?",
    "CLICK_HEAR_TO_REGISTER": "Click here to Register",
    "ENTER_WU_NUMBER": "Enter 9-digit My WU Number",
    "ENTER_VALID_NUMBER": "Enter Valid My WU number",
    "NEXT": "Next",
    "RECEIVER_DETAILS": "Receiver Details",
    "MYWU_NUMBER": "MYWU # {0}",
    "VIEW": "View",
    "SELECT_YOUR_PAST_RECEIVERS": "Select your Past Receivers",
    "NONE": "None",
    "OR_ENTER_NEW_RECIPIENT": "Or enter New Recipient",
    "ENTER_NEW_RECIPIENT": "Enter New Recipient",
    "RECEIVERS_FIRST_NAME_STAR": "Receivers First Name*",
    "RECEIVERS_MIDDLE_NAME": "Receivers Middle Name",
    "RECEIVERS_LAST_NAME_STAR": "Receivers Last Name*",
    "E_MAIL": "Email",
    "COUNTRY": "Country",
    "COUNTRY_ERROR": "Please select Country",
    "STATE_ERROR": "Please select State",
    "CITY_ERROR": "Please select City",
    "DELIVERY_TYPE": "Delivery Type",
    "SELECT_DELIVERY_TYPE": "Select a Delivery Type",
    "MONEY_IN_MINUTES_CAP": "MONEY IN MINUTES",
    "MONEY_IN_MINUTES": "Money In Minutes",
    "STATE_TEXT": "State",
    "CITY_TEXT": "City",
    "BACK": "Back",
    "CONTINUE": "Continue",
    "REGISTRATION": "Registration",
    "WU_TEXT": "Western Union ",
    "PRIVACY_STATEMENT": "Privacy Statement",
    "TERMS_CONDITIONS": "Terms and Conditions",
    "WEBVIEW_LOADING_ERROR": "There was problem loading the page, it may not be displayed correctly.",
    "TRANSFER_DETAL": "Transfer Details",
    "RECIVER_INFO": "Reciever Information",
    "YOUR_ACCOUNT_INFO": "Your Account Information",
    "PAYOUT_CURRENCY": "Payout Currency",
    "AMOUNT2SEND": "Amount to Send",
    "PROMO_CODE": "Promotional Code",
    "NOTIFICATIONS_BY_EMAIL": "Notifications by Email",
    "NOTIFICATIONS_BY_SMS": "Notifications by SMS",
    "MESSAGING_OPTIONS": "Messaging options",
    "MESSAGING_OPTIONS_USER_MESSAGE_EMAIL": "*By providing your email address, you agree to receiving transactional and marketing emails from Western Union. To manage your preferences view our Privacy Statement.",
    "MESSAGING_OPTIONS_USER_MESSAGE_SMS": "By completing, you authorize Western Union to send you text message notification(s) about your money transfer status, including notifying you when the Receiver picks up funds. Standard message and data rates apply.",
    "NEED_OPTIONAL_NOTIFICATION": "Would you like to receive an optional notification when the transfer is complete?",
    "MYWU_HISTORY": "MyWU History",
    "WU_TRANSFER_INFO": "Western Union also makes money from currency exchange. When choosing a money transmitter, carefully compare both transfer fees and exchange rates.  Fees and foreign exchange rates may vary by brand, channel, and location based on a number of factors. Fees and rates subject to change without notice.",
    "CONFIRM_TRANSFER": "Confirm Transfer",
    "NOT_A_RECEIPT": "NOT A RECEIPT",
    "WU_CONFIRMATION_INFO": "Date available will be displayed on receipt for international transfers over $15. Service and funds availability depends on certain factors including the Service selected, the selection of delayed delivery options, special terms applicable to each Service, amount sent, destination country, currency availability, regulatory issues, consumer protection issues, identification requirements, delivery restrictions, agent location hours, and differences in time zones (collectively, “Restrictions”).\n\nWestern Union también obtiene ganancias en el cambio de moneda. Al elegir un transmisor de dinero, compara con cuidado las tarifas de envío y las tasas de cambio. Las tarifas y las tasas de cambio pueden variar conforme a la marca, el canal y la localidad a base de una serie de factores. Las tarifas y las tasas de cambio están sujetas a cambio sin previo aviso",
    "SENDER": "Sender",
    "RECIEVER": "Reciever",
    "INVOICE": "Invoice",
    "SENDER_INFO": "Sender Information",
    "EDIT_DETAILS": "Edit Details",
    "CONFIRM_SEND": "Confirm & Send",
    "FINAL": "Final",
    "ORIGINAL": "Original",
    "TRACKING_NUMBER": "Tracking Number",
    "DATE_OF_TRANSACTION": "Date of Transaction",
    "TIME_OF_TRANSACTION": "Time of Transaction",
    "MY_WU_NUMBER": "My WU Number",
    "TRANSFER_DETAILS_INFO_WU": "Date Available will be displayed on receipt for international transfers over $15. Service and funds availability depends on certain factors including the Service selected, the selection of delayed delivery options, special terms applicable to each Service, amount sent, destination country, currency availability, regulatory issues, consumer protection issues, identification requirements, delivery restrictions, agent location hours, and differences in time zones (collectively, “Restrictions”).\n\nWestern Union also makes money from currency exchange. When choosing a money transmitter, carefully compare both transfer fees and exchange rates.  Fees and foreign exchange rates may vary by brand, channel, and location based on a number of factors. Fees and rates subject to change without notice.",
    "TOTAL_POINTS": "Total Points",
    "FAILED_TO_GET_COUNTRIES": "Failed to fetch Country list. Please try again later.",
    "FAILED_TO_GET_STATE": "Failed to fetch State list. Please try again later.",
    "FAILED_TO_CURRENCY_RATE": "Failed to fetch Current Currency Rate. Please try again later.",
    "WU_PRIVACEY_AGGREEMENT": "I agree to the Privacy Statement",
    "WU_TERMS_AGGREEMENT": "I agree to the Terms and Conditions",
    "WU_TERMS_ERROR": "Please accept Terms and Conditions to proceed.",
    "PAY_FROM_ACCOUNT": "Pay From Account",
    "ASSOCIATE_CHECKING": "Associate Checking",
    "PAYOUT_COUNTRY": "Payout Country",
    "PAYOUT_STATE": "Payout State",
    "PAYOUT_CITY": "Payout City",
    "ESTIMATED_TOTAL_TO_RECEIVER": "Estimated total to final receiver",
    "TOTAL_TO_RECEIVER": "Total to Final Receiver",
    "TOTAL": "Total",
    "SERVICE_TYPE": "Service Type",
    "TRANSFER_AMOUNT": "Transfer Amount",
    "TRANSFER_FEES": "Transfer Fees",
    "ADDITIONAL_FEES": "Additional Fees",
    "TRANSFER_TAXES": "Transfer Taxes",
    "PROMOTION_DISCOUNT": "Promotion Discount",
    "OTHER_FEES": "Other Fees",
    "DELIVERY_TYPE_SUMMERY": "Date Available will be displayed on receipt for international transfers over $15. Service and funds availability depends on certain factors including the Service selected, the selection of delayed delivery options, special terms applicable to each Service, amount sent, destination country, currency availability, regulatory issues, consumer protection issues, identification requirements, delivery restrictions, agent location hours, and differences in time zones (collectively, “Restrictions”).",
    "CAN_NOT_BIG_THEN_BALANCE": "Insufficient Balance to carry out the transfer.",
    "INSUFFICIENT_BALANCE_WITH_ALL_CHARGES": "Insufficient Balance after adding all charges. Total payable amount after adding all charges will be {0}",
    "NO_CURRENCY_AVAILABLE": "No Currency available",
    "ESTIMATED_EXCHANGE_RATE": "Estimated Exchange Rate",
    "EXCHANGE_RATE": "Exchange Rate",
    "ESTIMATED_TRANSFER_AMOUNT": "Estimated Transfer Amount",
    "EMPTY_AMOUNT_NOT_ALLOW": "Amount field can not empty",
    "FAILED_TO_SEND_MONEY_VALUATION": "Failed to fetch transaction data. Please try again later.",
    "WU_INVALID_RANGE_MSG": "Please enter Amount between ${0}-${1}",
    "INVITATION_REQUEST_MSG": " wants to add you to their Cuentas Address Book",
    "CHANGE_LANGUAGE": "Change Language",
    "FEATURED_ITEMS": "Featured Items",
    "BUY_NOW": "Buy Now",
    "TRANSFER": "Transfer",
    "AUTH_WU_MESSAGE": "Looking to send money abroad or to non-Cuentas Users?\nWe need to collect some additional info to get started.",
    "WHAT_YOU_WILL_NEED": "What you’ll need:",
    "VALIDATE_EMAIL": "Validate Email",
    "ENABLE_2_FACTOR_AUTHENTICATION": "Enable 2 Factor Authentication",
    "USER_VERIFICATION": "User Verification",
    "UPLOAD_A_VALID_ID": "Upload a Valid ID",
    "PROCEED_AND_SEND_EMAIL_VALIDATION_LINK": "Proceed and Send Email Validation Link",
    "PROCEED_AND_UPLOAD_ID": "Proceed and Upload ID",
    "PROCEED_AND_SEND_SMS_CODE": "Proceed and Send SMS Code",
    "SUBMIT_AND_ENABLE_2FA": "Submit and Enable 2FA",
    "ENTER_EMAIL_VERIFICATION_CODE": "Enter Email Verification Code",
    "EMAIL_VERIFICATION": "Email Verification",
    "PHONE_VERIFICATION": "Phone Verification",
    "ENTER_PIN_IS_INVALID": "The PIN entered is not valid.",
    "WE_SENT_YOU_PIN_TO": "A Code was sent to {0}",
    "PIN_SENT": "PIN sent",
    "PRESS_RESEND_FOR_NEW_CODE": "If you did not receive a Code, please press the \"Resend Code\" button.",
    "FAQS": "FAQs",
    "EMAIL_ITEM": "Email",
    "SENDER_CITY": "Sender City",
    "SENDER_STATE": "Sender State",
    "SENDER_PHONE": "Sender Phone",
    "EXPECTED_FOREIGN_COUNTRY_PAYOUT_LOCATION": "Expected Foreign Country Payout Location",
    "EXPECTED_COUNTRY_PAYOUT_LOCATION": "Expected Payout Location",
    "FOREIGN_COUNTRY_PAYOUT_LOCATION": "Foreign Country Payout Location",
    "COUNTRY_PAYOUT_LOCATION": "Payout Location",
    "FINAL_RECEIVER": "Final Receiver",
    "TRACKING_INFO": "Tracking Information",
    "TRACKING_NUMBER_MTCN": "Tracking number\n(MTCN)",
    "DOCUMENT_NOT_VERIFIED_ERROR": "Document not verified, please try again.",
    "WU_VERIFICATION_SUCCESS_MSG": "You are now eligible for Western Union Transfers",
    "NO_TRANSACTION_FOUND": "This transaction is already Cancelled",
    "TRANSACTION_CANNOT_BE_CANCELLED": "This transaction cannot be Cancelled",
    "MTCN_ON_HOLD": "This transaction is On Hold, please try after few minutes",
    "CANCEL_NOT_ALLOWED": "Cancel not allowed",
    "STATUS_PENDING": "Status: Pending",
    "CANCEL_TRANSFER": "Cancel Transfer",
    "REFUND_TRANSFER": "Refund Transfer",
    "CANCEL_CONFIRMATION": "Would you like to Cancel your Transfer?",
    "REFUND_CONFIRMATION": "Would you like to Refund your Transfer?",
    "SENDER_NAME": "Sender Name",
    "SENDER_ADDRESS": "Sender Address",
    "SENDER_ZIP_CODE": "Sender ZIP Code",
    "SENDER_NOTIFICATION": "Sender Notification",
    "EMAIL_RECEIPT_SUCCESS": "Email Receipt sent successfully",
    "NO_PENDING_DUES": "You don't have any pending dues",
    "REFUNDED": "Refunded",
    "PAID": "Paid",
    "WU_CONTACT_INFO": "For questions or complaints about Western Union, contact:\nSending customer State regulatory name: {0}\nSending customer state regulatory phone #1: {1}\nSending customer state regulatory phone #2: {2}\nState regulatory agency website url: {3}\nConsumer Financial Protection Bureau CFPB phone #1: {4}\nCFPB phone #2: {5}\nCFPB website url: {6}",
    "FULL_NAME": "Full Name",
    "CONTACT_LIST": "Contact List",
    "ACTIVATION_SLIDER_TITLE_1": "Welcome\nto Cuentas",
    "ACTIVATION_SLIDER_TITLE_2": "Activate\nyour Card",
    "ACTIVATION_SLIDER_TITLE_3": "Set up\nDirect Deposit",
    "ACTIVATION_SLIDER_TITLE_4": "Ask friends\nto send you money",
    "ACTIVATION_SLIDER_TITLE_5": "Now you’re ready to\nstart",
    "ACTIVATION_SLIDER_TEXT_1": "We are excited to have you as a cardholder.  Here are a few tips to get you started until you receive your card.",
    "ACTIVATION_SLIDER_TEXT_2": "Go to settings on the Cuentas app, click the link to activate your card. Grab a pen and write down the 4-digit code you see in the app so you can use it to activate your card on the next step.",
    "ACTIVATION_SLIDER_TEXT_3": "Easily set up Direct Deposit of your paycheck or government benefits check, fee free. You will see your Account and Routing number after completing card activation.",
    "ACTIVATION_SLIDER_TEXT_4": "While you wait for your card in the mail, you can ask other Cuentas Cardholders to transfer you money fee-free!",
    "ACTIVATION_SLIDER_TEXT_5": "Browse the Cuentas app store to receive discounts on brands like Amazon, Burger King, and more!",
    "WELCOME_TO_CUENTAS_FAMILY": "Welcome to the Cuentas Family!",
    "SUCCESS_SCREEN_TEXT": "Use the QR code below to download the app and start using your account today. Your personalized card will be delivered in 7-10 business days.",
    "CARD_HOLDER_AGREEMENT_PDF": "app_link_cha_pdf",
    "SCAN": "Scan",
    "BAR_CODE_SCAN_MODAL_HEADING": "Bill Pay Mexico",
    "BAR_CODE_SCAN_MODAL_DESCRIPTION": "To find your <account number>, locate a copy of your bill, and look for the highlighted area below.\n \nYou may also scan the provided bar code.",
    "BAR_CODE_SCAN_MODAL_DESCRIPTION_WITH_NO_IMAGE": "Most companies use either an account number, an invoice number, or a combination of both.  Please review your invoice and locate one or both of these numbers before you begin.",
    "PERMISSION_BLOCKED": "The Camera permission is denied, Please go to settings and enable Camera Permission.",
    'OOPS_ERROR': 'Oops! Something went wrong.'
  }
