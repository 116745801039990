import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import { SET_STEP } from 'constants/App';
import AccountDetailsPage from 'components/Auth';
import AccountForm from 'components/Auth/account';
import VerificationForm from 'components/Auth/verification';
import SuccessPage from 'components/Auth/success';
import Steps from './steps.styles';

const SignupStep = () => {
    const dispatch = useDispatch();
    const step = useSelector((state) => state.theme.step || 1)

    const nextPage = () => {
        dispatch({
            type: SET_STEP,
            step: (step+1)
        })
    }

    const prevPage = () =>  {
        dispatch({
            type: SET_STEP,
            step: (step-1)
        })
    }

    const handleView = () => {
        switch(step){
        case 1:
            return <AccountForm
            className="step-color-pallate"
            prevPage={ prevPage }
            submitData={ nextPage }
            nextPage={ nextPage }
        />
        case 2:
            return <VerificationForm
            className="step-color-pallate"
            prevPage={ prevPage }
            nextPage={ nextPage }
        />
        case 3:
            return <AccountDetailsPage
            className="step-color-pallate"
            prevPage={ prevPage }
            nextPage={ nextPage }
        />
        case 4:
            return <SuccessPage
            className="step-color-pallate"
        />
        default:
            return
        }
    }
    return(
        <Steps>
            {handleView()}
        </Steps>
    )
}

export default withRouter(SignupStep)
