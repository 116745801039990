import React, { useEffect } from 'react';
import {  Field, reduxForm } from 'redux-form';
import { GET_STEP_PROGRESS_BAR } from 'constants/App';
import { useDispatch,useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { resendOtp, verifyOtp } from 'middleware/signup';
import { accountFormValidate as validate } from 'utils/formUtils/validator';
import { OTPField } from 'utils/formUtils/otpField';
import Card from 'components/shared/Card';
import Button from 'components/shared/Button.styled';

import Logo from "../../assests/images/logo.png";
import authBackground from "../../assests/images/auth-bg.png";
import { setData } from 'utils/helpers';

const VerificationForm = (props) => {
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formValues = useSelector((state) => state.form.verification?.values);
  const accountFormValues = useSelector((state) => state.form.account?.values);

  const submit = () => {
    dispatch(verifyOtp(formValues?.otpField, t))
  }

  useEffect(() => {
    dispatch({
        type: GET_STEP_PROGRESS_BAR,
        data: { title: t('CREATE_NEW_ACCOUNT'), step: 2 }
    })
  },[])

  useEffect(() => {
    setData('step', 2)
  },[])

  const resendOtpHandler = () => {
    dispatch(resendOtp(accountFormValues?.phoneNumber,t))
  }

  const goBack = () => {
    props.prevPage()
  }

  return (
    <div className="auth-container h-100" style={{ backgroundImage: `url(${authBackground})` }}>
     <div className="logo-container text-center">
        <img src={Logo} alt=""/>
      </div>
      <Card className="main-card">
      <form className="margin-top space-top" onSubmit={ handleSubmit( submit ) } >
      <div className="scrollable-content account-scrollable-content">
      <h5 className="text-center">{t('ENTER_SMS_CODE')}</h5>
      <h6 className="text-center">{t('ENTER_CODE_TEXT')} {accountFormValues?.phoneNumber}</h6>
      <div className="otp otp-field">
        <Field
          name="otpField"
          type="text"
          component={OTPField}
        />
      </div>
      <h6 className="text-center"><span>{t('ENTER_CODE_MESSAGE')}</span></h6>
      <div className="resend-link text-center">
      <a onClick={() => resendOtpHandler()}>{t('RESEND_CODE')}</a>
      </div>
      </div>
      <div className="d-flex mt-3 justify-content-between flex-btns">
        <Button className="back-button" onClick={() => goBack()}> {t('BACK') }</Button>
        <Button outlined type='submit' className="confirm-mobile">{t('VERIFY')}</Button>
      </div>
      </form >
      </Card>
      <div className="height-block d-none"></div>
      </div>
  )
}

export default reduxForm({
    form: 'verification', // a unique identifier for this form
    destroyOnUnmount: false,
    validate
})(VerificationForm);