import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';

import { SET_DOCUMENTS_STEP } from 'constants/App';
import CardHolderAgreement from 'components/Documents/cardHolderAgreement';
import ShortFeesAgreement from 'components/Documents/shortFees';
import LongFeesAgreement from 'components/Documents/longFees';
import history from 'utils/history';

import Steps from './steps.styles';
import { ROUTES } from 'constants/AppRoutes';

const DocumentStep = () => {
    const dispatch = useDispatch();
    const step = useSelector((state) => state.theme.documentStep || 1)

    const nextPage = () => {
        dispatch({
            type: SET_DOCUMENTS_STEP,
            step: (step+1)
        })
    }

    const prevPage = () =>  {
        dispatch({
            type: SET_DOCUMENTS_STEP,
            step: (step-1)
        })
    }

    useEffect(() => {
        history.listen((location) => {
            if(location.pathname === ROUTES.CHA_DOCUMENT) {
                dispatch({
                    type: SET_DOCUMENTS_STEP,
                    step: 1
                })
            }
            if(location.pathname === ROUTES.SHORT_FEES_DOCUMENT) {
                dispatch({
                    type: SET_DOCUMENTS_STEP,
                    step: 2
                })
            }
            if(location.pathname === ROUTES.LONG_FEES_DOCUMENT) {
                dispatch({
                    type: SET_DOCUMENTS_STEP,
                    step: 3
                })
            }
        });
    })

    useEffect(() => {
        if(step === 1){
            history.push(ROUTES.CHA_DOCUMENT)
        }else if(step === 2){
            history.push(ROUTES.SHORT_FEES_DOCUMENT)
        }else if(step ===3){
            history.push(ROUTES.LONG_FEES_DOCUMENT)
        }
    },[step])

    const handleView = () => {
        switch(step){
        case 1:
            return <CardHolderAgreement
            className="step-color-pallate"
            nextPage={ nextPage }
        />
        case 2:
            return <ShortFeesAgreement
            className="step-color-pallate"
            prevPage={ prevPage }
            nextPage={ nextPage }
        />
        case 3:
            return <LongFeesAgreement
            className="step-color-pallate"
            prevPage={ prevPage }
            nextPage={ nextPage }
        />
        default:
            return
        }
    }
    return(
        <Steps>
            {handleView()}
        </Steps>
    )
}

export default withRouter(DocumentStep)
