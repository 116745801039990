import Styled from 'styled-components';

export const Nav = Styled.nav`
    max-width: 300px;
    z-index: 1;
    position: fixed;
    top: 5rem;
    left:0;right:0;
    margin: auto;
    .top-header {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        padding-bottom: 50px;
        a {
            width: 4%;
        }
        .sentIcon {
            display: none;
        }
    }
    .progress-wrapper {
        width: 100%;
        color: ${ (props) => props.theme.textBlack };
        h3 {
            font-family: font-bold;
            line-height: 21px;
            margin-top: 25px;
        }
        .progressbar {
            counter-reset: step;
            padding: 0;
            display: flex;
            justify-content: center;
            li {
                list-style-type: none;
                float: left;
                width: calc(25% - 14px);
                height: 10px;
                position: relative;
                text-align: center;
                margin-right: 14px;
                box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 38px;
                margin-top: 12px;

                font-weight: 500;
                font-size: 12px;
                line-height: 30px;
                color: ${ (props) => props.theme.success };
                &:last-child {
                    margin-right: 0;
                }
                &.active {
                    background: ${ (props) => props.theme.success };
                    border: 3px solid ${ (props) => props.theme.successDisable };
                }
            }
        }

    }
    .slider-wrapper{
        margin-bottom: 40px;
        .progressbar{
            display: flex;
            justify-content: center;
            li{
                width: calc(25% - 65px) !important;
            }
        }
    }

    @media (max-width: 768px){
        .slider-wrapper{
            margin-bottom: 0;
        }
    }

`;
