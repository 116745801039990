import { TRANSLATIONS_EN }  from 'translations/locales/en';
import { TRANSLATIONS_ES } from 'translations/locales/es';
import parse from 'html-react-parser';
import moment from 'moment';
import { GET_STEP_PROGRESS_BAR,GET_STEP_DOCUMENT_PROGRESS_BAR } from 'constants/App';
import { ROUTES } from 'constants/AppRoutes';

export const getToken = () => {
    return localStorage.getItem('token')
}

export const locale = () => {
    return localStorage.getItem('locale')
}

export const setData = async(name, value) => {
    await localStorage.setItem(name,value)
}

export const getData = (name) => {
    return localStorage.getItem(name)
}

export const getLocalByTitle = (title) => {
    const localType = locale()
    const TRANSLATION = localType === 'es' ? TRANSLATIONS_ES: TRANSLATIONS_EN
    return TRANSLATION[ title ]
}

export const getArticleById = (articles, id) => {
    const article = articles && articles.filter((item) => item.id === id)[ 0 ]
    return article?.body ? parse(article.body) : '' ;
}

export const getArticleByName = (articles, name) => {
    const article = articles && articles.filter((item) => item.name === name)[ 0 ]
    return article ? article.value : '';
}

export const getStringBeforeChar = (string, char) => {
    const finalString = string && string.substring(0, string.indexOf(char))
    return finalString ? finalString : '';
}

export const getOnlyNumbers = (value) => {
    return value && value?.replace(/[^\d]/g, '')
}

export const putArticlesParams = (feesType) => {
    const shortFeesParams =  { 'additional_properties': { 'fees_short_tnc': { 'type': 'boolean', 'value': true } } }
    const longFeesParams = { 'additional_properties': { 'fees_long_tnc': { 'type': 'boolean', 'value': true } } }
    return feesType === 'short' ? shortFeesParams : longFeesParams;
}

export const getEighteenYearsAgoDate = () => {
    return moment().subtract(18, 'years')._d;
}

export const normalizeSSN = (value, previousValue) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (!previousValue || value.length > previousValue.length) {
        if (onlyNums.length === 3) {
            return onlyNums + '-'
        }
        if (onlyNums.length === 5) {
            return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-'
        }
    }
    if (onlyNums.length <= 3) {
        return onlyNums
    }
    if (onlyNums.length <= 5) {
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
    }
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 5) + '-' + onlyNums.slice(5, 9)
}

export const normalizeZip = (value) => {
    if (!value) {
        return value
    }
    const onlyNums = value.replace(/[^\d]/g, '')
    if (onlyNums.length === 5) {
            return onlyNums.slice(0, 5)
    }
    if (onlyNums.length < 5) {
        return onlyNums
    }
    return onlyNums.slice(0, 5)
}

export const getIncommHeaders = (username) => {
    const appHeaders = {
        'x-knetikcloud-channel': 'WEB',
        'x-knetikcloud-ip':'49.36.16.55' ,
        'x-knetikcloud-username': username || '',
        'x-knetikcloud-appid': 'cuentas',
        'x-knetikcloud-date': new Date(),
    }
    return appHeaders
}

export const getPostRegData = (signup, account, accountDetails, userDetails, verificationId) => {
    const state = !accountDetails?.state?.value && JSON.parse(accountDetails?.state)?.data && JSON.parse(accountDetails?.state)?.data[0]?.state_cd;
    const postData = {
        "registrationInfo": {
          "starterPAN": "",
          "productCategoryId": "0000",
          "accountHolder": {
            "firstName": account?.firstName || userDetails?.first_name || '',
            "middleName": account?.middleName || userDetails?.middle_name || '',
            "lastName": account?.lastName || userDetails?.last_name || '',
            "dateOfBirth": moment(accountDetails?.birthDate).format('YYYY-MM-DD') || '',
            "emailAddress": signup?.email || userDetails?.email || '',
            "mothersMaidenName": accountDetails?.maidenName || '',
            //"activationCode": accountDetails?.activationCode || '',
            "verificationId": verificationId || '',
            "address": [
              {
                "address1":  accountDetails?.address || '',
                "address2": accountDetails?.streetDetail || '',
                "city": accountDetails?.city || '',
                "state": state || accountDetails?.state?.value,
                "postalCode": accountDetails?.zip || '',
                "countryCode": "USA",
                "type": "PHYSICAL"
              }
            ],
            "phone": [
              {
                "number": getOnlyNumbers(account?.phoneNumber || userDetails?.mobile_number) || '',
                "type": "MOBILE"
              }
            ],
            "identification": {
              "type": "SSN",
              "number": getOnlyNumbers(accountDetails?.ssn) || '',
            }
          }
        }
      }
    return postData;
}

export const navigationStep = (userInfoDetails, history) => {
    let ismobileVerified = userInfoDetails?.additional_properties?.mobile_verified?.value;
    let isShortFeesAgreed = userInfoDetails?.additional_properties?.fees_short_tnc?.value;
    let isLongFeesAgreed = userInfoDetails?.additional_properties?.fees_long_tnc?.value;
    let idologyId = userInfoDetails?.additional_properties?.idology_id?.value;
    let incommCxId = userInfoDetails?.additional_properties?.incomm_customer_id?.value;

    if (userInfoDetails && !ismobileVerified) {
        history.push(ROUTES.VERIFICATION)
        return [GET_STEP_PROGRESS_BAR, 2];
    }else if (userInfoDetails && ismobileVerified && !isShortFeesAgreed) {
        history.push(ROUTES.CHA_DOCUMENT)
        return [GET_STEP_DOCUMENT_PROGRESS_BAR, 1];
    }else if(userInfoDetails && ismobileVerified && isShortFeesAgreed && !isLongFeesAgreed) {
        history.push(ROUTES.LONG_FEES_DOCUMENT)
        return [GET_STEP_DOCUMENT_PROGRESS_BAR, 3];
    }else if (userInfoDetails && ismobileVerified && isShortFeesAgreed && isLongFeesAgreed && !idologyId) {
        history.push(ROUTES.VERIFICATION)
        return [GET_STEP_PROGRESS_BAR , 3];
    } else if (userInfoDetails && ismobileVerified && isShortFeesAgreed && isLongFeesAgreed && idologyId && !incommCxId) {
        history.push(ROUTES.VERIFICATION)
        return [GET_STEP_PROGRESS_BAR , 3];
    }else if (userInfoDetails && ismobileVerified && isShortFeesAgreed && isLongFeesAgreed && idologyId?.length > 0 && incommCxId?.length > 0) {
        let idologyResult = userInfoDetails?.additional_properties?.idology_result?.value;
        if (idologyResult === 'Not Approved') {
            history.push(ROUTES.VERIFICATION)
            return [GET_STEP_PROGRESS_BAR , 3];
        } else if (idologyResult === 'Approved') {
            history.push(ROUTES.VERIFICATION)
            return [GET_STEP_PROGRESS_BAR , 4];
        } else if(idologyResult === null) {
            history.push(ROUTES.VERIFICATION)
            return [GET_STEP_PROGRESS_BAR , 3];
        }
    } else {
        return [GET_STEP_PROGRESS_BAR, 1];
    }
}
