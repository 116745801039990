import React, { useEffect }  from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getUserDetails } from 'middleware/signup';
import Modal from 'components/shared/Modal';

const Iframe = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.signup?.userResponse);
  const verifyUserData = useSelector((state) => state.signup?.userData);
  const showModal = useSelector((state) => state.theme.isModalOpen);
  const signup =  useSelector((state) => state.form?.signup?.values);
  const account =  useSelector((state) => state.form?.account?.values);
  const accountDetails =  useSelector((state) => state.form?.accountDetails?.values);
  let counter = 0;

  useEffect(() => {
    if(verifyUserData?.additional_properties?.idology_result?.value){
      dispatch({ type: 'TOGGLE_MODAL', set: false })
    }
  }, [verifyUserData])

  const handle = () => {
    counter = counter+1;
    if(counter === 2){
      dispatch(getUserDetails(signup, account, accountDetails,t))
    }
  }

  return (
    <div className="iframe-modal">
    <Modal
      show={showModal}
      showProgressBar={false}
    >
      {data?.response &&
      <div>
      <iframe
        title="idology"
        width="100%"
        onLoad={() => handle()}
        src={data?.response && data?.response['id-scan-url']}
        id="myId"
        display="initial"
      >
      </iframe>
      </div>
      }
      </Modal>
      </div>
  )
}

export default Iframe;