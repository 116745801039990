export const ActionTypes = {
    GET_ARTICLES_REQUEST: 'GET_ARTICLES_REQUEST',
    GET_ARTICLES_SUCCESS: 'GET_ARTICLES_SUCCESS',
    GET_ARTICLES_FAILED: 'GET_ARTICLES_FAILED',
    GET_ARTICLES_LINK_REQUEST: 'GET_ARTICLES_LINK_REQUEST',
    GET_ARTICLES_LINK_SUCCESS: 'GET_ARTICLES_LINK_SUCCESS',
    GET_ARTICLES_LINK_FAILED: 'GET_ARTICLES_LINK_FAILED',
    PUT_ARTICLES_REQUEST: 'PUT_ARTICLES_REQUEST',
    PUT_ARTICLES_SUCCESS: 'PUT_ARTICLES_SUCCESS',
    PUT_ARTICLES_FAILED: 'PUT_ARTICLES_FAILED',
}