import React, { useEffect , useState} from 'react';
import { useDispatch } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { GET_STEP_DOCUMENT_PROGRESS_BAR } from 'constants/App';
import { renderCheckboxField } from 'utils/formUtils';
import { getArticlesLink } from 'middleware/documents';
import { getData,getArticleByName, locale } from 'utils/helpers';
import Button from 'components/shared/Button.styled';
import Modal from 'components/shared/Modal';
import history from 'utils/history';
import { ROUTES } from 'constants/AppRoutes';
import { notification } from 'services/notification';
import FileViewer from 'react-file-viewer';
import {EN_CHA_APP_LINK,ES_CHA_APP_LINK } from 'constants/App'

const CardHolderAgreement = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const articlesLink = useSelector((state) => state.documents.link || {});
  const isChecked = useSelector((state) => state.form.cardHolder?.values?.agreement);
  const [isBottom, setIsBottom] = useState(false);
  const [isPdfLoaded, setIsPdfLoaded] = useState(false);
  const [pdf ,setPdf] = useState('');
  const chaPdfName = locale() === 'en' ? EN_CHA_APP_LINK : ES_CHA_APP_LINK;

  useEffect(() => {
    dispatch({
        type: GET_STEP_DOCUMENT_PROGRESS_BAR,
        data: { title: t('CARHOLDER_AGREEMENT'), documentStep: 1 }
    })
   dispatch(getArticlesLink())
  },[])

  useEffect(() => {
    const id = getArticleByName(articlesLink.content, chaPdfName)
    if(id && id!=""){
      var xhr = new XMLHttpRequest();
      xhr.open("GET", id, true);
      xhr.responseType = "blob";
      xhr.onload = function() {
          if(xhr.status && xhr.status === 200) {
            savePdf(xhr.response, "pdfUrl");
          }
      }
      xhr.send();
    }
  }, [articlesLink])

  const savePdf = (pdf, key) => {
    var fileReader = new FileReader();

    fileReader.onload = function (evt) {
        var result = evt.target.result;

        try {
            localStorage.setItem(key, result);
            setPDF();
        } catch (e) {
            console.log("Storage failed: " + e);
        }
    };

    fileReader.readAsDataURL(pdf);
}

  const setPDF = ()=>{
    const pdfLink = getData('pdfUrl')
    setPdf(pdfLink);
    setIsPdfLoaded(true);
  }

  const onClickHandler = () => {
    if(!isBottom){
      notification('error', t('SCROLL_TO_BOTTOM'));
    } else {
      history.push(ROUTES.SHORT_FEES_DOCUMENT)
    }
  }

  const handleScroll = (event) => {
    const target = event?.target;
    if(target.scrollHeight - target.scrollTop <= target.clientHeight + 10){
      setIsBottom(true)
    }
  }

   useEffect(() =>{
    const resizeObserver = new ResizeObserver(() => {
       if(window?.innerWidth < 1024){
        setIsBottom(true)
      }
    });
    resizeObserver.observe(document.getElementById("pdf-div"));
   },[])

   const handleLoad = () => {
    const resizeObserver = new ResizeObserver((entries) => {
      const target = entries[0]?.target;
      if(target?.scrollHeight - target?.scrollTop <= target?.clientHeight + 10){
        setIsBottom(true)
      }
    });
    resizeObserver.observe(document.getElementById("pdf-div"));
  }

  return (
    <>
    <Modal
      show={true}
    >
      <div className="pdf-block">
        <div
          className="pdf-scroll"
          id="pdf-div"
          onLoad={() => handleLoad()}
          onScroll={(e) => handleScroll(e)}
        >
        {pdf &&
          <FileViewer
            fileType={'pdf'}
            filePath={pdf}
          />
        }
      </div>
      </div>
      <div className="d-flex">
      <Field
        name="agreement"
        type="checkbox"
        component={renderCheckboxField}
      />
      <p>{t('ACCEPT_TNC')}</p>
      </div>
      <div className="text-center">
      <Button outlined type='submit' disabled={!isChecked || !isPdfLoaded} onClick={() => onClickHandler()}>{t('AGREE_TEXT')}</Button>
      </div>
    </Modal>
    </>
  )
}

export default reduxForm({
  form: 'cardHolder', // a unique identifier for this form
  destroyOnUnmount: false,
})(CardHolderAgreement);