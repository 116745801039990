import React from 'react';
import { CardWrapper } from './Card.styled';
import { Layout } from './Layout';

const Card = (props) => {
  const {children} = props;

  return (
      <CardWrapper>
        <Layout>{children}</Layout>
      </CardWrapper>
    );
};

export default Card;