import React, { useEffect, useState } from 'react';
import { Field, reduxForm, change } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import moment from 'moment';
import Iframe from'./iframeView';

import { GET_STEP_PROGRESS_BAR } from 'constants/App';
import { getLocationAddress,postUserDetails, putUsersAddressDetails,putUsersPromoDetails } from 'middleware/signup';
import { accountDetailsFormValidate as validate } from 'utils/formUtils/validator';
import { renderDatepickerField, renderField, renderSelectField } from 'utils/formUtils';
import Card from 'components/shared/Card';
import Button from 'components/shared/Button.styled';
import { normalizeSSN,normalizeZip, getOnlyNumbers, getEighteenYearsAgoDate, getData } from 'utils/helpers';

import Logo from "../../assests/images/logo.png";
import authBackground from "../../assests/images/auth-bg.png";
import { US_STATES } from 'constants/state';

const AccountDetailsForm = (props) => {
  const { handleSubmit } = props;
  const latitude = getData('latitude');
  const longitude = getData('longitude');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const signup =  useSelector((state) => state.form?.signup?.values);
  const accountFormValues = useSelector((state) => state.form.account?.values);
  const address = useSelector((state) => state.signup?.address);
  const userDetails = useSelector((state) => state.signup?.userData);
  const [ state, setState ] = useState(null);
  const [ selectedState, setSelectedState] = useState(null)
  const [ birthDate, setBirthDate] = useState(null);
  const [ zipCode, setZipCode] = useState(null);

  const submit = (values) => {
    const submitValues = {
      ...values,
      ssn: getOnlyNumbers(values.ssn),
      dobYear: values?.birthDate && values?.birthDate.getFullYear(),
      dobMonth: values?.birthDate && values?.birthDate.getMonth() + 1,
      dobDay: values?.birthDate && values?.birthDate.getDate()
    }
    dispatch(putUsersAddressDetails(values, state?.value))
    if(values?.promoCode?.length > 0){
      dispatch(putUsersPromoDetails(signup,accountFormValues,submitValues,state?.value, userDetails,values?.promoCode,t))
    } else if (submitValues) {
      dispatch(postUserDetails(signup,accountFormValues,submitValues,state?.value,userDetails,t ))
    }
  }

  useEffect(()=>{
      if(latitude!=='undefined' && longitude!=='undefined'){
        dispatch(getLocationAddress('',latitude,longitude))
      }
  }, [latitude, longitude])

  useEffect(() => {
    dispatch({
        type: GET_STEP_PROGRESS_BAR,
        data: { title: t('CREATE_NEW_ACCOUNT'), step: 3 }
    })
  },[])

  useEffect(()=> {
  const stateValue = US_STATES?.filter((state) => state?.value === address?.state)
    if(address?.country === 'US'){
      dispatch(change('accountDetails','zip',address?.postalCode))
      dispatch(change('accountDetails','city',address?.city))
      if(stateValue && stateValue[0]) {
        setSelectedState({label: stateValue[0]?.label, value:stateValue[0]?.value })
        setState(stateValue[0])
        dispatch(change('accountDetails','zip',address?.postalCode))
        dispatch(change('accountDetails','city',address?.city))
        dispatch(change('accountDetails','state',{ label:stateValue[0]?.label, value:stateValue[0]?.value }))
      }
    }else {
      dispatch(change('accountDetails','zip',address?.postalCode || zipCode))
      dispatch(change('accountDetails','city',address?.city))
    }
  }, [address])

  const handleChangeState = (event) => {
    setState(event)
    setSelectedState({ label: event?.label, value: event?.value })
    dispatch(change('accountDetails','state',null))
  }

  const handleChangeBirthDate = (event) => {
    setBirthDate(event)
    if(event){
      dispatch(change('accountDetails','birthDate',null))
    }
  }

  const debounced = useDebouncedCallback((value) => {
    setZipCode(value)
    if(`${value}`.length <=5)
      dispatch(getLocationAddress(value))
  }, 500);

  return (
      <div className="auth-container h-100" style={{ backgroundImage: `url(${authBackground})` }}>
      <div className="logo-container text-center">
        <img src={Logo} alt=""/>
      </div>

      <Card className="main-card">
        <h4>{t('ADDRESS_INFO')}</h4>
      <form className="account-margin-top" onSubmit={ handleSubmit( submit ) } >
        <div className="account-card">
      <Field
        name="address"
        type="text"
        placeholder={t('ADDRESS')}
        component={renderField}
      />
       <Field
        name="streetDetail"
        type="text"
        placeholder={t('APT')}
        component={renderField}
      />
      <Field
        name="zip"
        type="tel"
        normalize={normalizeZip}
        placeholder={t('ZIP')}
        handleChange={(e) => debounced(e)}
        component={renderField}
      />
      <Field
        name="city"
        type="text"
        placeholder={t('CITY')}
        component={renderField}
      />
      <Field
        name="state"
        placeholder={t('STATE_TEXT') }
        handleChange = {handleChangeState}
        options= {US_STATES}
        selectedOption={selectedState}
        component={renderSelectField}
      />
      <div className="date-field">
      <label>{t('ENTER_BIRTHDAY')}</label>
      <Field
        name="birthDate"
        maxDate={getEighteenYearsAgoDate()}
        value={moment(birthDate).format('YYYY/MM/DD')}
        handleChange={handleChangeBirthDate}
        placeholder={t('ENTER_BIRTHDAY')}
        component={renderDatepickerField}
      />
      </div>
      <label>{t('PASSWORD_SECURITY')}</label>
      <Field
        name="maidenName"
        type="text"
        placeholder={t('MAIDEN_NAME')}
        component={renderField}
      />
      <Field
        name="ssn"
        type="text"
        normalize={normalizeSSN}
        placeholder={t('SSN')}
        component={renderField}
      />
      <label>{t('PROMO_CODE')}</label>
      {/* <Field
        name="activationCode"
        type="text"
        placeholder={t('ENTER_ACTIVATION_CODE')}
        component={renderField}
      /> */}
       <Field
        name="promoCode"
        type="text"
        placeholder={t('ENTER_PROMO_CODE')}
        component={renderField}
      />
      </div>
      <div className="d-flex mx-3 justify-content-center">
      <Button outlined type='submit' className="submit-btn" >{t('SIGN_UP')}</Button>
      </div>
      </form>
      </Card>
      <div className="height-block d-none"></div>
      <div className="iframe-section">
      <Iframe/>
      </div>
      </div>
  )
}

export default reduxForm({
    form: 'accountDetails', // a unique identifier for this form
    validate
})(AccountDetailsForm);