export const SET_STEP = 'SET_STEP'
export const SET_DOCUMENTS_STEP = 'SET_DOCUMENTS_STEP'
export const SET_SLIDER_STEP = 'SET_SLIDER_STEP'
export const GET_STEP_PROGRESS_BAR = 'GET_STEP_PROGRESS_BAR'
export const GET_STEP_DOCUMENT_PROGRESS_BAR = 'GET_STEP_DOCUMENT_PROGRESS_BAR'
export const GET_STEP_SLIDER_PROGRESS_BAR = 'GET_STEP_SLIDER_PROGRESS_BAR'
export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const EN_CHA_APP_LINK = 'app_link_cha_pdf'
export const ES_CHA_APP_LINK = 'es_app_link_cha_pdf'
export const SET_DOMAIN = 'SET_DOMAIN'
export const EN_SHORT_FEES = 'en_fees_short_form'
export const ES_SHORT_FEES = 'es_fees_short_form'
export const EN_LONG_FEES = 'en_fees_long_form'
export const ES_LONG_FEES = 'es_fees_long_form'
