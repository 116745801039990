import { ActionTypes } from 'actions/documents/actionTypes'
const initialState = {
    loading: false,
}
const documentsReducer = (state = initialState, action) => {
    switch (action.type) {
    case ActionTypes.GET_ARTICLES_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.GET_ARTICLES_SUCCESS:
        return { ...state,loading: false , list: action.payload  };
    case ActionTypes.GET_ARTICLES_FAILED:
        return { ...state, loading: false };
    case ActionTypes.GET_ARTICLES_LINK_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.GET_ARTICLES_LINK_SUCCESS:
        return { ...state,loading: false , link: action.payload  };
    case ActionTypes.GET_ARTICLES_LINK_FAILED:
        return { ...state, loading: false };
    case ActionTypes.PUT_ARTICLES_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.PUT_ARTICLES_SUCCESS:
        return { ...state,loading: false };
    case ActionTypes.PUT_ARTICLES_FAILED:
        return { ...state, loading: false };
    default:
        return state;
    }
};

export default documentsReducer