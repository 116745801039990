import {
    SET_STEP,
    GET_STEP_PROGRESS_BAR,
    TOGGLE_MODAL,
    SET_DOCUMENTS_STEP,
    GET_STEP_DOCUMENT_PROGRESS_BAR,
    GET_STEP_SLIDER_PROGRESS_BAR,
    SET_SLIDER_STEP,
    SET_DOMAIN
} from 'constants/App'
const initialState = {
    step: 1,
    documentStep: 1,
    sliderStep: 1
}

const themeReducer = (state = initialState, action) => {
    switch (action.type) {
    case GET_STEP_PROGRESS_BAR:
        return { ...state, stepData: action.data };
    case GET_STEP_DOCUMENT_PROGRESS_BAR:
        return { ...state, documentData: action.data };
    case GET_STEP_SLIDER_PROGRESS_BAR:
        return { ...state, sliderStep: action.step };
    case SET_STEP:
        return { ...state, step: action.step };
    case SET_SLIDER_STEP:
        return { ...state, sliderStep: action.step };
    case SET_DOCUMENTS_STEP:
        return { ...state, documentStep: action.step };
    case SET_DOMAIN:
        return { ...state, domain: action.data };
    case TOGGLE_MODAL:
        return { ...state, isModalOpen: action.set };
    default:
        return state;
    }
};

export default themeReducer