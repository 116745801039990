import SliderCard from 'components/shared/SliderCard';
import { GET_STEP_SLIDER_PROGRESS_BAR } from 'constants/App';
import { t } from 'i18next';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router';
import Logo from "../../assests/images/logo.png";
import authBackground from "../../assests/images/auth-bg.png";
import Button from 'components/shared/Button.styled';
import history from 'utils/history';
import { ROUTES } from 'constants/AppRoutes';

import Arrow from "../../assests/images/arrow.svg";

const Slider = (props) => {
    const dispatch = useDispatch();
    const {
      title,
      body,
      footer,
      subTitle,
      subText1,
      subText2,
      subText3,
      subText4,
      subText5,
      imgSrc,
      step} = props;

    const navigate = () => {
      history.push(ROUTES.SIGNUP)
    }

    useEffect(() => {
        dispatch({
            type: GET_STEP_SLIDER_PROGRESS_BAR,
            sliderStep: `${step}`
        })
      },[])

    return(
        <div className="auth-container slider-page h-100 notranslate" style={{ backgroundImage: `url(${authBackground})` }}>
        <div className="logo-container text-center">
          <img src={Logo} alt=""/>
        </div>
        <SliderCard className="main-card">
          <div className="slider-text">
          <div className="slider-content">
            <img src={imgSrc} alt="slider-img"/>
            <h3>{title}</h3>
          <p>{body}</p>
          <p className="text-left">{subTitle && <em>{subTitle}</em>}</p>
          <ul className="text-left padding-left">
          {subText1 && <li>{subText1}</li>}
            {subText2 && <li>{subText2}</li> }
            {subText3 && <li>{subText3}</li>}
            {subText4 && <li>{subText4}</li>}
            {subText5 && <li>{subText5}</li>}
          </ul>
          {footer && <div className="footer">{footer}</div>}
          </div>
          

        <div className="slider-btns">
        <Button disabled={step===1} onClick={() => props.prevPage()} className="slider-btn-prev"> <img src={Arrow}/> </Button>
        <Button disabled={step===4} onClick={() => props.nextPage()} className="slider-btn-next"> <img src={Arrow}/> </Button>
        </div>

         <div className="text-center slider-content">
          <Button outlined onClick={() => navigate()} type='submit' className="submit-btn">{t('SIGN_UP')}</Button>
          </div>
          </div>
        </SliderCard>
        <div className="height-block extra-height d-none"></div>
        </div>
    )
}

export default withRouter(Slider)
