import {
    getArticlesFailed,
    getArticlesLinkFailed,
    getArticlesLinkRequest,
    getArticlesLinkSuccess,
    getArticlesRequest,
    getArticlesSuccess,
    putArticlesFailed,
    putArticlesRequest,
    putArticlesSuccess,
} from 'actions/documents';
import axiosInstance from 'services/api';
import { putArticlesParams } from 'utils/helpers';

export const getArticles = () => {
    const data = {
        size: 50,
        tags: [ 'card_holder_agreement', 'short_fees', 'long_fees' ],
    };
    return async (dispatch) => {
        dispatch(getArticlesRequest());
        try {
            const result = await axiosInstance.post('articles/search', data);
            dispatch(getArticlesSuccess(result.data));
        } catch (e) {
            dispatch(getArticlesFailed());
        }
    };
};

export const getArticlesLink = () => {
    return async (dispatch) => {
        dispatch(getArticlesLinkRequest());
        try {
            const result = await axiosInstance.get('configs?filterSearch=app_link');
            dispatch(getArticlesLinkSuccess(result.data));
        } catch (e) {
            dispatch(getArticlesLinkFailed());
        }
    };
};

export const putArticles = (feesType) => {
    const data = putArticlesParams(feesType);
    return async (dispatch) => {
        dispatch(putArticlesRequest());
        try {
            const result = await axiosInstance.put('users/me', data);
            dispatch(putArticlesSuccess(result.data));
        } catch (e) {
            dispatch(putArticlesFailed());
        }
    };
};
