import React from 'react';
import {
  Switch,
  Route,
  withRouter
} from "react-router-dom";
import { ROUTES } from 'constants/AppRoutes';
import SignupStep from 'components/steps/index';
import DocumentStep from 'components/steps/documents';
import Signup from 'components/Auth/signup';
import OnboardingStep from 'components/steps/onboarding';
import ErrorPage from 'components/Error';
import { PrivateRoute } from 'routes/hoc/PrivateRoute';

function App() {
  return (
    <Switch>
        <Route exact
            path={ ROUTES.ROOT }
            component={ OnboardingStep }
        />
        <Route exact
            path={ ROUTES.SIGNUP }
            component={ Signup }
        />
        <Route exact
            path={ ROUTES.VERIFICATION }
            component={ SignupStep }
        />
        <PrivateRoute exact
            path={ ROUTES.CHA_DOCUMENT }
            component={ DocumentStep }
        />
        <PrivateRoute exact
            path={ ROUTES.SHORT_FEES_DOCUMENT }
            component={ DocumentStep }
        />
        <PrivateRoute exact
            path={ ROUTES.LONG_FEES_DOCUMENT }
            component={ DocumentStep }
        />
        <Route component={ ErrorPage } />
    </Switch>
  );
}

export default withRouter(App);
