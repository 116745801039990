import { ActionTypes } from 'actions/signup/actionTypes'
const initialState = {
    loading: false,
    emailResponse: { exists: true },
    exists : false,
    address: {}
}
const signupReducer = (state = initialState, action) => {
    switch (action.type) {
    case ActionTypes.VERIFY_EMAIL_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.VERIFY_EMAIL_SUCCESS:
        return { ...state,loading: false , emailResponse: action.payload  };
    case ActionTypes.VERIFY_EMAIL_EXISTS_SUCCESS:
        return { ...state,loading: false , exists: action.payload };
    case ActionTypes.VERIFY_EMAIL_FAILED:
        return { ...state, loading: false };
    case ActionTypes.POST_USER_DATA_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.POST_USER_DATA_SUCCESS:
        return { ...state,loading: false , userResponse: action.payload  };
    case ActionTypes.POST_USER_DATA_FAILED:
        return { ...state, loading: false };
    case ActionTypes.VERIFY_OTP_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.VERIFY_OTP_SUCCESS:
        return { ...state,loading: false };
    case ActionTypes.VERIFY_OTP_FAILED:
        return { ...state, loading: false };
    case ActionTypes.RESEND_OTP_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.RESEND_OTP_SUCCESS:
        return { ...state,loading: false };
    case ActionTypes.RESEND_OTP_FAILED:
        return { ...state, loading: false };
    case ActionTypes.GET_STATES_REQUEST:
        return { ...state, loading: true, statesLoading: true };
    case ActionTypes.GET_STATES_SUCCESS:
        return { ...state,loading: false, statesLoading: false,states: action.payload };
    case ActionTypes.GET_STATES_FAILED:
        return { ...state, loading: false, statesLoading: false };
    case ActionTypes.POST_USER_DETAILS_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.POST_USER_DETAILS_SUCCESS:
        return { ...state,loading: false , userResponse: action.payload  };
    case ActionTypes.POST_USER_DETAILS_FAILED:
        return { ...state, loading: false };
    case ActionTypes.GET_LOCATION_ADDRESS_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.GET_LOCATION_ADDRESS_SUCCESS:
        return { ...state,loading: false, address: action.payload };
    case ActionTypes.GET_LOCATION_ADDRESS_FAILED:
        return { ...state, loading: false };
    case ActionTypes.GET_USER_DETAILS_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.GET_USER_DETAILS_SUCCESS:
        return { ...state,loading: false, userData: action.payload };
    case ActionTypes.GET_USER_DETAILS_FAILED:
        return { ...state, loading: false };
    case ActionTypes.POST_REGISTRATION_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.POST_REGISTRATION_SUCCESS:
        return { ...state, loading: false, regData: action.payload };
    case ActionTypes.POST_REGISTRATION_FAILED:
        return { ...state, loading: false };
    case ActionTypes.PUT_PROMOCODE_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.PUT_PROMOCODE_SUCCESS:
        return { ...state,loading: false };
    case ActionTypes.PUT_PROMOCODE_FAILED:
        return { ...state, loading: false };
    case ActionTypes.PUT_USER_LANGUAGE_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.PUT_USER_LANGUAGE_SUCCESS:
        return { ...state,loading: false };
    case ActionTypes.PUT_USER_LANGUAGE_FAILED:
        return { ...state, loading: false };
    case ActionTypes.SIGNUP_REQUEST:
        return { ...state, loading: true };
    case ActionTypes.SIGNUP_SUCCESS:
        return { ...state,loading: false };
    case ActionTypes.SIGNUP_FAILED:
        return { ...state, loading: false };
    default:
        return state;
    }
};

export default signupReducer