/* eslint-disable camelcase */
import { getIncommHeaders, getPostRegData, locale, setData } from 'utils/helpers';
import { notification } from 'services/notification';
import {
    signupRequest,
    signupSuccess,
    signupFailed,
    verifyEmailRequest,
    verifyEmailSuccess,
    verifyEmailFailed,
    userDataRequest,
    userDataSuccess,
    userDataFailed,
    verifyOtpRequest,
    verifyOtpSuccess,
    verifyOtpFailed,
    resendOtpRequest,
    resendOtpSuccess,
    resendOtpFailed,
    postUserDetailsRequest,
    postUserDetailsSuccess,
    postUserDetailsFailed,
    getLocationAddressRequest,
    getLocationAddressSuccess,
    getLocationAddressFailed,
    getUserDetailsRequest,
    getUserDetailsSuccess,
    getUserDetailsFailed,
    postRegRequest,
    postRegSuccess,
    postRegFailed,
    putPromoCodeRequest,
    putPromoCodeSuccess,
    putPromoCodeFailed,
    verifyEmailExistsSuccess,
    putUserLangRequest,
    putUserLangSuccess,
    putUserLangFailed,
} from 'actions/signup';
import axiosInstance from 'services/api';
import history from 'utils/history';
import { SET_STEP } from 'constants/App';
import moment from 'moment';
import { putArticlesFailed, putArticlesRequest, putArticlesSuccess } from 'actions/documents';
import { ROUTES } from 'constants/AppRoutes';

export const signup = (data, t) => {
    return async (dispatch) => {
        dispatch(userDataRequest());
        dispatch(signupRequest());
        try {
            const result = await axiosInstance.post('/oauth/token', {
                username: data?.email,
                password: data?.password,
                grant_type: 'password',
                client_id: 'knetik',
            });
            setData('token', result.data.access_token);
            dispatch(signupSuccess(result.data.access_token));
            result.data.access_token && dispatch(putUsersLangDetails(locale()))
        } catch (e) {
            dispatch(signupFailed());
            notification('error', t('SOMETHING_WENT_WRONG'));
        }
    };
};

export const emailExistLogin = (data, t) => {
    return async (dispatch) => {
        dispatch(signupRequest());
        dispatch(verifyEmailRequest());
        try {
            const result = await axiosInstance.post('/oauth/token', {
                username: data?.email,
                password: data?.password,
                grant_type: 'password',
                client_id: 'knetik',
            });
            setData('token', result.data.access_token);
            dispatch(signupSuccess(result.data.access_token));
            result.data.access_token && dispatch(putExistingUsersLangDetails(locale()))
        } catch (e) {
            dispatch(signupFailed());
            notification('error', t('SOMETHING_WENT_WRONG'));
        }
    };
};

export const verifyEmail = (email, values, t) => {
    return async (dispatch) => {
        dispatch(verifyEmailRequest());
        try {
            const result = await axiosInstance.post('users/search/email', {
                email: email,
            });
            dispatch(verifyEmailSuccess(result.data));
            if (result?.data?.exists) {
                dispatch(emailExistLogin(values, t))
            }
        } catch (e) {
            dispatch(verifyEmailFailed());
            notification('error', t('SOMETHING_WENT_WRONG'));
        }
    };
};

export const postUserData = (signupData, accountData, t) => {
    const data = {
        additional_properties: {
            preferred_language: { type: 'text', value: locale() },
        },
        country: 'US',
        email: signupData.email || '',
        first_name: accountData.firstName || '',
        last_name: accountData.lastName || '',
        middle_name: accountData.middleName || '',
        mobile_number: accountData.phoneNumber || '',
        password: signupData.password,
        username: signupData.email || '',
    };
    return async (dispatch) => {
        dispatch(userDataRequest());
        try {
            const result = await axiosInstance.post('users', data);
            dispatch(userDataSuccess(result.data));
            dispatch(signup(signupData));
            if(result?.status === 200){
                dispatch({
                    type: SET_STEP,
                    step: 2
                 })
            }
        } catch (error) {
            dispatch(userDataFailed(error));
            if (error?.response?.status === 400) {
                notification('error', `${ error?.response?.data?.result[0]?.details?.result[0]?.message || t('USER_ALREADY_EXISTS') }`);
            }
        }
    };
};

export const verifyOtp = (code, t) => {
    return (dispatch) => {
        dispatch(verifyOtpRequest());
        try {
            axiosInstance
                .post(`verification/requests/${ code }/responses`)
                .then((result) => {
                    dispatch(verifyOtpSuccess(result.data));
                    dispatch({ type: 'TOGGLE_MODAL', set: true });
                    history.push(ROUTES.CHA_DOCUMENT);
                    notification('success', t('PHONE_NUMBER_VERIFIED'));
                })
                .catch((error) => {
                    if (error?.response?.status === 404) {
                        notification('error', t('INCORRECT_OTP'));
                    }
                    dispatch(verifyOtpFailed());
                });
        } catch (e) {
            dispatch(verifyOtpFailed());
        }
    };
};

export const resendOtp = (mobileNumber, t) => {
    const data = {
        mobile_number: mobileNumber || '',
        template: 'sms_verification',
    };
    return async (dispatch) => {
        dispatch(resendOtpRequest());
        try {
            const result = await axiosInstance.post('verification/requests', data);
            dispatch(resendOtpSuccess(result.data));
            notification('success', t('CODE_RESENT_SUCCESSFULLY'));
        } catch (e) {
            dispatch(resendOtpFailed());
        }
    };
};

export const postUserDetails = (signup, accountFormValues, postData, stateCode, userDetails,t) => {
  const data = {
    "firstName": accountFormValues?.firstName || userDetails?.first_name || '',
    "lastName":accountFormValues?.lastName || userDetails?.last_name || '',
    "middleName": accountFormValues?.middleName || userDetails?.middle_name || '',
    "address": postData?.address || '',
    "address2": postData?.streetDetail || '',
    "zip": postData?.zip || '',
    "city": postData?.city || '',
    "state": stateCode || '',
    "country": "USA",
    "dobDay": postData?.dobDay || '',
    "dobMonth": postData?.dobMonth || '',
    "dobYear": postData?.dobYear || '',
    "mothersMaidenName": postData?.maidenName || '',
    "ssn": postData?.ssn || '',
    "promoCode": postData?.promoCode || ''
  }

  return async(dispatch) => {
    dispatch(postUserDetailsRequest())
    try{
        axiosInstance.post('idology/verify', data).then((result)=>{
            let key = '';
            let idScan = result?.data?.response['id-scan'];
            let idScanURL = result?.data?.response['id-scan-url'];
            let summaryResult = result?.data?.response['summary-result'];
            if (summaryResult) {
                key = summaryResult.key;
              }
            if (idScan === 'no' && key === 'id.success') {
                dispatch(postUserDetailsSuccess({...result.data , scan: 'no', idScanURL: ''}))
                dispatch(getUserDetails(signup, accountFormValues, postData,t))
            }else if (idScan === 'yes' && idScanURL) {
                dispatch(postUserDetailsSuccess({...result.data , scan: 'yes', idScanURL: idScanURL}))
                dispatch({ type: 'TOGGLE_MODAL', set: true })
            }
          result?.data?.response?.error && notification('error',`${ result?.data?.response?.error }`)
        })
    }catch(error){
        dispatch(postUserDetailsFailed(error))
    }
  }
}

export const getLocationAddress = (zip,latitude,longitude) => {
  return (dispatch) => {
      dispatch(getLocationAddressRequest())
      let path = '';
      if (zip?.length > 0) {
        path = `location/search/address?zipCode=${zip}`;
      } else {
        path = `location/search/address?latitude=${latitude || ''}&longitude=${longitude || ''}`;
      }
      axiosInstance.get(path)
      .then((response) => {
          dispatch(getLocationAddressSuccess(response.data))
          response?.error?.error && notification('error',`${ response?.error?.error }`)
      }).catch((error) => {
          dispatch(getLocationAddressFailed(error))
      })
    }
}

export const getUserDetails = (signup, account, accountDetails,t) => {

  return (dispatch) => {
      dispatch(getUserDetailsRequest())
      dispatch(userDataRequest());
      axiosInstance.get('users/me').then((response) => {
          dispatch(getUserDetailsSuccess(response.data))
          setData('locale', response?.data?.additional_properties?.preferred_language?.value || 'en')
          if(response?.data?.additional_properties?.idology_result?.value === 'Not Approved' ||
          response?.data?.additional_properties?.idology_result?.value === null
          ){
            notification('error', t('DOCUMENT_NOT_VERIFIED'))
          } else if(response?.data?.additional_properties?.idology_result?.value === 'Approved') {
                dispatch(postRegistration(
                    signup,
                    account,
                    accountDetails,
                    response?.data,
                    response?.data?.additional_properties?.idology_id?.value))
              }
          response?.error?.error && notification('error',`${ response?.error?.error }`)
      }).catch((error) => {
          dispatch(getUserDetailsFailed(error))
      })
  }
}

export const getExistingUserDetails = (emailExists) => {
    return (dispatch) => {
        dispatch(getUserDetailsRequest())
        dispatch(verifyEmailRequest());
        axiosInstance.get('users/me').then((response) => {
            dispatch(getUserDetailsSuccess(response.data))
            setData('locale', response?.data?.additional_properties?.preferred_language?.value || 'en')
              if(emailExists){
                  dispatch(verifyEmailExistsSuccess(true))
              }
            response?.error?.error && notification('error',`${ response?.error?.error }`)
        }).catch((error) => {
            dispatch(getUserDetailsFailed(error))
        })
    }
  }

export const postRegistration = (signup, account, accountDetails, userDetails, verificationId) => {
    const data = getPostRegData(signup, account, accountDetails, userDetails, verificationId);
    return (dispatch) => {
      dispatch(postRegRequest())
      axiosInstance.post('incomm/registration', data, {
          headers: getIncommHeaders(signup?.email)
      }).then((response) => {
          dispatch(postRegSuccess(response.data))
          response?.data?.responseMessage === 'success' &&
          dispatch({
            type: SET_STEP,
            step: 4
          })
          response?.error?.error && notification('error',`${ response?.error?.error }`)
      }).catch((error) => {
          error?.response?.data?.result[0] && notification('error', error?.response?.data?.result[0])
          dispatch(postRegFailed(error))
      })
  }
}

export const putUsersAddressDetails = (accountDetails, stateValue) => {
    const data =   {
        "address":  accountDetails?.address || '',
        "address2": accountDetails?.streetDetail || '',
        "city": accountDetails?.city || '',
        "date_of_birth": moment(accountDetails?.birthDate).format('YYYY-MM-DD') || '',
        "state": stateValue || '' ,
        "postal_code": accountDetails?.zip || '',
      }
    return async (dispatch) => {
        dispatch(putArticlesRequest());
        try {
            const result = await axiosInstance.put('users/me', data);
            dispatch(putArticlesSuccess(result.data));
        } catch (e) {
            dispatch(putArticlesFailed());
        }
    };
};

export const putUsersPromoDetails = (signup, accountFormValues,submitValues, state, userDetails, promoCode,t) => {
    const data = { "additional_properties": { "redeem_code": { "type": 'text', "value": `${promoCode}` } } }
    return async (dispatch) => {
        dispatch(putPromoCodeRequest())
        try {
            const result = await axiosInstance.put('users/me', data)
            dispatch(putPromoCodeSuccess(result.data));
            if(result.status === 204) {
                dispatch(postUserDetails(signup, accountFormValues,submitValues,state, userDetails,t))
            }
        } catch (error) {
            error?.response && notification('error', `${ error?.response?.data?.result[0]?.details?.result[0]?.message }`);
            dispatch(putPromoCodeFailed());
        }
    };
};

export const putExistingUsersLangDetails = (language) => {
    const data = { "additional_properties": { "preferred_language": { "type": "text", "value": language } } }
    return async (dispatch) => {
        dispatch(putUserLangRequest())
        dispatch(userDataRequest());
        try {
            const result = await axiosInstance.put('users/me', data)
            dispatch(putUserLangSuccess(result.data));
            if(result.status === 204) {
                dispatch(getExistingUserDetails(true))
            }
        } catch (error) {
            error?.response && notification('error', `${ error?.response?.data?.result[0]?.details?.result[0]?.message }`);
            dispatch(putUserLangFailed());
        }
    };
};

export const putUsersLangDetails = (language) => {
    const data = { "additional_properties": { "preferred_language": { "type": "text", "value": language } } }
    return async (dispatch) => {
        dispatch(putUserLangRequest())
        dispatch(userDataRequest());
        try {
            const result = await axiosInstance.put('users/me', data)
            dispatch(putUserLangSuccess(result.data));
            if(result.status === 204) {
               dispatch(getUserDetails())
            }
        } catch (error) {
            error?.response && notification('error', `${ error?.response?.data?.result[0]?.details?.result[0]?.message }`);
            dispatch(putUserLangFailed());
        }
    };
};


