import React from 'react';
import OtpInput from 'react-otp-input';

export const OTPField = ({
  input,
  className,
  name,
  Value,
  updateValue,
  meta: { touched, error },
}) => {
  const err = touched && error ? error : '';
  return (
    <div>
      {updateValue ? (
        <OtpInput
          className={err ? 'errorClass otpdesign' : className}
          name={name}
          numInputs={6}
          isInputNum
          separator={<span> </span>}
          value={Value}
          onChange={updateValue(input.value)}
          {...input}
        />
      ) : (
        <OtpInput
          {...input}
          className={err ? 'errorClass otpdesign' : className}
          name={name}
          numInputs={6}
          isInputNum
          separator={<span> </span>}
        />
      )}
      <span className="required__msg">{err}</span>
    </div>
  );
};