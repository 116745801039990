import React from 'react';
import { useTranslation } from 'react-i18next';

import authBackground from "../../assests/images/auth-bg.png";
import Modal from 'components/shared/Modal';

const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <div
      className="auth-container h-100"
      style={{ backgroundImage: `url(${authBackground})` }}
    >
    <Modal
      show={ true }
    >
      {t('OOPS_ERROR')}
    </Modal>
    </div>
  )
}

export default ErrorPage;