import React from 'react';
import { CardWrapper } from './Card.styled';
import SliderProgressBar from './SliderProgressBar';

const SliderCard = (props) => {
  const {children} = props;

  return (
      <CardWrapper className="slider-card-wrapper">
        <SliderProgressBar/>
        {children}
      </CardWrapper>
    );
};

export default SliderCard;