import React from 'react';
import { withRouter } from 'react-router-dom';
import { geolocated } from 'react-geolocated';

import AccountDetailsForm from 'components/Auth/accountDetails';
import { setData } from 'utils/helpers';

const AccountDetailsPage = (props) => {
  setData('latitude', props?.coords?.latitude ||'37.785834')
  setData('longitude', props?.coords?.longitude || '-122.406417')

  return (
    <>
      <AccountDetailsForm
        prevPage={props.prevPage}
        nextPage={props.nextPage}
      />
    </>
  )
}

export default withRouter(geolocated({
  positionOptions: {
      enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(AccountDetailsPage))