export const ActionTypes = {
    SIGNUP_REQUEST: 'SIGNUP_REQUEST',
    SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
    SIGNUP_FAILED: 'SIGNUP_FAILED',
    VERIFY_EMAIL_REQUEST: 'VERIFY_EMAIL_REQUEST',
    VERIFY_EMAIL_SUCCESS: 'VERIFY_EMAIL_SUCCESS',
    VERIFY_EMAIL_EXISTS_SUCCESS: 'VERIFY_EMAIL_EXISTS_SUCCESS',
    VERIFY_EMAIL_FAILED: 'VERIFY_EMAIL_FAILED',
    POST_USER_DATA_REQUEST: 'POST_USER_DATA_REQUEST',
    POST_USER_DATA_SUCCESS: 'POST_USER_DATA_SUCCESS',
    POST_USER_DATA_FAILED: 'POST_USER_DATA_FAILED',
    VERIFY_OTP_REQUEST: 'VERIFY_OTP_REQUEST',
    VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
    VERIFY_OTP_FAILED: 'VERIFY_OTP_FAILED',
    RESEND_OTP_REQUEST: 'RESEND_OTP_REQUEST',
    RESEND_OTP_SUCCESS: 'RESEND_OTP_SUCCESS',
    RESEND_OTP_FAILED: 'RESEND_OTP_FAILED',
    POST_USER_DETAILS_REQUEST: 'POST_USER_DETAILS_REQUEST',
    POST_USER_DETAILS_SUCCESS: 'POST_USER_DETAILS_SUCCESS',
    POST_USER_DETAILS_FAILED: 'POST_USER_DETAILS_FAILED',
    GET_LOCATION_ADDRESS_REQUEST: 'GET_LOCATION_ADDRESS_REQUEST',
    GET_LOCATION_ADDRESS_SUCCESS: 'GET_LOCATION_ADDRESS_SUCCESS',
    GET_LOCATION_ADDRESS_FAILED: 'GET_LOCATION_ADDRESS_FAILED',
    GET_USER_DETAILS_REQUEST: 'GET_USER_DETAILS_REQUEST',
    GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
    GET_USER_DETAILS_FAILED: 'GET_USER_DETAILS_FAILED',
    POST_REGISTRATION_REQUEST: 'POST_REGISTRATION_REQUEST',
    POST_REGISTRATION_SUCCESS: 'POST_REGISTRATION_SUCCESS',
    POST_REGISTRATION_FAILED: 'POST_REGISTRATION_FAILED',
    PUT_PROMOCODE_REQUEST: 'PUT_PROMOCODE_REQUEST',
    PUT_PROMOCODE_SUCCESS: 'PUT_PROMOCODE_SUCCESS',
    PUT_PROMOCODE_FAILED: 'PUT_PROMOCODE_FAILED',
    PUT_USER_LANGUAGE_REQUEST: 'PUT_PROMOCODE_REQUEST',
    PUT_USER_LANGUAGE_SUCCESS: 'PUT_PROMOCODE_SUCCESS',
    PUT_USER_LANGUAGE_FAILED: 'PUT_PROMOCODE_FAILED'
}