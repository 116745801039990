export const TRANSLATIONS_ES = {
    "APP_NAME": "Cuentas",
    "ONBOARDING1_TITLE": "Banca móvil construida para ti",
    "ONBOARDING1_TEXT": "Aplica en menos de 5 minutos. (En realidad)",
    "ONBOARDING2_TITLE": "Lo que necesitas\npara aplicar",
    "ONBOARDING2_TEXT": "Ser mayor de 18 años.\nTener una dirección en EE.UU. (USA) \nTener número de seguro social o ITIN",
    "ONBOARDING3_TITLE": "Lo que obtendrás",
    "ONBOARDING3_TEXT": "Una tarjeta de débito Mastercard®.\nSus fondos asegurados por la FDIC.\nDescuentos especiales en sus tiendas favoritas.\nLlamadas de larga distancia gratuitas.*",
    "ONBOARDING4_TITLE": "¿Cuantó cuesta?",
    "ONBOARDING4_TEXT": "$4.50 por matenimiento mensual.",
    "ONBOARDING5_TITLE": "El registro toma sólo unos minutos.",
    "ONBOARDING5_TITLE_TXT": "Asegúrate de tener todo lo que necesitas antes de empezar. Aquí te damos algunos consejos útiles.",
    "ONBOARDING5_SUB_TEXT": "Debes ser mayor de 18 años para aplicar.",
    "ONBOARDING5_SUB_FRSTTEXT": "Tu dirección en EE.UU. debe coincidir con la dirección de la identificación que usas para registrarte.",
    "ONBOARDING5_SUB_SCNDTEXT": "Ten disponible tu número de SSN o número ITIN.",
    "ONBOARDING5_SUB_THRD_TEXT": "Ten a mano tu licencia de conducir emitida por el estado u otra forma de identificación emitida por el gobierno.",
    "ONBOARDING5_SUB_FRTH_TEXT": "Si alguna forma de identificación adicional es requerida, puedes usar tu Pasaporte, Matrícula Consular o Tarjeta de Residencia (Green Card).",
    "SLIDER_TEXT": "*Hasta $15.00 por mes",
    "PASSWORD_TEXT": "Carácter Especial. Letra Mayúscula. Mínimo 9 Caracteres.",
    "ALREADY_HAVE_ACCOUNT": "¿Ya tienes una cuenta?",
    "SPECIAL_CHARACTER": "Carácter Especial",
    "CAPITAL_CHARACTER": "Letra Mayúscula",
    "PASSWORD_LENGTH": "Mínimo 9 Caracteres",
    "CREATE_NEW_ACCOUNT": "Crea una Cuenta Nueva",
    "PROGRESS_TEXT": "Progreso",
    "FIRST_NAME_TEXT": "Primer Nombre*",
    "MIDDLE_NAME_TEXT": "Segundo Nombre",
    "LAST_NAME_TEXT": "Apellido*",
    "EMAIL": "Correo Electrónico*",
    "PASSWORD": "Contraseña*",
    "CONFIRM_PASSWORD": "Confirmar Contraseña*",
    "OLD_PASSWORD": "Contraseña Anterior*",
    "NEW_PASSWORD": "Nueva Contraseña*",
    "CONFIRM_NEW_PASSWORD": "Confirmar Nueva Contraseña*",
    "ENTER_EMAIL": "Ingrese Correo Electrónico*",
    "FORGOT_PASSWORD": "¿Olvidaste tu Contraseña?",
    "RESTORE": "Restaurar",
    "SIGN_IN": "Registrarse",
    "SIGN_UP": "Regístrate",
    "NEED_ACCOUNT": "¿Necesito una cuenta?",
    "ADDRESS": "Dirección*",
    "APT": "Apartamento, Suite, Unidad, etc.",
    "ZIP": "Código Postal*",
    "CITY": "Ciudad*",
    "STATE": "Estado*",
    "ADDRESS_INFO": "Información del Domicilio",
    "ENTER_BIRTHDAY": "Ingresa tu Fecha de Nacimiento",
    "PASSWORD_SECURITY": "Contraseña y Seguridad",
    "CREATE_YOUR_PIN": "Crea tu Codigo de Activación de 4 d dígitos",
    "ACTIVATION_AND_PROMO_CODE": "Código de Activación y Promoción",
    "MAIDEN_NAME": "Apellido de Soltera de la Madre*",
    "SSN": "SSN (Numero de Seguridad Social)*",
    "ITIN": "ITIN",
    "OR": "O",
    "CREATE_PIN": "Crear PIN",
    "RE_ENTER_PIN": "Vuelva a Ingresar el PIN",
    "PLACEHOLDER_NUMBER": "Número Telefónico",
    "CONFIRM_MOBILE_NUMBER": "Confirmar Número Celular",
    "ENTER_SMS_CODE": "Ingrese el código recibido por Mensaje de Texto",
    "ENTER_CODE_TEXT": "Te enviamos un código por Mensaje de Texto a",
    "ENTER_PHONE_NUMBER": "Ingrese su Número Telefónico",
    "ENTER_PHONE_SUBTEXT": "Le enviaremos un código por Mensaje de Texto para restaurar su Contraseña",
    "ENTER_CODE_MESSAGE": "Si no recibió un código, presione el botón \"Reenviar Código \".",
    "VERIFY": "Verificar",
    "RESEND_CODE": "Reenviar Código",
    "SEND": "Enviar",
    "SEND_INVITE": "Enviar Invitación",
    "DONE": "Hecho",
    "PENDING": "Pendiente",
    "CANCELLED": "Cancelada",
    "CHANGE": "Cambio",
    "CHECK_MAIL_VALIDATION": "Por favor revise su Correo Electrónico",
    "CHECK_MAIL_LENGTH": "Por favor introduzca su Correo Electrónico",
    "CHECK_PASSWORD": "Por favor revise su Contraseña",
    "ENTER_PASSWORD": "Por favor, introduzca su Contraseña",
    "CHECK_PASSWORD_CONFIRM_PASSWORD": "Verificar Contraseña y Confirmar/ Contraseña deben ser iguales.",
    "ENTER_CONFIRM_PASSWORD": "Por favor ingrese Confirmar Contraseña",
    "FIRST_NAME": "Por favor ingrese su Primer Nombre",
    "LAST_NAME": "Por favor ingrese su Apellido",
    "CONTACT_NUMBER": "Ingrese el Número de Teléfono",
    "INVALID_CONTACT_NUMBER": "Ingrese un Número de Teléfono válido de 10 dígitos",
    "INVALID_CONTACT_NUMBER_WITH_CODE": "Por favor ingrese un Número de Teléfono válido",
    "SUCCESS": "Gracias!",
    "PHONE_NUMBER_VERIFIED": "Hemos verificado su Número de Teléfono",
    "FACE_ID": "Identificación de Rostro",
    "ENABLE_FACE_ID": "¿Deseas habilitar Identificación de Rostro?",
    "TOUCH_ID": "Identificación de Huella",
    "ENABLE_TOUCH_ID": "¿Deseas habilitar Identificación de Huella?",
    "WELCOME_TO_CUENTAS": "Bienvenido a Cuentas",
    "GO_TO_MY_ACCOUNT": "Ir a Mi Cuenta",
    "YES_TEXT": "Si",
    "NO_TEXT": "No",
    "OK_TEXT": "Ok",
    "FEES_TEXT": "Tarifas",
    "NEED_HELP": "¿Necesitas Ayuda?",
    "PASSWORD_CHANGED": "Contraseña modificada.",
    "CLOSE_TEXT": "Cerrar",
    "CONTACT_SUPPORT": "Comuníquese con Soporte al 1-833-283-6827",
    "CARHOLDER_AGREEMENT": "Acuerdo del Titular de la Tarjeta",
    "TOUCH_ID_AGREEMENT": "Acuerdo para Identificación de Huella / Identificación de Rostro",
    "USER_ALREADY_EXISTS": "El Nombre de Usuario que seleccionó ya está registrado.",
    "INCORRECT_CREDENTIALS": "Compruebe sus datos de Inicio de Sesión.",
    "INCORRECT_OTP": "El Código ingresado es incorrecto.",
    "INCORRECT_CODE": "Verifique Código temporal o Número de Teléfono.",
    "RESTORE_PASSWORD": "Restaurar Contraseña",
    "CHANGE_PASSWORD": "Cambia tu Contraseña",
    "CHANGE_PASSWORD_TEXT": "Ingrese la nueva Contraseña",
    "PHONE_NUMBER_NOT_REGISTERED": "Si el Número de Teléfono está registrado, recibirá un Codigo por Mensaje de Texto.",
    "PASSWORD_RESET_SUCCESS": "Contraseña restaurada correctamente. Inicie Sesión nuevamente por favor.",
    "INVALID_SECRET_KEY": "Clave secreta no válida, ingrese la clave correcta e intente nuevamente",
    "ENTER_ACTIVATION_CODE": "Ingrese Código de Activación*",
    "ACTIVATION_CODE_REQUIRED": "Se Requiere el Código de Activación",
    "INVALID_ACTIVATION_CODE": "Código de Activación inválido",
    "INVALID_ACTIVATION_CODE_MESSAGE": "Ingrese un Código de Activación de 4 dígitos",
    "CODE_RESENT_SUCCESSFULLY": "Código reenviado con éxito",
    "ENTER_PROMO_CODE": "Introduce el Código de Promoción",
    "ENTER_ADDRESS": "La Dirección es Requerida",
    "ENTER_ZIP_CODE": "Se Requiere Código Postal",
    "ENTER_CITY": "Se Requiere la Ciudad",
    "ENTER_STATE": "El Estado es Requerido",
    "ENTER_MOTHER_MAIDEN_NAME": "Se Requiere el Nombre de Soltera de la Madre",
    "ENTER_SSN": "Número de Seguro Social/ITIN es Requerido",
    "INVALID_SSN": "NSS Inválido/ITIN Invalido",
    "EMPTY_BIRTHDAY": "Se Requiere la Fecha de Nacimiento",
    "ILD_REWARDS_TITLE": "Términos de Recompensa en Llamadas de Larga Distancia",
    "LICENSE_AGREEMENT": "Acuerdo de Licencia",
    "AGREE_TEXT": "De Acuerdo",
    "DECLINE_TEXT": "Declinar",
    "HOME": "Inicio",
    "WALLET": "Cartera",
    "ADD_CASH": "Agregar Dinero",
    "RELOAD_CARD": "Recargar Tarjeta",
    "RELOAD": "Recargar",
    "NAVIGATE_TO": "Navegar a",
    "AMOUNT": "Cantidad",
    "RELOAD_CARD_TEXT": "Recargue su Tarjeta\nindirectamente en el registro",
    "LOCATIONS": "Ubicaciones",
    "LINKED_CUENTAS_MEMBERS": "Miembros de Cuentas Vinculados",
    "PHONE_INVITES_SENT": "Invitaciones telefónicas enviadas",
    "EMAIL_INVITES_SENT": "Invitaciones por Correo Electrónico enviadas",
    "PHONE_CONTACTS": "Contactos de su teléfono",
    "SEARCH_BY_ZIP": "Buscar por Código Postal",
    "SEARCH": "Buscar",
    "ACCEPT_TNC": "Acepto los Términos y Condiciones",
    "ILD_DESCRIPTION": "Registro completo para\nSaldo de recompensas de larga distancia internacional.",
    "ILD_ACCEPTED_DESCRIPTION": "Saldo de Recompensa de Larga Distancia \nInternacional",
    "DOCUMENT_NOT_VERIFIED": "Documento no verificado",
    "DETAILS": "Detalles",
    "COMPLETE_PURCHASE": "Compra Completa",
    "AVAILABLE_FUNDS": "Fondos Disponibles",
    "ENTER_YOUR_EMAIL": "Introduce tu Correo Electrónico",
    "ENTER_AN_AMOUNT": "Ingrese una Cantidad",
    "HELP_TEXT": "Ayuda",
    "CARD_HOLDER_AGREEMENT": "Acuerdo del Titular de la Tarjeta",
    "ESIGN_DISCLOSURE": "Difusión de Firma Electrónica",
    "SUTTON_PRIVACY_POLICY": "Política de Privacidad de Sutton Bank",
    "FEES_AND_LIMITS": "Tarifas y Límites",
    "CUENTAS_PRIVACY_POLICY": "Política de Privacidad de Cuentas",
    "CUENTAS_TERMS_OF_USE": "Condiciones de Uso de Cuentas",
    "CUENTAS_REWARDS": "Plan de Lealtad",
    "ABOUT": "Acerca de Cuentas",
    "QNA_TEXT": "Preguntas y Respuestas",
    "CONTACT_TEXT": "Información de Contacto",
    "SECURITY_AND_FEES": "Seguridad y Tarifas",
    "GENERAL_INFO": "Información General",
    "SPENDING_MONEY": "Usa tu Dinero",
    "ADDING_MONEY_TO_CART": "Agregando Dinero a su Tarjeta",
    "GENERAL_INFO_AND_GETTING_STARTED": "Información General y Comenzando",
    "CARDHOLDER_AGREEMENT": "Acuerdo del Titular de la Tarjeta",
    "SUTTON_BANK": "Banco Sutton",
    "FEES_N_LIMITS": "Tarifas & Límites",
    "ALL_FEES": "Todas las Tarifas",
    "PRIVACY_POLICY": "Política de Privacidad",
    "REWARDS_TEXT": "Plan de Lealtad",
    "ABOUT_CUENTAS": "Acerca de Cuentas",
    "CONTACT_INFO_TEXT": "Información de Contacto",
    "TERMS_OF_USE": "Condiciones de Uso",
    "ADVANCE_SEARCH": "Búsqueda\nAvanzada",
    "ADVANCE_SEARCH_DEC": "Configure Filtros, después, presione\nContinuar para ver resultados",
    "SEARCH_FILTER": "Seleccionar Filtro",
    "SELECT_AMOUNT": "Seleccionar Monto",
    "SELECT_STATE": "Seleccione Estado",
    "SELECT_OPERATOR": "Seleccionar Operadora",
    "TYPE": "Tipo",
    "PERIOD": "Período",
    "BY_RECIPENT": "Por Destinatario",
    "ENTER_RECIPENT_NAME": "Ingrese el Nombre del Destinatario",
    "PROCEED": "Continuar",
    "SELECT_ACTION": "Seleccione una Acción",
    "WHAT_DO_YOU_WANT_DO": " ",
    "ENTER_AMOUNT": "Ingrese la Cantidad que le gustaría Cargar",
    "GENERATE_BAR_CODE": "Generar Código de Barras",
    "PLEASE_ENTER_AMOUNT": "Ingresa una Cantidad",
    "SCAN_CODE": "Escanear Código de Barras",
    "PLEASE_SCAN_BAR_CODE": "Muestre este Código de Barras en la Caja Registradora",
    "RELOAD_CARD_TEXT1": "Lleva tu Tarjeta de Cuentas y dinero en efectivo a una Tienda Participante",
    "RELOAD_CARD_TEXT2": "Presente su Tarjeta al Cajero para agregar Dinero a su Tarjeta Prepagada. Ó, genere un Código de Barras a continuación para que el cajero lo escanee",
    "RELOAD_CARD_TEXT3": "El Cajero iniciará la venta y cargará sus fondos directamente en su Tarjeta",
    "RELOAD_FEES_APPLY": "*Se aplican Tarifas de recarga. Sujeto a los límites de carga de la Tarjeta Prepagada. Consulte Contrato del Titular de la Tarjeta para obtener detalles completos.",
    "CANCEL": "Cancelar",
    "SUBMIT": "Enviar",
    "DISMISS": "Descartar",
    "SETTINGS_TITLE": "Ajustes",
    "WESTERN_UNION": "Western Union",
    "ACCOUNT_TITLE": "Cuenta",
    "BANK_INFO": "Información Bancaria",
    "ALERT_TITLE": "Notificaciones",
    "PERSONALE_INFORMATION": "Información Personal",
    "SEND_EMAIL_VALIDATION_CODE": "Enviar Código de Validación por Correo Electrónico",
    "SECURITY_TEXT": "Seguridad",
    "ENABLE_TOUCH_ID_TEXT": "Habilitar Identificación de Huella",
    "ENABLE_FACE_ID_TEXT": "Habilitar Identificación de Rostro",
    "ON_TEXT": "ENCENDIDO",
    "OFF_TEXT": "APAGADO",
    "NAME_TEXT": "Nombre",
    "PHONE_TEXT": "Teléfono",
    "EMAIL_TEXT": "Correo Electrónico",
    "PHONE_NUMBER": "Número de Teléfono",
    "E_MAIL_TEXT": "Correo Electrónico",
    "CHANGE_PASSWORD_TXT": "Cambiar la Contraseña",
    "ENTER_EMAIL_VALIDATION": "Ingrese el Código de Validación de Correo Electrónico",
    "VALIDATE_EMAIL_TEXT": "Validar Correo Electrónico",
    "CANCEL_TEXT": "Cancelar",
    "ENTER_OLD_PASSWORD": "Ingresa la Contraseña anterior y luego Crea una Nueva Contraseña",
    "OLD_PASSWORD_TEXT": "Contraseña Anterior",
    "ENTER_NEW_PASSWORD": "Introduce Nueva Contraseña",
    "ENTER_YOUR_NEW_PASSWORD": "Ingresa tu Contraseña Anterior",
    "NEW_PASSWORD_TEXT": "Nueva Contraseña",
    "CONFIRM_NEW_PASSWORD_TEXT": "Confirmar Nueva Contraseña",
    "VALIDATIONS_STATEMENT": "1 Carácter Especial, 1 Letra Mayúscula y un Mínimo 9 Caracteres",
    "SUBMIT_TEXT": "Enviar",
    "PLEASE_ENTER": "Por favor escribe ",
    "PLEASE_ENTER_VALID": "Por favor ingrese datos válidos",
    "PASSWORD_UPDATED_SUCCESSFULLY": "Contraseña actualizada exitosamente.",
    "ENTER_VALID_AMOUNT": "Ingrese una Cantidad Válida",
    "MAX_AMOUNT_EXCEED": "El Monto Máximo es $500.00",
    "ENTER_VALID_ZIP_CODE": "Por Favor ingrese un Código Postal válido",
    "SERVER_ERROR": "Error del servidor. Vuelve a intentarlo más tarde.",
    "PHONE_OR_ACCOUNT": "Teléfono",
    "PAY": "Pagar",
    "REQUEST": "Petición",
    "INVITE": "Invitación",
    "MAP": "Mapa",
    "LIST": "Lista",
    "PAYMENT_HISTORY": "Historial De Pagos",
    "MONEY_IN": "Dinero Entrante",
    "MONEY_OUT": "Dinero Saliente",
    "BOTH": "Ambos",
    "ADDITIONAL_DETAILS": "Detalles Adicionales",
    "INCORRECT_CURRENT_PASSWORD": "Contraseña Actual Incorrecta",
    "INCORRECT_CODE_TEXT": "Código Incorrecto",
    "CODE_VALIDATED_SUCCESSFULLY": "Código Validado Exitosamente",
    "ALERTS_TRAILING_TEXT": "Mensaje de Texto y Correo Electrónico",
    "SMS_TEXT": "Mensaje de Texto",
    "PUSH": "Notificaciones en la aplicación",
    "AMOUNT_PAID": "Cantidad Pagada",
    "AMOUNT_RECEIVED": "Monto Recibido",
    "TRANSFER_TO": "Transferir a",
    "RECEIVED_FROM": "Recibido de",
    "NOTIFICATION_TITLE": "Notificaciones",
    "ALL_TAB": "Todos",
    "INVITE_TAB": "Invitaciones",
    "TRANSACTION_TAB": "Transacciones",
    "VIEW_TRANSACTION": "Ver Transacción",
    "TRANSACTION_SUB_TEXT": "Presione el botón Ver Transacción para ver los detalles",
    "REJECT_OPTION": "Rechazar",
    "ACCEPT_OPTION": "Aceptar",
    "DISMISS_OPTION": "Descartar",
    "SEND_OPTION": "Enviar",
    "NO_DATA_TEXT": "Datos no disponibles",
    "NO_NOTIFICATION_TEXT": "No hay Notificaciones disponibles",
    "NO_INVITE_TEXT": "No hay Invitaciones disponibles",
    "NO_TRANSACTIONN_TEXT": "No hay Transacciones disponibles",
    "HI_TEXT": "Hola",
    "INVIATION_SUB_TEXT": "Quiero agregarte a mi agenda de direcciones de Cuentas. Por favor acepta mi invitación.",
    "TODAY": "Hoy",
    "CURRENT_WEEK": "Semana Actual",
    "CURRENT_MONTH": "Mes Actual",
    "CUSTOM": "Personalizar",
    "SET_CUSTOM_RANGE": "Establecer Rango Personalizado",
    "START_DATE": "Fecha de Inicio",
    "END_DATE": "Fecha Final",
    "DATE_VALIDATION_ERROR": "La Fecha de Inicio debe ser menor que la Fecha de Finalización.",
    "SESSION_TIMEOUT": "Se agotó el Tiempo de Espera en la Sesión, Inicie Sesión nuevamente.",
    "SOMETHING_WENT_WRONG": "Se produjo un error. Vuelve a intentarlo más tarde.",
    "RELOAD_NOT_AVAILABLE": "Recargar efectivo no está disponible para esta ubicación.",
    "CARD_ACTIVATION_TITLE": "Código de Activación de la Tarjeta",
    "CARD_ACTIVATION_BUTTON_TEXT": "Activar Tarjeta",
    "DIRECT_DEPOSIT_TITLE": "Información de Depósito Directo",
    "DEPOSIT_HINT_TEXT1": "ABA/Enrutamiento",
    "DEPOSIT_HINT_TEXT2": "Número de Cuenta",
    "LA_TRANSIT_TITLE": "Recarga de la Tarjeta LA Transit TAP",
    "CEB_GO_TRANSIT_TITLE": "Recargar СЕВ GO Transit",
    "GEAND_RAPID_TITLE": "Reposición de Grand Rapids",
    "ID_PLACEHOLDER": "Identificación",
    "SAVE_CHANGES_BUTTON_TEXT": "Guardar Cambios",
    "CODE_INVALID_ERROR_MESSAGE": "Ingresa un Código válido.",
    "SET_NOTIFICATION_TITLE_TEXT": "Establecer notificaciones",
    "SAVE_BUTTON_TEXT": "Guardar",
    "PAY_MONEY": "Pagar Dinero",
    "REQUEST_MONEY": "Solicitar Dinero",
    "PAYMENTS": "Pagos",
    "CODE_COPIED_TEXT": "Código Copiado",
    "ALERTS_UPDATE_SUCCESS_MESSAGE": "Alertas actualizadas con éxito",
    "ADDRESS_UPDATE_SUCCESS_MESSAGE": "Dirección actualizada correctamente",
    "DATA_UPDATE_SUCCESS_MESSAGE": "Datos actualizados con éxito",
    "ITEM_SUBTOTAL": "Subtotal de Artículo",
    "CUENTAS_DISCOUNT": "Cuentas Descuento",
    "DISCOUNT_SUBTOTAL": "Subtotal Descontado",
    "SALES_TAX": "Impuesto de Venta",
    "INVOICE_TOTAL": "Total de Factura",
    "ITEM_VALUE": "Valor del Artículo",
    "FROM": "Desde",
    "TO": "A",
    "ADD_PAYMENT_NOTE": "Agregar Nota de Pago",
    "PAYMENT_SUCCESSFUL": "Se ha transferido la cantidad.",
    "REQUEST_SENT_SUCCESSFUL": "Se ha enviado la solicitud de importe.",
    "PAYMENT_FAILED": "El pago falló. Vuelve a intentarlo más tarde.",
    "REQUEST_FAILED": "La solicitud falló. Vuelve a intentarlo más tarde.",
    "PURCHASE_ERROR": "Existió un error al procesar la orden. Por favor contacte al Centro de Ayuda.",
    "LOW_BALANCE_ERROR": "Tu saldo es menor que la cantidad solicitada.",
    "INVITE_FRIENDS": "Invitar a Amigos a Cuentas.",
    "CHOOSE_WAY_TO_INVITE": " ",
    "INVITE_WITH_SMS": "Invitar con\nMensaje de Texto",
    "INVITE_WITH_EMAIL": "Invitar con\nCorreo Electrónico",
    "ENTER_EMAIL1": "Ingrese Correo Electrónico",
    "ENTER_EMAIL_NEEDED": "Ingrese el Correo Electrónico solicitado",
    "ENTER_PHONE": "Introduzca el Número de Teléfono",
    "ENTER_PHONE_OR_CONTACTS": "Ingrese un Número de Teléfono o búsquelo en la libreta de direcciones",
    "INVITE_SENT_SUCCESSFULLY": "Tu Invitación fue enviada con éxito",
    "INVITE_FAILED": "Invitación fallida. Vuelve a intentarlo más tarde.",
    "MARKET_PLACE": "Tienda",
    "SHOW_CARD_OTPION_TEXT": "Mostrar información de la Tarjeta",
    "ACCOUNT_NO": "Número de Cuenta",
    "PLEASE_ENTER_EMAIL": "Por favor ingrese su Correo Electrónico",
    "PLEASE_ENTER_VALID_EMAIL": "Por favor introduzca un Correo Electrónico válido",
    "ENTER_TRANSIT_CODE": "Ingrese el Código de Tránsito",
    "SIXTEEN_DIGIT_TRANSIT_CODE": "Código de Tránsito de 16 dígitos",
    "EMPTY_TRANSIT_CODE_ERROR": "Por favor ingrese el Código de Tránsito",
    "INVALID_TRANSIT_CODE_ERROR": "Ingresa un Código de Transferencia Válido",
    "ENABLE_LOCATION": "Habilite los servicios de ubicación del dispositivo y reinicie la aplicación para usar esta función",
    "ENABLE_CONTACTS": "Habilite el Permiso de Contactos para obtener la Lista de Contactos del Teléfono",
    "NETWORK_LOST": "Se perdió la conexión de red.",
    "NETWORK_LOST_TRY_LATER": "Se perdió la conexión de red. Vuelve a intentarlo más tarde.",
    "NETWORK_AVAILABLE": "La conexión de red está disponible ahora.",
    "CARD_ACTIVATION_POPUP_TITLE": "¡Felicidades!",
    "CARD_ACTIVATION_POPUP_TEXT": "¡Felicidades! Tu cuenta está activa. A partir de mañana, puedes activar completamente tu cuenta para Transferencias y Compras de la Aplicación yendo a \"Configuración\" para ingresar el Código de Activación de 4 dígitos que creó durante el registro. Tu saldo de $4.50 cubre la cuota de activación.",
    "EMAIL_NEEDS_VERIFICATION": "El Correo Electrónico debe ser validado para hacer una Compra en la Tienda de Cuentas",
    "STARTS_FROM": "Comienza desde {0}",
    "CONFIRM_TEXT": "Confirmar",
    "CODE_SENT_SUCCESSFULLY": "Se ha enviado un nuevo Código.",
    "RECIPIENT_PHONE": "Teléfono del Destinatario",
    "LOOKUP": "Buscar",
    "OPERATOR_NOT_FOUND": "Operadora no encontrada",
    "CARD_NEEDS_ACTIVATION": "La Tarjeta debe ser Activada para realizar el Pago",
    "CARD_NEEDS_ACTIVATION_FOR_RELOAD": "La Tarjeta debe ser Activada para Recargar",
    "LOGOUT": "Cerrar Sesión",
    "STORE": "Tienda",
    "LOW_BAL_ALERT": "Monto de Saldo Bajo",
    "HIGH_AUTH_ALERT": "Alerta de Monto de Autorización Alta",
    "Invite Received": "Invitación Recibida",
    "Invite Accepted": "Invitación Aceptada",
    "Invite Rejected": "Invitación Rechazada",
    "Password Updated": "Contraseña Actualizada",
    "Transfer Complete": "Transferencia Completada",
    "Daily Balance Alert": "Alerta de Saldo Diario",
    "Low Balance Alert": "Alerta de Saldo Bajo",
    "Negative Balance Alert": "Alerta de Saldo Negativo",
    "High Authorization Amount Alert": "Alerta de Monto de Autorización Alto",
    "Insufficient Balance Alert": "Alerta de Saldo Insuficiente",
    "Incorrect Pin Alert": "Alerta de PIN Incorrecto",
    "Reload Alert": "Alerta de Recarga",
    "SELECT_CATEGORY": "Selecciona una Categoría",
    "SELECT_PROVIDER": "Selecciona Proveedor",
    "ENTER_ACCOUNT_NO": "Ingrese Número de Cuenta ",
    "PLEASE_ENTER_ACCOUNT_NO": "Ingrese Número de Cuenta ",
    "ENTER_PHONE_NO": "Ingrese Número de Teléfono ",
    "ENTER_INVOICE_NO": "Ingrese Número de Factura ",
    "ENTER_BAR_CODE_NO_OR_SCAN": "Ingrese N.º de Código de Barras",
    "ENTER_ACCOUNT_NO_OR_SCAN_BAR_CODE": "Ingrese N.º de Cuenta o Código de Barras",
    "ENTER_VALID_ACCOUNT_NO": "Ingrese una Cuenta Válida #",
    "ENTER_VALID_PHONE_NO": "Ingresa un Teléfono Válido #",
    "ENTER_VALID_INVOICE_NO": "Ingrese una Factura Válida #",
    "ENTER_VALID_BAR_CODE_NO_OR_SCAN": "Ingrese un N.º de Código de Barras Válido",
    "ENTER_VALID_ACCOUNT_NO_OR_SCAN_BAR_CODE": "Ingrese una Cuenta o Código de Barras validos",
    "CHECK_BALANCE": "Consultar Saldo",
    "ENTER_PAYMENT_AMOUNT": "Ingrese el Monto del Pago",
    "PAY_FULL_AMOUNT": "Pagar el Monto Total",
    "PAY_PARTIAL_AMOUNT": "Pagar Cantidad Parcial",
    "SELECT_CURRENCY": "Seleccione el Tipo de Moneda",
    "SUCCESSFUL_PURCHASE_SUBTITLE": "Has comprado con éxito $ {0} de {1} por $ {2}!",
    "SUCCESSFUL_PAID_SUBTITLE": "Has pagado correctamente $ {0} a {1}",
    "CONVERT": "Convertir",
    "PAY_BILL": "Pagar Factura",
    "PIN": "Pin",
    "ITEM_NAME": "Nombre del Árticulo",
    "INVOICE_ID": "ID de Factura",
    "CURRENT_BALANCE": "Balance de Cuentas: {0}",
    "AMOUNT_DUE": "Monto Adeudado",
    "INSUFFICIENT_BALANCE": "Saldo Insuficiente",
    "NEGATIVE_BALANCE_ERROR": "Ingrese una Cantidad mayor",
    "PLEASE_SELECT_AMOUNT": "Por favor seleccione la Cantidad",
    "PLEASE_SELECT_OPERATOR": "Por favor seleccione operadora",
    "PLEASE_SELECT_CATEGORY": "Seleccione una Categoría",
    "ACTIVATION_CODE_POPUP_TITLE": "Código de Activación",
    "ACTIVATION_CODE_POPUP_TEXT": "Defina su Código de Activación de Tarjeta personal. Este Código será requerido para activar su Tarjeta en la aplicación, o puede llamar al 844-433-8086 después de completar el registro.",
    "TRANSIT_CODE_POPUP_TITLE": "Número de Cuenta de Tránsito",
    "TRANSIT_CODE_POPUP_TEXT": "El Número de Cuenta son los últimos 16 dígitos del número que se encuentra en el reverso de su Tarjeta",
    "TRANSACTION_DETAILS_TEXT": "Detalles De La Transacción",
    "REDEEM_AMOUNT_TEXT": "Canjear Monto",
    "BILL_AMOUNT_TEXT": "Total de la Factura",
    "AUTHENTICATION_TITLE_TEXT": "Autenticacion Requerida",
    "AUTHENTICATION_SUBTITLE_TEXT": "Por Favor Verificar",
    "AUTHENTICATION_SENSOR_DESC": "Sensor Tactil",
    "AUTHENTICATION_ERROR_DESC": "Fallida",
    "CONFIRMATION": "Confirmación",
    "CONFIRMATION_SEND_MONEY": "Estás a punto de Enviar ",
    "CONFIRMATION_REQUEST_MONEY": "Estás a punto de Solicitar ",
    "FROM_1": "desde",
    "TO_1": "para",
    "IDOLOGY_FAILED": "Hubo un error con su solicitud. Verifique su información y vuelva a intentarlo.",
    "CONFIRMATION_ACTION": "Confirme su compra de: {0}.",
    "ERROR": "Error",
    "SUCCESS_TITLE": "Éxito",
    "MAINTENANCE_MESSAGE_TEXT": "Mantenimiento Programado. Por favor, vuelva a consultar pronto.",
    "UPGRADE_MESSAGE_TEXT": "Nueva versión de la Aplicación disponible. Por favor, descargue la última versión",
    "CHOOSE_CATEGORY": "Elegir la Categoría",
    "SCROLL_TO_BOTTOM": "Desplácese hasta la parte inferior para Aceptar los Términos y Condiciones",
    "ALERT_TOAST_TITLE": "Alerta",
    "REPORT_CARD_LOST_TEXT": "Reportar la Tarjeta como Perdida o Robada",
    "CUENTAS_TRANSFERS": "Transferencias de Cuentas",
    "CUENTAS_TRANSFER": "Transferencia de Cuentas",
    "AVATAR_DIALOG_TITLE": "Actualizar Imagen de Perfil",
    "AVATAR_DIALOG_SUBTITLE": "Escoge una opción.",
    "AVATAR_DIALOG_OPTION_GALLERY": "Galería",
    "AVATAR_DIALOG_OPTION_CAMERA": "Cámara",
    "IMAGE_UPLOAD_SUCCESS": "Imagen cargada con éxito",
    "IMAGE_UPLOAD_ERROR": "Error al cargar la imagen",
    "UPLOAD_USER_IMG": "Subir imagen de usuario",
    "AVTAR_SIZE": "La imagen debe tener al menos \n300x300 px \n (png., jpg. admitido)",
    "AVTAR_SUCCESS_MSG": "¡La imagen de Perfil se ha actualizado correctamente!",
    "INVITE_A_FRIEND_TRANSFER": "Invita a un amigo a Cuentas con transferencia opcional",
    "INVITE_A_FRIEND_TRANSFER_REQUEST": "Invita a un Amigo a Cuentas con una Solicitud de Fondos Opcional",
    "OPTIONAL_AMOUNT": "Ingrese la Cantidad Opcional",
    "ENTER_A_PHONE_NUMBER": "Introduzca el Número de Teléfono",
    "ACCEPT_INVITE_SEND_MONEY": "Acepta Invitación y Envía dinero",
    "ACCEPT_INVITE_REJECT_MONEY": "Aceptar invitaciones y rechazar dinero",
    "REJECT_INVITE_REJECT_MONEY": "Rechazar invitaciones y rechazar dinero",
    "OPTIONAL": "(opcional)",
    "SOMETHING_WENT_WRONG_ERROR": "¡Algo salió mal!",
    "INVITATION_ACCEPTED": "Invitación Aceptada",
    "INVITATION_REJECTED": "Invitación Rechazada",
    "ALREADY_EXIST_EMAIL": "El Correo Electrónico que seleccionó ya está registrado.",
    "EMAIL_NOT_MATCH": "El Correo Electrónico no coincide",
    "WITH_THE_MESSAGE": " Con mensaje: ",
    "REQUEST_DECLINED": "La solicitud ha sido rechazada",
    "OK": "OK",
    "MAX_AMOUNT_LIMIT": "La Cantidad Máxima podría ser de $1,000.",
    "INVITE_CONFIRMATION": "¿Estás segura de que quieres enviar Invitación?",
    "POWERED_BY_WESTERN_UNION": "DESARROLLADO POR WESTERN UNION",
    "SEND_MONEY_WITH_CUENTAS": "Envía Dinero con Cuentas",
    "HAVE_MY_WU_REWARDS_NUMBER": "Tienes un número\nde recompensas MyWU?",
    "CLICK_HEAR_TO_ENTER": "Haga clic aquí para entrar",
    "NO_MY_WU_REWARDS": "Sin recompensas MyWU?",
    "CLICK_HEAR_TO_REGISTER": "Pulse aquí para registrarse",
    "ENTER_WU_NUMBER": "Ingrese Mi Número WU de 9 dígitos",
    "ENTER_VALID_NUMBER": "Ingrese un número My WU válido",
    "NEXT": "Próxima",
    "MYWU_NUMBER": "MYWU # {0}",
    "RECEIVER_DETAILS": "Detalles del Destinatario",
    "VIEW": "Vista",
    "SELECT_YOUR_PAST_RECEIVERS": "Seleccione sus Receptores Anteriores",
    "NONE": "Ninguno",
    "OR_ENTER_NEW_RECIPIENT": "O ingrese un Nuevo Destinatario",
    "ENTER_NEW_RECIPIENT": "Ingresa Nuevo Destinatario",
    "RECEIVERS_FIRST_NAME_STAR": "Nombre del Destinatario *",
    "RECEIVERS_MIDDLE_NAME": "Segundo Nombre del Destinatario",
    "RECEIVERS_LAST_NAME_STAR": "Apellido del Destinatario *",
    "E_MAIL": "Correo Electrónico",
    "COUNTRY": "País",
    "COUNTRY_ERROR": "Por favor seleccione País",
    "STATE_ERROR": "Seleccione el Estado",
    "CITY_ERROR": "Por favor seleccione Ciudad",
    "DELIVERY_TYPE": "Tipo de Entrega",
    "SELECT_DELIVERY_TYPE": "Seleccione un Tipo de Entrega",
    "MONEY_IN_MINUTES_CAP": "DINERO EN MINUTOS",
    "MONEY_IN_MINUTES": "Dinero en Minutos",
    "STATE_TEXT": "Estado",
    "CITY_TEXT": "Ciudad",
    "BACK": "Atrás",
    "CONTINUE": "Continuar",
    "WU_TEXT": "Western Union ",
    "PRIVACY_STATEMENT": "Declaracion de Privacidad",
    "TERMS_CONDITIONS": "Términos y Condiciones",
    "WEBVIEW_LOADING_ERROR": "Hubo un problema al cargar la página, es posible que no se muestre correctamente.",
    "TRANSFER_DETAL": "Detalles de la Transferencia",
    "RECIEVER_INFO": "Información del Destinatario",
    "YOUR_ACCOUNT_INFO": "La Información de su Cuenta",
    "PAYOUT_CURRENCY": "Moneda de Pago",
    "AMOUNT2SEND": "Monto a Enviar",
    "PROMO_CODE": "Codigo Promocional",
    "NOTIFICATIONS_BY_EMAIL": "Notificaciones por Correo Electrónico",
    "NOTIFICATIONS_BY_SMS": "Notificaciones por Mensaje de Texto",
    "MESSAGING_OPTIONS": "Opciones de Mensajería",
    "MESSAGING_OPTIONS_USER_MESSAGE_EMAIL": "*Al proporcionar su dirección de Correo Electrónico, acepta recibir correos electrónicos transaccionales y de marketing de Western Union. Para administrar sus preferencias, consulte nuestra Declaración de privacidad.",
    "MESSAGING_OPTIONS_USER_MESSAGE_SMS": "Al completar, autoriza a Western Union a enviarle notificaciones por Mensaje de Texto sobre el estado de su transferencia de dinero, incluida la notificación cuando el Destinatario retira los fondos. Se aplican Tarifas de mensajes y datos estándar.",
    "NEED_OPTIONAL_NOTIFICATION": "¿Le gustaría recibir una notificación opcional\ncuando se complete la transferencia?",
    "MYWU_HISTORY": "Historia de MyWU",
    "WU_TRANSFER_INFO": "Western Union también obtiene ganancias en el cambio de moneda. Al elegir un transmisor de dinero, compara con cuidado las Tarifas de envío y las tasas de cambio. Las Tarifas y las tasas de cambio pueden variar conforme a la marca, el canal y la localidad a base de una serie de factores. Las Tarifas y las tasas de cambio están sujetas a cambio sin previo aviso",
    "CONFIRM_TRANSFER": "Confirmar Transferencia",
    "NOT_A_RECEIPT": "NO ES UN RECIBO",
    "WU_CONFIRMATION_INFO": "Date available will be displayed on receipt for international transfers over $15. Service and funds availability depends on certain factors including the Service selected, the selection of delayed delivery options, special terms applicable to each Service, amount sent, destination country, currency availability, regulatory issues, consumer protection issues, identification requirements, delivery restrictions, agent location hours, and differences in time zones (collectively, “Restrictions”).\n\nWestern Union también obtiene ganancias en el cambio de moneda. Al elegir un transmisor de dinero, compara con cuidado las Tarifas de envío y las tasas de cambio. Las Tarifas y las tasas de cambio pueden variar conforme a la marca, el canal y la localidad a base de una serie de factores. Las Tarifas y las tasas de cambio están sujetas a cambio sin previo aviso",
    "SENDER": "Remitente",
    "RECIEVER": "Destinatario",
    "INVOICE": "Factura",
    "SENDER_INFO": "Información del Remitente",
    "EDIT_DETAILS": "Editar Detalles",
    "CONFIRM_SEND": "Confirmar y Enviar",
    "FINAL": "Final",
    "ORIGINAL": "Original",
    "TRACKING_NUMBER": "Número de Control del Envio",
    "DATE_OF_TRANSACTION": "Fecha de la Transacción",
    "TIME_OF_TRANSACTION": "Hora de la Transacción",
    "MY_WU_NUMBER": "Número My WU",
    "TRANSFER_DETAILS_INFO_WU": "La fecha de Disponibilidad estará visible en el recibo de las transferencias internacionales por importes superiores a $15. La disponibilidad del servicio y de los fondos depende de factores tales como el Servicio seleccionado, la selección de opciones de entrega diferida, los términos especiales aplicables a cada Servicio, la cantidad enviada, el país destinatario, la disponibilidad de moneda, los asuntos reglamentarios y de protección al consumidor, los requisitos de identificación, las restricciones de entrega, los horarios de las agencias de Western Union y los diferentes husos horarios (colectivamente \"Restricciones\"), entre otros.\n\nWestern Union también obtiene ganancias en el cambio de moneda. Al elegir un transmisor de dinero, compara con cuidado las Tarifas de envío y las tasas de cambio. Las Tarifas y las tasas de cambio pueden variar conforme a la marca, el canal y la localidad a base de una serie de factores. Las Tarifas y las tasas de cambio están sujetas a cambio sin previo aviso",
    "TOTAL_POINTS": "Puntos Totales",
    "FAILED_TO_GET_COUNTRIES": "No se pudo recuperar la lista de Países. Por favor, inténtelo de nuevo más tarde.",
    "FAILED_TO_GET_STATE": "No se pudo recuperar la lista de Estados. Por favor, inténtelo de nuevo más tarde.",
    "FAILED_TO_CURRENCY_RATE": "No se pudo obtener el Tipo de Cambio actual. Por favor, inténtelo de nuevo más tarde.",
    "WU_PRIVACEY_AGGREEMENT": "Acepto la Declaración de Privacidad",
    "WU_TERMS_AGGREEMENT": "Estoy de acuerdo con los Términos y Condiciones",
    "WU_TERMS_ERROR": "Acepte los Términos y Condiciones para continuar.",
    "PAY_FROM_ACCOUNT": "Pagar Desde Cuenta",
    "ASSOCIATE_CHECKING": "Cuenta de Cheques Asociada",
    "PAYOUT_COUNTRY": "País de pago",
    "PAYOUT_STATE": "Estado de pago",
    "PAYOUT_CITY": "Ciudad de pago",
    "ESTIMATED_TOTAL_TO_RECIEVER": "Total estimado al Destinatario",
    "TOTAL_TO_RECEIVER": "Total al Destinatario Final",
    "TOTAL": "Total",
    "SERVICE_TYPE": "Tipo de Servicio",
    "TRANSFER_AMOUNT": "Monto de la Transferencia",
    "TRANSFER_FEES": "Tasas de Transferencia",
    "ADDITIONAL_FEES": "Cargos Adicionales",
    "TRANSFER_TAXES": "Impuestos de Transferencia",
    "PROMOTION_DISCOUNT": "Descuento Promocional",
    "OTHER_FEES": "Otros Cargos",
    "PAYMENT_SUMMERY": "Resumen de Pago",
    "DELIVERY_TYPE_SUMMERY": "La fecha de Disponibilidad estará visible en el recibo de las transferencias internacionales por importes superiores a $15. La disponibilidad del Servicio y de los fondos depende de factores tales como el Servicio seleccionado, la selección de opciones de entrega diferida, los términos especiales aplicables a cada servicio, la cantidad enviada, el país destinatario, la disponibilidad de moneda, los asuntos reglamentarios y de protección al consumidor, los requisitos de identificación, las restricciones de entrega, los horarios de las agencias de Western Union y los diferentes husos horarios (colectivamente \"Restricciones\"), entre otros.",
    "CAN_NOT_BIG_THEN_BALANCE": "Saldo insuficiente para realizar la transferencia.",
    "INSUFFICIENT_BALANCE_WITH_ALL_CHARGES": "Saldo insuficiente después de sumar todos los cargos. El Monto total a pagar después de agregar todos los cargos será {0}",
    "NO_CURRENCY_AVAILABLE": "No hay Moneda disponible",
    "ESTIMATED_EXCHANGE_RATE": "Tipo de Cambio Estimado",
    "EXCHANGE_RATE": "Tipo de Cambio",
    "ESTIMATED_TRANSFER_AMOUNT": "Cantidad Estimada de Envío",
    "EMPTY_AMOUNT_NOT_ALLOW": "El campo de cantidad no se puede vaciar",
    "FAILED_TO_SEND_MONEY_VALUATION": "No se pudieron recuperar los datos de la transacción. Por favor, inténtelo de nuevo más tarde.",
    "WU_INVALID_RANGE_MSG": "Por favor ingrese el Monto entre ${0}-${1}",
    "INVITATION_REQUEST_MSG": " quiere agregarte a su libreta de direcciones de Cuentas",
    "CHANGE_LANGUAGE": "Cambiar Idioma",
    "TRANSACTION_DETAILS": "Detalles De La Transacción",
    "ALL_HISTORY": "Toda La Historia",
    "ENTER_NEW_NO": "Ingresa un Nuevo Número de Teléfono",
    "ENTER_PIN_CODE": "Ingrese el Código PIN",
    "SEND_CODE": "Enviar Código",
    "CHECK_PHONE_FOR_PIN": "Verifique su Teléfono para el Código PIN",
    "CHECK_EMAIL_FOR_PIN": "Verifique su Correo Electrónico para obtener el Código PIN",
    "EMAIL_UPDATE_SUCCESS": "Su Correo Electrónico se ha actualizado correctamente",
    "PHONE_UPDATE_SUCCESS": "Tu Número de Teléfono se ha actualizado correctamente",
    "CHANGE_EMAIL": "Cambiar Correo Electrónico",
    "CHANGE_PHONE": "Cambiar Número de Teléfono",
    "ENTER_NEW_EMAIL": "Ingrese un Nuevo Correo Electrónico",
    "ENTER_YOUR_NEW_EMAIL": "Ingrese su Nuevo Correo Electrónico",
    "NEW_EMAIL_PLACEHOLDER": "Nuevo Correo Electrónico",
    "CONFIRM_EMAIL_PLACEHOLDER": "Confirme Nuevo Correo Electrónico",
    "UPDATE_EMAIL_INFO": "Ingrese y confirme su nuevo Correo Electrónico",
    "DIDNT_RECIEVE_PIN": "¿No recibió el PIN?",
    "RESEND_TEXT": "Reenviar",
    "VERIFICATION_CODE_INFO": "En caso de que no haya recibido el Código, presione el botón de Reenviar y le enviaremos un nuevo Código.",
    "PLEASE_ENTER_CONFIRM_EMAIL": "Por favor ingrese Confirmar Correo Electrónico",
    "PLEASE_ENTER_VALID_CONFIRM_EMAIL": "Ingrese un Correo Electrónico de Confirmación válido",
    "ESTIMATED_TOTAL_TO_RECEIVER": "Total estimado al destinatario final",
    "RECIVER_INFO": "Información del Destinatario",
    "PLEASE_ACTIVATE_YOUR_CARD": "Por Favor primero Active su Tarjeta",
    "CARD_ACTIVATION_FAILED": "La Activación de la Tarjeta falló",
    "CUENTAS_PURCHASES": "Compras en Cuentas",
    "FEATURED_ITEMS": "Productos Destacados",
    "BUY_NOW": "Comprar Ahora",
    "TRANSFER": "Transferir",
    "AUTH_WU_MESSAGE": "¿Desea enviar dinero al extranjero o a usuarios que no son de Cuentas?\nNecesitamos recopilar información adicional para comenzar.",
    "WHAT_YOU_WILL_NEED": "Lo que necesitarás:",
    "VALIDATE_EMAIL": "Validar Correo Electrónico",
    "ENABLE_2_FACTOR_AUTHENTICATION": "Habilitar la Autenticación de 2 Factores",
    "USER_VERIFICATION": "Verificación de Usuario",
    "UPLOAD_A_VALID_ID": "Cargar una Identificación Válida",
    "PROCEED_AND_SEND_EMAIL_VALIDATION_LINK": "Continuar y Enviar el Enlace de Validación por Correo Electrónico",
    "PROCEED_AND_UPLOAD_ID": "Proceda y Suba la Identificación",
    "PROCEED_AND_SEND_SMS_CODE": "Continuar y Enviar Código de Mensaje de Texto",
    "SUBMIT_AND_ENABLE_2FA": "Enviar y habilitar la Autenticación de 2 Factores",
    "ENTER_EMAIL_VERIFICATION_CODE": "Ingrese el Código de verificación de Correo Electrónico",
    "EMAIL_VERIFICATION": "Verificación de Correo Electrónico",
    "PHONE_VERIFICATION": "Verificación por Teléfono",
    "ENTER_PIN_IS_INVALID": "El PIN ingresado no es válido.",
    "WE_SENT_YOU_PIN_TO": "Se envió un Código a {0}",
    "PIN_SENT": "PIN enviado",
    "PRESS_RESEND_FOR_NEW_CODE": "Si no recibió un Código, presione el botón \"Reenviar Código\".",
    "FAQS": "FAQs",
    "EMAIL_ITEM": "Correo Electrónico",
    "SENDER_CITY": "Ciudad del remitente",
    "SENDER_STATE": "Estado del remitente",
    "SENDER_PHONE": "Teléfono del remitente",
    "EXPECTED_FOREIGN_COUNTRY_PAYOUT_LOCATION": "Ubicación de Pago Esperada en el País Extranjero",
    "EXPECTED_COUNTRY_PAYOUT_LOCATION": "Ubicación de Pago Esperada",
    "FOREIGN_COUNTRY_PAYOUT_LOCATION": "Ubicación de Pago en el País Extranjero",
    "COUNTRY_PAYOUT_LOCATION": "Ubicación de Pago",
    "FINAL_RECEIVER": "Destinatario Final",
    "TRACKING_INFO": "Información de Seguimiento",
    "TRACKING_NUMBER_MTCN": "Número de Control del Envío\n(MTCN)",
    "DOCUMENT_NOT_VERIFIED_ERROR": "Documento no verificado, inténtelo de nuevo.",
    "WU_VERIFICATION_SUCCESS_MSG": "Ahora eres elegible para Transferencias de Western Union",
    "NO_TRANSACTION_FOUND": "Esta transacción ya está cancelada",
    "TRANSACTION_CANNOT_BE_CANCELLED": "Esta transacción no se puede Cancelar.",
    "MTCN_ON_HOLD": "Esta transacción está En Espera. Por favor, inténtelo después de unos minutos.",
    "CANCEL_NOT_ALLOWED": "Cancelación no permitida",
    "STATUS_PENDING": "Estado: Pendiente",
    "CANCEL_TRANSFER": "Cancelar Transferencia",
    "REFUND_TRANSFER": "Transferencia de Reembolso",
    "CANCEL_CONFIRMATION": "¿Quieres Cancelar tu transferencia?",
    "REFUND_CONFIRMATION": "¿Le gustaría Reembolsar su transferencia?",
    "SENDER_NAME": "Nombre del Remitente",
    "SENDER_ADDRESS": "Dirección del Remitente",
    "SENDER_ZIP_CODE": "Código Postal del Remitente",
    "SENDER_NOTIFICATION": "Notificación del Remitente",
    "EMAIL_RECEIPT_SUCCESS": "Recibo de Correo Electrónico enviado con éxito",
    "NO_PENDING_DUES": "No tienes Cargos pendientes",
    "REFUNDED": "Reembolsado",
    "PAID": "Pagada",
    "WU_CONTACT_INFO": "Si tiene preguntas o quejas sobre Western Union, comuníquese con:\nNombre regulador del estado del cliente que envía: {0}\nEnvío de Teléfono reglamentario del estado del cliente n. #1: {1}\nEnvío de Teléfono reglamentario del estado del cliente #2: {2}\nURL del sitio web de la agencia reguladora estatal: {3}\nTeléfono CFPB de la Oficina de Protección Financiera del Consumidor #1: {4}\nTeléfono CFPB #2: {5}\nURL del sitio web de Safpub: {6}",
    "FULL_NAME": "Nombre Completo",
    "CONTACT_LIST": "Lista de Contactos",
    "ACTIVATION_SLIDER_TITLE_1": "Bienvenido a Cuentas",
    "ACTIVATION_SLIDER_TITLE_2": "Activar tu Tarjeta",
    "ACTIVATION_SLIDER_TITLE_3": "Configurar\nDepósito Directo",
    "ACTIVATION_SLIDER_TITLE_4": "Pide a tus amigos\n que te envíen dinero",
    "ACTIVATION_SLIDER_TITLE_5": "Ahora estás listo para\n comenzar",
    "ACTIVATION_SLIDER_TEXT_1": "Estamos muy contentos de tenerte como tarjetahabiente. Aquí hay algunos consejos para comenzar hasta que recibas su tarjeta.",
    "ACTIVATION_SLIDER_TEXT_2": "Vaya a la configuración en la aplicación Cuentas, haga clic en el enlace para activar su Tarjeta. Toma un bolígrafo y escribe el Código de 4 dígitos que ves en la aplicación para que puedas usarlo para activar tu Tarjeta en el siguiente paso.",
    "ACTIVATION_SLIDER_TEXT_3": "Configure fácilmente el Depósito Directo de su cheque de pago o cheque de beneficios del gobierno, sin cargo. Verá su cuenta y número de ruta después de completar la activación de la Tarjeta.",
    "ACTIVATION_SLIDER_TEXT_4": "Mientras espera su Tarjeta por correo, puede pedir a otros Titulares de Tarjetas de Cuentas que le transfieran dinero sin cargo!",
    "ACTIVATION_SLIDER_TEXT_5": "¡Navegue por la tienda de aplicaciones Cuentas para recibir descuentos en marcas como Amazon, Burger King y más!",
    "WELCOME_TO_CUENTAS_FAMILY": "¡Bienvenido a la Familia Cuentas!",
    "SUCCESS_SCREEN_TEXT" : "Escanee el código QR de abajo para descargar la aplicación y empezar a utilizar su cuenta hoy mismo. Recibirá su tarjeta personalizada dentro de 7 a 10 días hábiles.",
    "CARD_HOLDER_AGREEMENT_PDF": "es_app_link_cha_pdf",
    "SCAN": "Escanear",
    "BAR_CODE_SCAN_MODAL_HEADING": "Pago de Facturas México",
    "BAR_CODE_SCAN_MODAL_DESCRIPTION": "Para encontrar su <Número de Cuenta>, busque una copia de su factura y busque el área resaltada a continuación. \n \nTambién puede escanear el Código de Barras proporcionado.",
    "BAR_CODE_SCAN_MODAL_DESCRIPTION_WITH_NO_IMAGE": "La mayoría de las empresas utilizan un número de cuenta, un número de factura o una combinación de ambos. Revise su factura y ubique uno o ambos de estos dos números antes de comenzar.",
    "PERMISSION_BLOCKED": "El permiso de la cámara está denegado, vaya a la configuración y habilite el permiso de la cámara.",
    "OOPS_ERROR": "¡UPS! Algo salió mal",
    "GMAIL": "GMAIL.COM",
    "YAHOO": "YAHOO.COM",
    "OUTLOOK": "OUTLOOK.COM",
  }
