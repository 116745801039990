import Styled from 'styled-components'

const ModalWrapper = Styled.div`
    .modal-wrap {
        box-shadow: 0px 20px 30px rgba(43, 43, 43, 0.1);
        border-radius: 16px;
        width: 70%;
        min-width: 480px;
        margin: auto;
        height: 60%;
        min-height: calc(100vh - 180px);
        background: ${ props => props.theme.white };
        flex: 1;
        padding: 40px;
        position: relative;
        z-index: 2;
        .modal-container {
            margin: auto;
        }
        iframe {
            height: 70vh;
            margin-top: 60px;
            overflow-y: auto;
        }
        button{
            width: calc(50% - 5px);
            font-size: 16px;
            line-height: 21px;
            padding: 18px 3px;
            margin-top: 10px;
            &.btn-close {
                border-radius: 12px;
                width: 162px;
                padding: 18px;
                margin-top: 12px;
            }
        }
        h3 {
            color: #333333;
            text-align: center;
            font-family: font-bold;
            font-size: 28px;
            line-height: 21px;
            margin-top: 25px;
        }
        h4 {
            font-size: 14px;
            line-height: 22px;
        }
        p {
            margin-left: 15px;
            text-align: center;
            margin-bottom: 0;
            font-size: 14px;
        }
         .fees-block-short{
            margin: 40px 0 10px 0;
            .overflow-page{
                max-height: calc(100vh - 404px);
                overflow-y: auto;
                p{
                    text-align: left;
                    img{
                        margin: auto;
                        display: flex;
                    }
                }
            }
        }
        .fees-block-long{
            margin: 70px 0 10px 0;
            .overflow-page{
                max-height: calc(100vh - 434px);
                overflow-y: auto;
                p{
                    text-align: left;
                    img{
                        margin: auto;
                        display: flex;
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .pdf-block{
            margin: 70px 0 10px 0;
            .pdf-scroll{
                overflow-y: auto;
                max-height: calc(100vh - 434px);
                border: 1px solid;
                .pdf-canvas{
                    margin: auto;
                    width: 673px;
                }
            }
        }
    }

    @media (max-width: 1024px){
        .modal-wrap{
            height: 100%;
        }
        .pdf-scroll{
            overflow-y: hidden !important;
            max-height: none !important;
            margin-top: 80px !important;
        }
        .overflow-page{
            overflow-y: hidden !important;
            max-height: none !important;
            overflow-x: auto;
        }
    }

    @media (max-width:800px){
        iframe {
            margin-top: 100px !important;
        }
    }

    @media (max-width: 768px){
        .modal-wrap{
            padding: 20px;
        }
        .fees-block-short{
            margin: 50px 0 10px 0 !important;
        }
        .fees-block-long{
            margin: 90px 0 10px 0 !important;
        }
        
    }

    @media (max-width:575px){
        .modal-wrap{
            min-width: 80%;
        }
        .pdf-block{
            margin-top: 110px !important;
        }
    }
}
`
export default ModalWrapper