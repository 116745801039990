import React from 'react';
import {  Nav } from './Navbar.styled';
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux';

const SliderProgressBar = ()  => {
    const step =  useSelector((state) => state.theme.sliderStep||1)
    const getActiveBar = (value) => {
        return step >= value && 'active'
    }
    return (
            <Nav>
              <div className="progress-wrapper slider-wrapper">
                        <ul className="progressbar">
                            <li className={ getActiveBar(1) }></li>
                            <li className={ getActiveBar(2) }></li>
                            <li className={ getActiveBar(3) }></li>
                            <li className={ getActiveBar(4) }></li>
                            {/* <li className={ getActiveBar(5) }></li> */}
                        </ul>
                    </div>
            </Nav>
    )
}
export default withRouter(SliderProgressBar);