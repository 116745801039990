import React, { useEffect,useState } from 'react';
import Button from 'components/shared/Button.styled';
import { Field, reduxForm,change } from 'redux-form';
import { useDispatch,useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import { renderField } from 'utils/formUtils';
import { ROUTES } from 'constants/AppRoutes';
import { signupFormValidate as validate } from 'utils/formUtils/validator';
import { verifyEmail } from 'middleware/signup';
import Card from 'components/shared/Card';
import history from 'utils/history';
import { getStringBeforeChar, getToken, navigationStep, setData, locale, getData } from 'utils/helpers';
import { GET_STEP_PROGRESS_BAR, SET_DOMAIN,GET_STEP_DOCUMENT_PROGRESS_BAR, SET_STEP, SET_DOCUMENTS_STEP } from 'constants/App';

import Eye from "../../assests/images/eye.svg";
import EyeCross from "../../assests/images/eye-cross.svg";
import Logo from "../../assests/images/logo.png";
import authBackground from "../../assests/images/auth-bg.png";

const SignupForm = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, initialize } = props;
  const token = getToken();
  const step = getData('step');
  const pdf = getData('pdfUrl');
  const email = useSelector((state) => state.signup.emailResponse || false);
  const emailValue = useSelector((state) => state.signup.exists || false);

  const formValue = useSelector((state) => state.form?.signup?.values);
  const domainValue = useSelector((state) => state.theme?.domain);
  const verifyUserData = useSelector((state) => state.signup?.userData);

  const dispatch = useDispatch();
  const languageSelected = locale() ? locale() : 'en';
  const [isPasswordClicked, setIsPasswordClicked] = useState(false);
  const [isConfirmPasswordClicked, setIsConfirmPasswordClicked] = useState(false);

  const onPasswordClicked = () => {
    setIsPasswordClicked(!isPasswordClicked)
  }

  const onConfirmPasswordClicked = () => {
    setIsConfirmPasswordClicked(!isConfirmPasswordClicked)
  }

  const submit = (values) => {
    token && localStorage.removeItem('token');
    pdf && localStorage.removeItem('pdfUrl');
    dispatch(verifyEmail(values?.email,values, t));
  }

  const handleLangChange = (e) => {
    i18n.changeLanguage(e.value)
    setData('locale',e.value)
    dispatch(change('signup','email',null))
  }

  const langOptions= [
    {
      name: 'EN',
      value: 'en',
    },
    {
      name: 'ES',
      value: 'es',
    },
  ]

  const handleDomainChange = (domain) => {
    const initialValue = domain ? getStringBeforeChar(formValue?.email, '@') : formValue?.email;
    if(initialValue === "") {
      if(formValue.email.includes(domainValue)){
        formValue.email = domain
      } else {
        formValue.email = formValue.email + domain
      }
    } else {
      formValue.email = initialValue + domain
    }
    dispatch({
      type: SET_DOMAIN,
      data: domain
    })
  }

  useEffect(()=> {
    if(!email.exists) {
      history.push(ROUTES.VERIFICATION)
    }
  }, [email.exists])

  useEffect(()=> {
    step && localStorage.removeItem('step');
  }, [])

  useEffect(() => {
    if(emailValue) {
      const progressBar = navigationStep(verifyUserData, history) && navigationStep(verifyUserData,history)[0];
      const step =  navigationStep(verifyUserData,history) && navigationStep(verifyUserData,history)[1];
      if(progressBar === GET_STEP_PROGRESS_BAR) {
        dispatch({
          type: SET_STEP,
          step: step
        })
        dispatch({
          type: GET_STEP_PROGRESS_BAR,
          data: { title: t('CREATE_NEW_ACCOUNT'), step: step }
      })
      } else if(progressBar === GET_STEP_DOCUMENT_PROGRESS_BAR){
        dispatch({
          type: SET_DOCUMENTS_STEP,
          step: step
        })
        dispatch({
          type: GET_STEP_DOCUMENT_PROGRESS_BAR,
          data: { title: t('CARHOLDER_AGREEMENT'), documentStep: step }
      })
      }
    }
  }, [emailValue])

  useEffect(()=>{
    initialize({
      email: formValue?.email || ''
    })
  }, [formValue?.email])

  return (
    <div className="auth-container h-100" style={{ backgroundImage: `url(${authBackground})` }}>
      <div className="logo-container text-center">
        <img src={Logo} alt=""/>
      </div>

     <Card className="main-card">
       <div className="language-select d-flex justify-content-between">
        {langOptions?.map((item, index) => {
          return (
            <span
              key={index}
              className={item.value === languageSelected ? 'active' : ''}
              onClick={() => handleLangChange(item) }
            >{t(`${item?.name}`)}
            </span>
          )
        })}
      </div>
      <h3>{t('SIGN_UP')}</h3>
      <form onSubmit={ handleSubmit( submit ) } className="auth-form">
        <div className="scrollable-content">
        <div className="form-field">
          <Field
          name="email"
          type="email"
          placeholder={t('EMAIL')}
          component={renderField}
          />
        <div className="text-left domain-blocks">
          <span onClick={() => handleDomainChange('@gmail.com')}>@{t('GMAIL')}</span>
          <span onClick={() => handleDomainChange('@yahoo.com')}>@{t('YAHOO')}</span>
          <span onClick={() => handleDomainChange('@outlook.com')}>@{t('OUTLOOK')}</span>
        </div>
        </div>

        <div className="form-field d-flex w-100 position-relative flex-column">
          <div className="password-block">
            <Field
              name="password"
              type={!isPasswordClicked ? "password" : "text" }
              placeholder={t('PASSWORD')}
              component={renderField}
            />
          </div>
            <div className="input-group-append">
              <span className="input-group-text-img cursor-pointer">
                <img
                  src={!isPasswordClicked ? EyeCross : Eye}
                  onClick={() => onPasswordClicked()}
                  width="100%"
                  alt=""
                />
              </span>
            </div>
          <div className="password-type">
          {t('PASSWORD_TEXT')}
        </div>
        </div>
        <div className="form-field d-flex w-100 position-relative">
          <div className="password-block">
          <Field
            name="confirmPassword"
            type={!isConfirmPasswordClicked ? "password" : "text" }
            placeholder={t('CONFIRM_PASSWORD')}
            component={renderField}
          />
            </div>
            <div className="input-group-append">
              <span className="input-group-text-img cursor-pointer">
                <img
                  src={!isConfirmPasswordClicked ? EyeCross : Eye}
                  onClick={() => onConfirmPasswordClicked()}
                  width="100%"
                  alt=""
                />
              </span>
            </div>
        </div>
        </div>
        <div className="text-center">
      <Button outlined type='submit' className="submit-btn">{t('SIGN_UP')}</Button>
      </div>
      </form >
      </Card>
      <div className="height-block d-none"></div>
    </div>
  )
}

export default reduxForm({
    form: 'signup', // a unique identifier for this form
    destroyOnUnmount: false,
    validate
})(SignupForm);