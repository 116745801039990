import { ROUTES } from 'constants/AppRoutes'
import React from 'react'
import { useSelector } from 'react-redux'
import history from 'utils/history'
import DocumentProgressBar from './DocumentProgressBar'
import MainLoader from './Loader'
import SignupProgressBar from './SignupProgressBar'

export const Layout = (props) => {
    const loading = useSelector((state) => state.signup.loading || state.documents.loading)

    const showDocumentsProgressBar = () => {
        return [ ROUTES.CHA_DOCUMENT, ROUTES.SHORT_FEES_DOCUMENT, ROUTES.LONG_FEES_DOCUMENT ].includes(history.location.pathname)
    }

    return(
    <div className={`main-layout active-progress-bar h-100`}>
        {!showDocumentsProgressBar() ? history.location.pathname === '/signup' || history.location.pathname === '/error' ? <></> : <SignupProgressBar/> : <></>}
        {showDocumentsProgressBar() && <DocumentProgressBar/>}
        {loading && <MainLoader />}
        {props.children }
    </div>
    )
}
