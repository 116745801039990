import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { GET_STEP_PROGRESS_BAR } from 'constants/App';
import Button from 'components/shared/Button.styled';
import Card from 'components/shared/Card';

import Logo from "../../assests/images/logo.png";
import QR from "../../assests/images/Android QR Code.png";
import QRCode from "../../assests/images/iOS QR Code.png";
import Google from "../../assests/images/google.png";
import Apple from "../../assests/images/app-store.png";
import authBackground from "../../assests/images/auth-bg.png";

const SuccessPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.clear();
    dispatch({
        type: GET_STEP_PROGRESS_BAR,
        data: { title: t('SUCCESS_TITLE'), step: 4 }
    })
  },[])

  return (
    <div className="auth-container success-card h-100" style={{ backgroundImage: `url(${authBackground})` }}>
      <div className="logo-container text-center">
        <img src={Logo} alt=""/>
      </div>
      <Card className="main-card">
        <div className="success-content scrollable-content text-center">
        <h5><strong>{t('WELCOME_TO_CUENTAS_FAMILY')}</strong></h5>
        <p>{t('SUCCESS_SCREEN_TEXT')}</p>
        <div className="success-img d-flex flex-column flex-sm-row justify-content-between justify-content-lg-evenly">
          <div className="d-flex flex-column">
            <Button type="submit" className="success-btns">
              <img src={Google} className="button-imgs"  alt="google-img"/>
            </Button>
            <img src={QR} className="qr-imgs" alt="success-img"/>
          </div>
          <div className="d-flex flex-column">
            
            <Button type="submit" className="success-btns apple-btn">
              <img src={Apple} className="button-imgs" alt="apple-img"/>
            </Button>
            
            <img src={QRCode} className="qr-imgs" alt="success-img"/>
          </div>
          </div>
        </div>
        {/*<div className="text-center">
        <Button outlined type='submit' className="submit-btn">{t('GO_TO_MY_ACCOUNT')}</Button>
        </div>*/}
        </Card>
        <div className="height-block d-none"></div>
      </div>
  )
}

export default SuccessPage;