import Styled from 'styled-components'

export const Input = Styled.input`
    background: ${ props => props.theme.white };
    border: 0.5px solid #D5D8DE;
    box-sizing: border-box;
    border-radius: 6px;
    line-height: 40px;
    height: 40px;
    font-size: 14px;
    &.validation-error {
        background: #f5dcdc96;
        border: 0.3px solid red;
    }
    &:hover,
    &:active,
    &:focus {
        box-shadow: none;
    }
    &:focus-visible {
        outline: #86b7fe auto 1px;
    }
    &::placeholder {
        color: #B3B9C2;
        font-size: 14px;
      }
`
