import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';

import { SET_SLIDER_STEP } from 'constants/App';
import Slider from '../Onboarding/slider';
import Steps from './steps.styles';

import Slider1 from "../../assests/images/onboarding1.png";
import Slider2 from "../../assests/images/onboarding2.png";
import Slider3 from "../../assests/images/onboarding3.png";
import Slider4 from "../../assests/images/onboarding4.png";
// import Slider5 from "../../assests/images/onboarding5.png";
import { setData } from 'utils/helpers';

const OnboardingStep = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const step = useSelector((state) => state.theme.sliderStep || 1)

    const nextPage = () => {
        dispatch({
            type: SET_SLIDER_STEP,
            step: (step+1)
        })
    }

    const prevPage = () =>  {
        dispatch({
            type: SET_SLIDER_STEP,
            step: (step-1)
        })
    }

    useEffect(()=>{
        i18n.changeLanguage('en')
        setData('locale', 'en')
    },[])

    const handleView = () => {
        switch(step){
        case 1:
            return <Slider
                title={t('ONBOARDING1_TITLE')}
                body={t('ONBOARDING1_TEXT')}
                step={1}
                prevPage={ prevPage }
                nextPage={ nextPage }
                imgSrc={Slider1}
            />
        case 2:
            return <Slider
                title={t('ONBOARDING2_TITLE')}
                body={t('ONBOARDING2_TEXT')}
                step={2}
                prevPage={ prevPage }
                nextPage={ nextPage }
                imgSrc={Slider2}
        />
        case 3:
            return <Slider
                title={t('ONBOARDING3_TITLE')}
                body={t('ONBOARDING3_TEXT')}
                footer={t('SLIDER_TEXT')}
                step={3}
                prevPage={ prevPage }
                nextPage={ nextPage }
                imgSrc={Slider3}
        />
        case 4:
            return <Slider
                title={t('ONBOARDING4_TITLE')}
                body={t('ONBOARDING4_TEXT')}
                step={4}
                prevPage={ prevPage }
                nextPage={ nextPage }
                imgSrc={Slider4}
        />
        // case 5:
        //     return <Slider
        //         title={t('ONBOARDING5_TITLE')}
        //         subTitle={t('ONBOARDING5_TITLE_TXT')}
        //         subText1={t('ONBOARDING5_SUB_TEXT')}
        //         subText2={t('ONBOARDING5_SUB_FRSTTEXT')}
        //         subText3={t('ONBOARDING5_SUB_SCNDTEXT')}
        //         subText4={t('ONBOARDING5_SUB_THRD_TEXT')}
        //         subText5={t('ONBOARDING5_SUB_FRTH_TEXT')}
        //         step={5}
        //         prevPage={ prevPage }
        //         imgSrc={Slider5}
        // />
        default:
            return
        }
    }

    return(
        <Steps>
            {handleView()}
        </Steps>
    )
}

export default withRouter(OnboardingStep)
