import React, { useEffect } from 'react';
import { Field, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';
import { useDispatch,useSelector } from 'react-redux';

import { GET_STEP_PROGRESS_BAR } from 'constants/App';
import { ROUTES } from 'constants/AppRoutes';
import history from 'utils/history';
import { postUserData } from 'middleware/signup';
import { renderField, renderPhoneNumberField } from 'utils/formUtils';
import { accountFormValidate as validate } from 'utils/formUtils/validator';
import Card from 'components/shared/Card';
import Button from 'components/shared/Button.styled';

import Logo from "../../assests/images/logo.png";
import authBackground from "../../assests/images/auth-bg.png";
import { getData } from 'utils/helpers';

const AccountForm = (props) => {
  const { handleSubmit } = props;
  const { t } = useTranslation();
  const step = getData('step');
  const dispatch = useDispatch();
  const signupFormValues = useSelector((state) => state.form.signup?.values || {});
  const email = useSelector((state) => state.signup?.emailResponse || false);

  const submit = (values) => {
    if(Object.keys(signupFormValues).length > 0) {
      dispatch(postUserData(signupFormValues,values,t))
    }
  }

  useEffect(() => {
    email.exists = true;
    dispatch({
        type: GET_STEP_PROGRESS_BAR,
        data: { title: t('CREATE_NEW_ACCOUNT'), step: 1 }
    })
  },[])

  useEffect(() => {
    if(Object.keys(signupFormValues).length === 0) {
        history.push(ROUTES.SIGNUP)
    }
    },[])

  useEffect(() => {
    if(step === '2') {
      history.push(ROUTES.SIGNUP)
    }
  },[step])


  const goBack = () => {
    history.push(ROUTES.SIGNUP)
  }

  return (
    <div className="auth-container h-100" style={{ backgroundImage: `url(${authBackground})` }}>
      <div className="logo-container text-center">
        <img src={Logo} alt=""/>
      </div>

      <Card className="main-card">
      <form className="margin-top space-top" onSubmit={ handleSubmit( submit ) } >
      <div className="scrollable-content account-scrollable-content">
      <Field
        name="firstName"
        type="text"
        placeholder={t('FIRST_NAME_TEXT')}
        component={renderField}
      />
       <Field
        name="middleName"
        type="text"
        placeholder={t('MIDDLE_NAME_TEXT')}
        component={renderField}
      />
      <Field
        name="lastName"
        type="text"
        placeholder={t('LAST_NAME_TEXT')}
        component={renderField}
      />
      <div className="telephone-no">
       <Field
        name="phoneNumber"
        type="tel"
        disableDropdown={true}
        countryCodeEditable={false}
        component={renderPhoneNumberField}
      />
      </div>
      </div>
      <div className="d-flex justify-content-between flex-btns">
        <Button onClick={() => goBack()} className="back-button"> {t('BACK') }</Button>
        <Button outlined type='submit' className="confirm-mobile">{t('CONFIRM_MOBILE_NUMBER')}</Button>
      </div>
      </form>
      </Card>
      <div className="height-block d-none"></div>
      </div>
  )
}

export default reduxForm({
    form: 'account', // a unique identifier for this form
    destroyOnUnmount: false,
    validate
})(AccountForm);