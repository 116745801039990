import { ActionTypes } from './actionTypes'

export const getArticlesRequest = () => {
    return { type:  ActionTypes.GET_ARTICLES_REQUEST }
}
export const getArticlesSuccess = (response) => {
    return {
        type: ActionTypes.GET_ARTICLES_SUCCESS,
        payload: response
    }
}
export const getArticlesFailed = () => {
    return {
        type: ActionTypes.GET_ARTICLES_FAILED,
    }
}

export const getArticlesLinkRequest = () => {
    return { type:  ActionTypes.GET_ARTICLES_LINK_REQUEST }
}
export const getArticlesLinkSuccess = (response) => {
    return {
        type: ActionTypes.GET_ARTICLES_LINK_SUCCESS,
        payload: response
    }
}
export const getArticlesLinkFailed = () => {
    return {
        type: ActionTypes.GET_ARTICLES_LINK_FAILED,
    }
}

export const putArticlesRequest = () => {
    return { type:  ActionTypes.PUT_ARTICLES_REQUEST }
}
export const putArticlesSuccess = (response) => {
    return {
        type: ActionTypes.PUT_ARTICLES_SUCCESS,
        payload: response
    }
}
export const putArticlesFailed = () => {
    return {
        type: ActionTypes.PUT_ARTICLES_FAILED,
    }
}