import Styled from 'styled-components';

export const CardWrapper = Styled.div`
  box-shadow: 0px 20px 30px rgba(43, 43, 43, 0.1);
  border-radius: 16px;
  width: 40%;
  min-width: 480px;
  margin: auto;
  background: ${ props => props.theme.white };
  flex: 1;
  padding: 40px;
  height: 60%;
  min-height: calc(100vh - 180px);
  position: relative;
  z-index: 2;
  .telephone-no{
    input{
      width: 100% !important;
      height: 40px !important;
      min-height: 40px !important;
      background: #ffffff;
      border: 0.5px solid #D5D8DE;
      border-radius: 6px;
    }
  }
  .margin-top, .account-margin-top{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
  }

  .account-card{
    max-height: calc(100vh - 390px);
    overflow-y: auto;
    padding-right: 10px;
    label{
      font-size: 12px;
    }
    .date-field{
      .react-datepicker{
        .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
          display: none;
        }
        .react-datepicker__month-dropdown-container--scroll, .react-datepicker__year-dropdown-container--scroll{
          font-weight: bold;
        } 
      }
      input{
        width: 100% !important;
        height: 40px !important;
        min-height: 40px !important;
        background: #F7F7FA;
        border: 0.5px solid #D5D8DE;
        border-radius: 6px;
        padding: 0.375rem 0.75rem;
        &:focus-visible{
          outline: #86b7fe  auto 1px;
        }
      }
    }
    [class$="control"]{
      [class$="ValueContainer"]{
        margin-top: -5px !important;
        [class$="INPUT"]{
         margin: 0 !important;
        }
        [class$="placeholder"]{
          font-size: 14px;
        }
      }
      [class$="indicatorContainer"]{
        padding: 2px 8px !important;
      }

    }

    input{
      line-height: 1;
      font-size: 14px !important;
    }
  }
  .scrollable-content{
    max-height: calc(100vh - 390px);
    overflow-y: auto;
  }
  .language-select{
    width: 10%;
    position: absolute;
    top: 10px;
    right: 40px;
    height: 30px;
    span{
      font-family: font-bold;
      font-size: 14px;
      color:#D5D8DE;
      cursor: pointer;
      &.active{
        color: #FAB200;
      }
    }
  }
  .otp{
    div{
      input{
        border: 1px solid;
      }
    }
  }
  h3 {
    font-size: 40px;
    line-height: 21px;
    margin-bottom: 0;
    color: #333333;
    text-align: center;
    font-family: font-bold;
  }
  h5{
    font-size: 24px;
    font-family: font-bold;
    line-height: 21px;
  }
  h6{
    font-size: 16px;
    line-height: 21px;
    color:#333333;
    span{
      color:#B3B9C2;
    }
  }

  .resend-link{
    margin-top: 20px;
    a{
      color:#255DC8;
      cursor:pointer;
      &:hover{
        color: #255DC8;
      }
    }
  }
  .otp-field{
    margin: 20px 0;
    input{
      width: 44px!important;
      height: 48px;
      border: 0.5px solid #D5D8DE !important;
      border-radius: 6px;
      background: #ffffff;
      margin-right: 25px;
      color: #000000;
      &:last-child{
        margin-right: 0;
      }
      &:focus-visible {
        outline: #86b7fe auto 1px;
    }
    }
  }
  .domain-blocks{
    margin-top: 5px;
    span{
      margin-right: 10px;
      border: 1px solid #FAB200;
      border-radius: 5px;
      color: #FAB200;
      font-size: 10px;
      padding: 2px 5px;
      font-family: font-bold;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .password-type{
    margin-top: 5px;
    color: #B3B9C2;
    font-size: 12px;
  }
  .auth-form{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    text-align: center;
    p{
      margin-bottom: 0;
    }
    .password-block{
      width: 100%;
    }
    .input-group-append{
      position: absolute;
      right: 5px;
      top: 5px;
      .input-group-text-img{
        background: transparent;
        img{
          padding: 5px;
        }
      }
    }

    button{
      margin-top: 10px;
    }
  }

  &.transaction-card {
    border-radius: 0;
    padding-top: 0;
    .fixed-header {
      position: fixed;
      top: 80px;
      left: 0;
      right: 0;
      bottom:0;
      padding: 0 30px;
      background: #fff;
      padding-top: 20px;
       &>div {
         margin-bottom: 10px;
       }
    }
    h3 {
      font-size: 40px;
      line-height: 21px;
      margin-bottom: 0;
      color: #333333;
    }
    .content-wrapper {
      padding-top: 60px;
    }
    a {
      color: #255DC8;
    }
  }
  .slider-text{
    height: 92%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    overflow-y: auto;
    .slider-content{
      img{
        width: 250px;
        margin: auto;
      }
      h3{
        font-size: 20px;
        font-family: font-bold;
        margin: 20px 0;
      }
      margin: 0px 20px 10px 20px;
      font-size: 14px;
      text-align: center;
    }
  }

  .slider-btns{
    button{
    position: absolute;
    top: 40%;
    width: 30px;
    height: 30px;
    padding: 0 !important;
    border-width: 0px;
    img{
      padding: 8px 12px;
    }
    &.slider-btn-prev{
      left: 10px;
    }
    &.slider-btn-next{
      right: 10px;
      img{
        transform: scaleX(-1);
        padding: 8px 0px 8px 0px;
      }
    }
  }
}

.slider-btns button[disabled] {
  display: none;
}

.success-content{
  margin-top: 70px;
  p{
    font-size: 14px;
  }
  h5{
    font-size: 20px;
  }
  &.scrollable-content{
    max-height: calc(100vh - 325px);
  }
.success-img{
  text-align: center;
  img{
    margin: auto;
  }
  .qr-imgs{
    height: 300px;
    width: 300px;
  }

  .button-imgs{
    width: 150px;
    height: auto;
    margin-bottom: 20px;
  }
}
}
@media (min-width: 1400px){
  width: 30%;
}

@media (max-width: 1024px){
  .scrollable-content{
    overflow-y: hidden;
    max-height: none;
  }
  .auth-form{
    height: 90%;
    margin-top: 30px;
  }
  .account-card{
    max-height: none !important;
    overflow-y: hidden !important;
  }
  .success-content{
    margin-top: 90px;
    &.scrollable-content{
      max-height: none !important;
    }
}

@media (max-width: 768px){
  padding: 20px;
  }
 
  .otp-field{
    >div{
      >div{
        justify-content: center;
      }
    }
  }
}


  @media (max-width: 575px){
    min-width: 80%;
    .flex-btns{
      flex-direction: column;
    }
    .language-select{
      width: 15%;
    }
    .slider-btns{
      button{
      &.slider-btn-next{
        right: 30px;
      }
    }
    }
    h3{
      margin-top: 30px;
    }
    .slider-text{
      .slider-content{
        margin: 0px 40px 30px 25px;
        img{
          width: 80%;
        }
      }
    }
    .auth-form{
      .domain-blocks{
        display: flex;
        flex-wrap: wrap;
        span{
          margin-bottom: 5px;
        }
      }
    }
    .otp-field{
      input{
        width: 30px!important;
        height: 30px;
        margin-right: 5px;
      }
    }

  }
  @media (max-width: 400px){
    .button-imgs{
      margin-bottom: 0px !important;
    }
    .apple-btn{
        margin-top: 20px;
    }
  }
  @media (max-width: 425px){
    .success-img{
      .qr-imgs{
        height: 100% !important;
        width: 100% !important;
      }
    }
  }
`;
