import { getLocalByTitle, getOnlyNumbers } from 'utils/helpers';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const UPPERCASE_REGEX = /^(?=.*[A-Z])/;
const SPECIAL_CHARACTER_REGEX = /^(?=.*[!@#$%^&*])/;
//const ACTIVATION_CODE = /^\d{4}$/;
const SSN_CODE = /([^\d]*\d[^\d]*){1,9}$/;

const required = (value) => (value ? undefined : 'Required');

const spaceTrim = (value) => (value && value.trim() ? undefined : 'Required');

const number = (value) => (value && isNaN(Number(value)) ? 'Must be a number' : undefined);

const minValue = (min) => (value) => (value && value < min ? `Must be at least ${min}` : undefined);
const minValue9 = minValue(9);
const maxValue = (max) => (value) => (value && value > max ? `Must be less than ${max}` : undefined);
const maxValue200 = maxValue(200);

const accountFormValidate = (values) => {
  const errors = {};
      if (!values?.firstName) {
          errors.firstName = getLocalByTitle('FIRST_NAME')
      }
      if (!values?.lastName) {
          errors.lastName = getLocalByTitle('LAST_NAME');
      }
      if (!values?.phoneNumber) {
        errors.phoneNumber = getLocalByTitle('ENTER_PHONE_NUMBER');
      }
      if(values?.phoneNumber?.length < 10) {
          errors.phoneNumber = getLocalByTitle('INVALID_CONTACT_NUMBER');
      }
  return errors;
}

const accountDetailsFormValidate = (values) => {
  const errors = {};
  const ssn = getOnlyNumbers(values?.ssn);
      if (!values?.address) {
          errors.address = getLocalByTitle('ENTER_ADDRESS')
      }
      if (!values?.zip) {
          errors.zip = getLocalByTitle('ENTER_ZIP_CODE');
      }
      if (!values?.city) {
        errors.city = getLocalByTitle('ENTER_CITY');
      }
      if (!values?.state) {
        errors.state = getLocalByTitle('STATE_ERROR');
      }
      if (!values?.maidenName) {
        errors.maidenName = getLocalByTitle('ENTER_MOTHER_MAIDEN_NAME');
      }
      if (!values?.ssn) {
        errors.ssn = getLocalByTitle('ENTER_SSN');
      }
      if(values?.ssn && ssn?.length < 9) {
        errors.ssn = getLocalByTitle('INVALID_SSN');
      }
      if (values?.ssn && !SSN_CODE.test(values.ssn)) {
        errors.ssn = getLocalByTitle('INVALID_SSN');
      }
      // if (!values?.activationCode) {
      //   errors.activationCode = getLocalByTitle('ACTIVATION_CODE_REQUIRED');
      // }
      // if (values?.activationCode?.length > 4 || values?.activationCode?.length < 4) {
      //   errors.activationCode = getLocalByTitle('INVALID_ACTIVATION_CODE');
      // }
      // if(!ACTIVATION_CODE.test(values.activationCode)) {
      //   errors.activationCode = getLocalByTitle('INVALID_ACTIVATION_CODE');
      // }
      if (!values?.birthDate) {
        errors.birthDate = getLocalByTitle('EMPTY_BIRTHDAY');
      }
  return errors;
}

const signupFormValidate = (values) => {
  const errors = {};
      if (!values?.email) {
          errors.email = getLocalByTitle('CHECK_MAIL_LENGTH')
      }
      if (!EMAIL_REGEX.test(values.email)) {
          errors.email = getLocalByTitle('CHECK_MAIL_VALIDATION');
      }
      if(!values.password) {
        errors.password = getLocalByTitle('ENTER_PASSWORD');
      }
      if(values?.password?.length < 9) {
        errors.password = getLocalByTitle('PASSWORD_LENGTH');
      }
      if(!UPPERCASE_REGEX.test(values.password)) {
        errors.password = getLocalByTitle('CAPITAL_CHARACTER');
      }
      if(!SPECIAL_CHARACTER_REGEX.test(values.password)) {
        errors.password = getLocalByTitle('SPECIAL_CHARACTER');
      }
      if(!values.confirmPassword) {
        errors.confirmPassword = getLocalByTitle('ENTER_CONFIRM_PASSWORD');
      }
      if(values.password !== values.confirmPassword) {
        errors.confirmPassword = getLocalByTitle('CHECK_PASSWORD_CONFIRM_PASSWORD');
      }
  return errors;
}

export {
  maxValue,
  minValue,
  number,
  required,
  spaceTrim,
  maxValue200,
  minValue9,
  accountFormValidate,
  signupFormValidate,
  accountDetailsFormValidate
};
