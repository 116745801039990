import Styled from 'styled-components';

const Button = Styled.button`
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  text-align: center;
  border-radius: 20px;
  width: 327px;
  max-width: 100%;
  padding: 18px 24px;
  cursor: pointer;
  display: inline-block;

  color: ${ props => props.outlined ? props.theme.white : props.theme.primary };
  border: 1px solid ${ props => props.theme.primary };
  background: ${ props => props.outlined ? props.theme.primary : props.theme.white };

  &.back-button{
    width: 38%;
  }
  &.confirm-mobile{
    width: 58%;
  }
  &.submit-btn{
    width: 300px;
  }
  &.success-btns{
    width: auto;
    border: none;
    color: transparent;
    padding: 0;
  }
  @media (max-width: 575px){
    &.back-button{
      margin-bottom: 10px;
    }
    &.back-button, &.confirm-mobile{
      width: 100%;
    }
  }
`;
export default Button