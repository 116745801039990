import { Input } from 'components/shared/Input.styled';
import React from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export const Validations = (props) => {
    const {
        touched,
        error,
        validationError,
        warning,
        withoutTouch
    } = props.props;

    return (
        <>
            {withoutTouch && <p>
                {((error && <span className={ `field_error ${ error === 'dummy-error' && 'dummy-error' }` }>{error}</span>) || (warning && <span>{warning}</span>))}
            </p>}
            {validationError && (
                <p>
                    {(validationError && <span className={ `field_error ${ error === 'dummy-error' && 'dummy-error' }` }>{validationError}</span>)}
                </p>
            )}

            {!withoutTouch && <p>
                {touched && ((error && <span className={ `field_error ${ error === 'dummy-error' && 'dummy-error' }` }>{error}</span>) || (warning && <span>{warning}</span>))}
            </p>
            }
        </>
    );
};
const renderField = (props) => {
    const {
        input,
        type,
        placeholder,
        disabled,
        checked,
        validationError,
        handleChange,
        meta: { touched, error, warning },
        maxLength,
        rows,
        defaultWarning
    } = props;

    const changeData = (event) => {
        handleChange && handleChange(event.currentTarget.value)
        input.onChange(event.currentTarget.value)
    }

    return (
        <>
            <Input rows={ rows } { ...input } checked={ checked } onChange={ changeData }  maxLength={ maxLength } disabled={ disabled || false } type={ type } className={ validationError || (touched && error) ? 'form-control validation-error' : 'form-control' } placeholder={ placeholder || '' }/>

            {defaultWarning && !input.value && <span className="default-warning"><i className="fas fa-exclamation-triangle"></i> {defaultWarning}</span>}
            <Validations
                props={ {
                    touched,
                    error,
                    validationError,
                    warning,
                } }
            />
        </>
    );
};
const renderNumberField = (props) => {
    const {
        input,
        fixed,
        step,
        type,
        inputMode,
        placeholder,
        disabled,
        checked,
        validationError,
        handleChange,
        meta: { touched, error, warning },
        maxLength,
        rows,
        defaultWarning
    } = props;

    const checkTwoDecimal = (value) => {
        if (value.includes('.')){
            if (value.split('.')[ 1 ].length > 2){
                return parseFloat(value).toFixed(fixed)
            }else{
                return (value)
            }
        }else{
            return value
        }
    }

    const changeData = (event) => {
        const value = event?.currentTarget?.value && event.currentTarget.value.replace(/[^0-9.]/g,'')
        handleChange && ( fixed ? handleChange(checkTwoDecimal(value)) : handleChange(value))
        fixed ? input.onChange(checkTwoDecimal(value)) :  input.onChange(value)
    }

    return (
        <>
            <Input rows={ rows } { ...input }  step={ step || 'any' } type ={ type } checked={ checked } onChange={ changeData }   maxLength={ maxLength } disabled={ disabled || false }
                inputMode={ inputMode } className={ validationError || (touched && error) ? 'form-control validation-error' : 'form-control' } placeholder={ placeholder || '' }/>

            {defaultWarning && !input.value && <span className="default-warning"><i className="fas fa-exclamation-triangle"></i> {defaultWarning}</span>}
            <Validations
                props={ {
                    touched,
                    error,
                    validationError,
                    warning,
                } }
            />
        </>
    );
};

const renderCheckboxField = (props) => {
    const {
      input,
      meta: { touched, error }
    } = props;
    return (
      <div style={{ border: touched && error ? '1px solid red' : 'none' }}>
        <input type="checkbox" {...input} />
      </div>
    );
};

const renderSelectField = (props) => {
    const {
        input,
        options,
        selectedOption,
        handleChange,
        placeholder,
        validationError,
        meta: { touched, error, warning },
        defaultWarning
    } = props;
    const customSelect = {
        control: (provided) => ({
            ...provided,
            background: (touched && error) ? '#f5dcdc96' : '#F7F7FA',
            border: (touched && error) ? '0.3px solid red' : '0.5px solid #D5D8DE',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
            svg: {
                fill: '#154CD9'
            },
        }),
        menu: (provided) => ({
            ...provided,
            borderRadius: '0 0 4px 4px',
            marginTop: '5px',
            padding: '0 5px',
        }),
        option: (provided) => ({
            ...provided,
            background: 'white',
            color: '#000',
        }),
        indicatorsContainer: () => ({
            'svg': {
                'path': {
                    color: '#154CD9'
                }
            }
        })
    }

    const changeValue=(event) => {
        handleChange && handleChange(event)
        input.onChange(event.value)
    }

    const getSelectedOption = (value) => {
        return options?.filter((item) => item.value === value )
    }

    return (
        <>
            <Select
                className={ error && 'has-error' }
                styles={ customSelect }
                value={ selectedOption || getSelectedOption(input?.value) }
                onChange={ changeValue }
                options={ options || [] }
                placeholder={ placeholder }
            />

            {defaultWarning && !input.value && <span className="default-warning"><i className="fas fa-exclamation-triangle"></i> {defaultWarning}</span>}
            <Validations
                props={ {
                    touched,
                    error,
                    validationError,
                    warning,
                } }
            />
        </>
    );
};

const renderDatepickerField = (props) => {
    const {
      input,
      handleChange,
      placeholder,
      maxDate,
      validationError,
      warning,
      meta: { touched, error }
    } = props;

    const changeData = (event) => {
        handleChange && handleChange(event)
        input.onChange(event)
    }

    return (
      <>
        <DatePicker
            { ...input }
            selected={input.value ? new Date(input.value) : ''}
            onChange={changeData}
            maxDate={maxDate}
            placeholderText={placeholder}
            autoComplete="off"
            onKeyDown={(e) => {
                e.preventDefault();
            }}
            yearDropdownItemNumber={100}
            scrollableYearDropdown={true}
            showYearDropdown
            showMonthDropdown
        />
        <Validations
            props={ {
                touched,
                error,
                validationError,
                warning,
            } }
        />
      </>
    );
};

const renderPhoneNumberField = (props) => {
    const {
      input,
      handleChange,
      placeholder,
      validationError,
      warning,
      meta: { touched, error }
    } = props;

    const changeData = (event) => {
        handleChange && handleChange(event)
        input.onChange(event)
    }

    return (
      <>
       <PhoneInput
            {...input}
            className={ validationError || (touched && error) ? 'validation-error' : '' }
            country={'us'}
            value={input.value}
            onChange={changeData}
            placeholder={placeholder}
            disableDropdown={props.disableDropdown}
            countryCodeEditable={props.countryCodeEditable}
        />
        <Validations
            props={ {
                touched,
                error,
                validationError,
                warning,
            } }
        />
      </>
    );
};


export {
    renderField,
    renderNumberField,
    renderSelectField,
    renderCheckboxField,
    renderDatepickerField,
    renderPhoneNumberField
};