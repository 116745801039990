import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import documentsReducer from './documents';
import signupReducer from './signup';
import themeReducer from './theme';

const appReducer = combineReducers({
    form: formReducer,
    theme: themeReducer,
    signup: signupReducer,
    documents: documentsReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        // eslint-disable-next-line no-param-reassign
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducer