import React from 'react';
import {  Nav } from './Navbar.styled';
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const SignupProgressBar = ()  => {
    const { t } = useTranslation();
    const stepData =  useSelector((state) => state.theme.stepData)
    const getActiveBar = (value) => {
        return stepData?.step >= value && 'active'
    }
    return (
            <Nav>
              <div className="progress-wrapper">
                        <h3 className="mb-2">{stepData?.title}</h3>
                        <ul className="progressbar">
                            <li className={ getActiveBar(1) }>{t('PROGRESS_TEXT')}</li>
                            <li className={ getActiveBar(2) }></li>
                            <li className={ getActiveBar(3) }></li>
                            <li className={ getActiveBar(4) }></li>
                        </ul>
                    </div>
            </Nav>
    )
}
export default withRouter(SignupProgressBar);