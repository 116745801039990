import React from 'react';
import Button from './Button.styled';
import { Layout } from './Layout';
import ModalWrapper from './Modal.styled';

import Logo from "../../assests/images/logo.png";
import authBackground from "../../assests/images/auth-bg.png";

const Modal = (props) => {
    const {
        show,
        children,
        leftButtonText,
        rightButtonText,
        showFooterButtons,
        leftButtonHandler,
        rightButtonHandler,
    } = props;

    const showHideClassName = show ? 'modal d-block' : 'modal d-none';

    return (
        <ModalWrapper className={ showHideClassName }>
        <div className="auth-container h-100" style={{ backgroundImage: `url(${authBackground})` }}>
            <div className="logo-container text-center">
                <img src={Logo} alt=""/>
            </div>
            <div className="modal-wrap">
                <div className="modal-container">
                <Layout>{children}</Layout>
                   {showFooterButtons && <><Button onClick={leftButtonHandler} > {leftButtonText }</Button>
                    <Button outlined type='submit' onClick={rightButtonHandler} >{rightButtonText}</Button></>}
                </div>
            </div>
            <div className="height-block d-none"></div>
        </div>
        </ModalWrapper>
    );
};

export default Modal;